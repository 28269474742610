import React, { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import visitorInfo from 'visitor-info'
import ButtonWrapper from '.././Button'
import utils from '../../utils/utils'
import { GlobalContext } from '../../context/global.context'
import { ImgContainer, GetAppPopupContainer, DetailsPageBadges } from './style'
/* --------------------------------------------- */
const visitor = visitorInfo()
/* --------------------------------------------- */
// GetAppPopup component for displaying get app popup.
const GetAppPopup = () => {
  /* --------------------------------------------- */
  const theme = useSelector(store => store.themeConfigReducers.popupTheme)
  const metaTheme = useSelector(store => store.themeConfigReducers.metaTheme)
  const appStoreConfig = useSelector(store => store.appConfigReducers.appStoreConfig)
  const appMessages = useSelector(store => store.appConfigReducers.appMessages)
  /* --------------------------------------------- */
  const { getAppDetails, isMobileUser, dispatch: globalDispatch } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const getAppPopupRef = useRef(null)
  /**
   * It: Hides installation banner.
   */
  useEffect(() => {
    if (!isMobileUser || !getAppDetails.isShowPopup) return
    globalDispatch({ type: 'HIDE_INSTALL_BANNER' })
  }, [isMobileUser, getAppDetails.isShowPopup, globalDispatch])
  /* --------------------------------------------- */
  /**
   * When: To handle cancel button click.
   * It: Hides popup.
   */
  const negativeHandler = () => {
    globalDispatch({
      type: 'GETAPP_POPUP_DETAILS',
      value: {
        isShowPopup: false,
        details: getAppDetails
      }
    })
  }
  /* --------------------------------------------- */
  /**
    * It: Enable/disable body scroll.
    */
   useEffect(() => {
    let contentEl = getAppPopupRef
    if (getAppDetails.isShowPopup) {
      utils.disableScroll(contentEl, { reserveScrollBarGap: true })
      globalDispatch({ 
        type: 'POPUP_DETAILS', 
        value: {
          inViewport: true,
          useMargin: utils.hasMargin()
        }
      })
    }
    else {
        utils.enableScroll(contentEl)
        globalDispatch({ 
            type: 'POPUP_DETAILS', 
            value: {
                inViewport: false,
                useMargin: utils.hasMargin()
            }
        })
    }
}, [globalDispatch, getAppDetails.isShowPopup])
  /* --------------------------------------------- */
  /**
   * When: To handle positive button click.
   * It: Opens store link.
   */
  const getAppHandler = () => {
    const os = visitor.os.name
    let storeLink = appStoreConfig.androidAppStoreUrl
    if (os === 'Mac OS' || os === 'iOS' || os === 'iPadOS') storeLink = appStoreConfig.iosAppStoreUrl
    window.open(storeLink, '_blank')
  }
  /* --------------------------------------------- */
  let images = []
  if (getAppDetails.details && (getAppDetails.details.currentPosterImage || getAppDetails.details.images)) {
    images = getAppDetails.details.currentPosterImage || getAppDetails.details.images
  }
  /* --------------------------------------------- */
  if (!isMobileUser || !getAppDetails.isShowPopup) return null
  /* --------------------------------------------- */
  return (
    <GetAppPopupContainer {...{ theme }} className={`two-action-popup-wrapper ${getAppDetails.isShowPopup ? 'two-action-popup-wrapper---open' : ''}`}>
      <div ref={getAppPopupRef} className='two-action-popup-wrapper__content'>
        <div className='img-box'>
          <ImgContainer {...{ images }} className='img-holder'>
            <div className='data'>
              <h2>{getAppDetails.details.title}</h2>
              <div className='details-section-header__badges'>
                <DetailsPageBadges {...{ metaTheme }}>
                  {utils.isNotNull(getAppDetails, 'details', 'releaseYear') ? <p><span className='releaseYear'>{getAppDetails.details.releaseYear}</span></p> : null}
                  {(utils.isNotNull(getAppDetails, 'details', 'parentalControl', 0, 'rating') || utils.isNotNull(getAppDetails, 'details', 'isCcAvailable') || utils.isNotNull(getAppDetails, 'details', 'isHdAvailable')) ? <div>
                    {utils.isNotNull(getAppDetails, 'details', 'parentalControl', 0, 'rating') ? <li className='filled'>{getAppDetails.details.parentalControl[0].rating}</li> : null}
                    {utils.isNotNull(getAppDetails, 'details', 'isCcAvailable') && getAppDetails.details.isCcAvailable ? <li className='hollow'>{appMessages.label_details_cc}</li> : null}
                    {utils.isNotNull(getAppDetails, 'details', 'isHdAvailable') && getAppDetails.details.isHdAvailable ? <li className='hollow'>{appMessages.label_details_hd}</li> : null}
                  </div> : null}
                </DetailsPageBadges>
              </div>
            </div>
          </ImgContainer>
        </div>
        <div className='text-box'>
          <h2>{appMessages.label_mobile_web_primary_text}</h2>
          <p>{appMessages.label_mobile_web_secondary_text}</p>
          <ButtonWrapper
            {...{
              theme: theme.compositeStyle && theme.compositeStyle.primaryButton,
              text: appMessages.button_mobile_web_primary,
              onClick: getAppHandler
            }}
          />
          <p className='optional'>{appMessages.label_mobile_web_confirm_text}</p>
          <ButtonWrapper
            {...{
              theme: theme.compositeStyle && theme.compositeStyle.secondaryButton,
              text: appMessages.button_mobile_web_secondary,
              size: 'small',
              onClick: negativeHandler
            }}
          />
        </div>
      </div>
    </GetAppPopupContainer>
  )
}


/* --------------------------------------------- */
GetAppPopup.defaultProps = {}
/* --------------------------------------------- */
export default GetAppPopup
/* --------------------------------------------- */
