export const routeMap = {
    /** Static Routes */
    gettingStarted: '/abonnement',
    contactUs: '/communiquer-avec-nous',
    faq: '/faq',
    tos: `/modalites-dutilisation`,
    pp: '/politique-de-vie-privee',
    /** Media Routes */
    catalog: '/:pageName',
    details: '/:pageName/:type/:mediaId?',
    listing: '/liste',
    favourites: '/favoris',
    player: '/lecteur',
    tvGuide: '/tv-guide',
    /** Account Routes */
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    changePassword: '/change-password',
    editProfile: '/edit-profile',
    accountInfo: '/account-info',
    myAccount: '/my-account',
    /** Payment Routes */
    paymentHistory: '/payment-history',
    payment: '/payment/:storeProductId'
}

export const routeVarMap = {
    pageName: {
        favourites: 'favoris'
    },
    type: {
        movie: 'm',
        series: 's',
        preview: 'p',
        channel: 'channel',
        match: 'match',
        sports: 'Sports',
        sportingevent: 'sportingevent',
        sportingEvent: 'sportingEvent'
    }
}