import { useContext, useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { ErrorContext } from '../context/error.context'
import useLoggly from '../containers/useLoggly'
import useCustomErrorConfigs from '../utils/useCustomErrorConfigs'
/* ---------------------------------------------------------------------------------------------------- */
/** Custom hook to dispatch error events to show/hide error screens */
const useErrorUtils = () => {
  const errorConfigurationRef = useRef([])
  const { dispatch } = useContext(ErrorContext)
  const { supportConfig } = useSelector(store => store.support)
  const { getErrors } = useCustomErrorConfigs()
  const { log } = useLoggly()
  /* -------------------------------------------------------------------------------------------------- */
  useEffect(() => {
    errorConfigurationRef.current = supportConfig.errorConfiguration || []
  }, [supportConfig.errorConfiguration])
  /* -------------------------------------------------------------------------------------------------- */
  const showError = useCallback(({ type = '', value = {}, errorMessage = '', errorInfo = {}, isSendLog = true }) => {
    const errorConfiguration = errorConfigurationRef.current || []
    /* ------------------------------------------------------------------------------------------------ */
    if (!type) return
    /* ------------------------------------------------------------------------------------------------ */
    let config = (errorConfiguration || []).filter((config) => {
      return config.type === type
    })[0]
    /* ------------------------------------------------------------------------------------------------ */
    if (!config) {
      const errors = getErrors()
      config = errors.filter((config) => {
        return config.type === type
      })[0]
    }
    /* ------------------------------------------------------------------------------------------------ */
    const {
      type: errorType,
      // title,
      description,
      code,
      uiType
      // primaryActionType,
      // secondaryActionType,
      // primaryActionLabel,
      // secondaryActionLabel,
      // secondaryActionDescription,
      // faqDescription,
      // faqLabel,
      // faqItem,
      // theme
    } = config || {}
  
    /* ------------------------------------------------------------------------------------------------ */
    let errorTypeVariable = ''
    let eventVariable = ''
    let moduleVariable = ''

    if (code === 'A-5007') {
      // GenericNoSearch
      errorTypeVariable = 'Debug'
      eventVariable = 'search'
      moduleVariable = 'Search'
    } else if (code === 'A-3001') {
      // LoginSessionExpire
      errorTypeVariable = 'Error'
      eventVariable = '_user.login'
      moduleVariable = 'Authentication'
    } else if (code === 'A-1001') {
      // AppNoNetwork
      errorTypeVariable = 'Debug'
      eventVariable = ''
      moduleVariable = 'General'
    } else if (code === 'A-1002') {
      // AppMaintenance
      errorTypeVariable = 'Info'
      eventVariable = ''
      moduleVariable = 'General'
    } else if (code === 'A-3002') {
      // LoginUnsupportedUserType
      errorTypeVariable = 'Info'
      eventVariable = '_user.login'
      moduleVariable = 'Authentication'
    } else if (code === 'A-3005') {
      // UserAccountCreationFailed
      errorTypeVariable = 'Error'
      eventVariable = '_user.profile'
      moduleVariable = 'Authentication'
    } else if (code === 'A-3006') {
      // UserProfileSavingFailed
      errorTypeVariable = 'Error'
      eventVariable = '_user.profile'
      moduleVariable = 'Authentication'
    } else if (code === 'A-3008') {
      // LoginUnsupportedSubscriptionType
      errorTypeVariable = 'Info'
      eventVariable = '_user.login'
      moduleVariable = 'Authentication'
    } else if (code === 'A-2002') {
      // PlaybackDRM
      errorTypeVariable = 'Error'
      eventVariable = '_content.playback'
      moduleVariable = 'Player'
    } else if (code === 'A-2001') {
      // PlaybackEntitlement
      errorTypeVariable = 'Error'
      eventVariable = '_content.playback'
      moduleVariable = 'Player'
    } else if (code === 'A-3009') {
      // UserLoginFailed
      errorTypeVariable = 'Error'
      eventVariable = '_user.login'
      moduleVariable = 'Authentication'
    } else if (code === 'A-5009') {
      // GenericNoFavourite
      errorTypeVariable = 'Debug'
      eventVariable = '_user.profile'
      moduleVariable = 'Profile'
    } else if (code === 'A-5001') {
      // GenericErrorRemoveFavourite
      errorTypeVariable = 'Debug'
      eventVariable = '_content.favourite'
      moduleVariable = 'Profile'
    } else if (code === 'A-5002') {
      // GenericAddFavourite
      errorTypeVariable = 'Debug'
      eventVariable = '_content.favourite'
      moduleVariable = 'Profile'
    }
    if (isSendLog) {
      log({
        type: errorTypeVariable,
        attr: {
          event: eventVariable,
          module: moduleVariable,
          error_type: errorType,
          error_code: code,
          error_message: errorMessage,
          message: description,
          errorInfo,
          error_shown: !!uiType,
          pageUrl:window.location.href,
          retry_count: errorInfo.retry_count ,
          mw_error_code: errorInfo.mw_error_code,
        }
      })
    }
    /* ------------------------------------------------------------------------------------------------ */
    if (!config) return
    /* ------------------------------------------------------------------------------------------------ */
    if (uiType === 'page') dispatch({ type: 'ERROR_PAGE', value: { ...config, ...value } })
    else if (uiType === 'popup') dispatch({ type: 'ERROR_POPUP', value: { ...config, ...value } })
    else if (uiType === 'fullscreen') dispatch({ type: 'ERROR_FULLSCREEN', value: { ...config, ...value } })
    else if (uiType === 'toast') dispatch({ type: 'ERROR_TOAST', value: { ...config, ...value } })
    else if (uiType === 'snackbar') dispatch({ type: 'ERROR_SNACKBAR', value: { ...config, ...value } })
    /* ------------------------------------------------------------------------------------------------ */
  }, [
    dispatch, getErrors, log
  ])
  /* -------------------------------------------------------------------------------------------------- */
  /** This function should be called to hide an error screen */
  const hideError = useCallback(({ type = '' }) => {
    const errorConfiguration = errorConfigurationRef.current || []
    /* ------------------------------------------------------------------------------------------------ */
    if (!type) return
    /* ------------------------------------------------------------------------------------------------ */
    const config = errorConfiguration.filter((config) => {
      return config.type === type
    })[0]
    /* ------------------------------------------------------------------------------------------------ */
    if (!config) return
    /* ------------------------------------------------------------------------------------------------ */
    const { uiType } = config
    /* ------------------------------------------------------------------------------------------------ */
    if (uiType === 'page') dispatch({ type: 'ERROR_PAGE', value: {} })
    else if (uiType === 'popup') dispatch({ type: 'ERROR_POPUP', value: {} })
    else if (uiType === 'fullscreen') dispatch({ type: 'ERROR_FULLSCREEN', value: {} })
    else if (uiType === 'toast') dispatch({ type: 'ERROR_TOAST', value: {} })
    else if (uiType === 'snackbar') dispatch({ type: 'ERROR_SNACKBAR', value: {} })
  }, [dispatch])
  /* -------------------------------------------------------------------------------------------------- */
  /** This function should be called to hide all errors */
  const hideAllErrors = useCallback(() => {
    dispatch({ type: 'ERROR_PAGE', value: {} })
    dispatch({ type: 'ERROR_POPUP', value: {} })
    dispatch({ type: 'ERROR_FULLSCREEN', value: {} })
    dispatch({ type: 'ERROR_TOAST', value: {} })
    dispatch({ type: 'ERROR_SNACKBAR', value: {} })
  }, [dispatch])
  /* -------------------------------------------------------------------------------------------------- */
  return { showError, hideError, hideAllErrors }
}
/* ---------------------------------------------------------------------------------------------------- */
export default useErrorUtils
/* ---------------------------------------------------------------------------------------------------- */
