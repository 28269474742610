import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const SubtitleIconFilled = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' width='36' height='36' {...rest}>
    <g id='Layer_2_1_' style={{ display: 'none' }}>
      <rect x='-100' y='-100' style={{ display: 'inline' }} width='360' height='360' />
    </g>
    <path
      fill={color} d='M1,23v105h158V23H1z M11,87h99v9H11V87z M31,121H11v-9h20V121z M149,121H50v-9h99V121z M149,96h-20v-9h20V96z'
    />
  </svg>
)
/* --------------------------------------------- */
SubtitleIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
SubtitleIconFilled.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default SubtitleIconFilled
/* --------------------------------------------- */
