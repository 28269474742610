import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const PauseIcon = ({ color, ...rest }) => (
  <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 160 160' style={{ enableBackground: 'new 0 0 160 160' }} xmlSpace='preserve' {...rest}>
    <g id='Layer_2_1_' className='st0' style={{ display: 'none' }}>
      <rect x='-100' y='-100' className='st1' width='360' height='360' style={{ display: 'inline' }} />
    </g>
    <line className='st2' x1='50.5' y1='4.5' x2='50.5' y2='156.5' style={{ fill: 'none', stroke: color, strokeWidth: 8, strokeLinecap: 'round', strokeMiterlimit: 10 }} />
    <line className='st2' x1='109.5' y1='4.5' x2='109.5' y2='156.5' style={{ fill: 'none', stroke: color, strokeWidth: 8, strokeLinecap: 'round', strokeMiterlimit: 10 }} />
  </svg>
)
/* --------------------------------------------- */
PauseIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
PauseIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default PauseIcon
/* --------------------------------------------- */
