import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import visitorInfo from 'visitor-info'
import device from 'current-device'
import firebase from 'firebase/app'
import 'firebase/analytics'
/* --------------------------------------------- */
import utils from '../utils/utils'
/* --------------------------------------------- */
import { version } from '../config'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
/* --------------------------------------------- */
const { browser = {}, os = {} } = visitorInfo() || {}
const utcOffset = -new Date().getTimezoneOffset() * 60 * 1000
/* --------------------------------------------- */
// Custom hook to handle analytics events.
const useAnalytics = () => {
  const { analyticsIntegrations } = useSelector(store => store.analytics)
  /* --------------------------------------------- */
  const { userInfo } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const [firebaseAnalytics, setFirebaseAnalytics] = useState(null)
  /* --------------------------------------------- */
  const userInfoRef = useRef({})
  const firebaseAnalyticsRef = useRef(null)
  const analyticsIntegrationsRef = useRef([])
  /* --------------------------------------------- */
  useEffect(() => {
    userInfoRef.current = userInfo
  }, [userInfo])
  /* --------------------------------------------- */
  useEffect(() => {
    firebaseAnalyticsRef.current = firebaseAnalytics
  }, [firebaseAnalytics])
  /* --------------------------------------------- */
  useEffect(() => {
    analyticsIntegrationsRef.current = analyticsIntegrations
  }, [analyticsIntegrations])
  /* --------------------------------------------- */
  useEffect(() => {
    firebase.analytics.isSupported()
      .then((isSupported) => {
        if (isSupported) setFirebaseAnalytics(firebase.analytics())
      }).catch(() => { })
  }, [])
  /* --------------------------------------------- */
  const sendAnalytics = useCallback(({ type, action = 'none', attr }) => {
    const userInfo = userInfoRef.current
    const firebaseAnalytics = firebaseAnalyticsRef.current
    const analyticsIntegrations = analyticsIntegrationsRef.current

    const getEvent = (eventAttributes = [], attr = {}) => {
      const _attr = {}
      eventAttributes.map((eventAttr) => {
        if (eventAttr === 'device_category' && device.type) {
          _attr[eventAttr] = device.type
        } else if (eventAttr === 'browser' && browser.name) {
          _attr[eventAttr] = browser.name
        } else if (eventAttr === 'os_version' && os.version) {
          _attr[eventAttr] = os.version
        } else if (eventAttr === 'language' && (window.navigator.language || window.navigator.userLanguage)) {
          _attr[eventAttr] = window.navigator.language || window.navigator.userLanguage
        } else if (eventAttr === 'browser_version' && browser.version) {
          _attr[eventAttr] = browser.version
        } else if (eventAttr === 'utc_offset' && utcOffset) {
          _attr[eventAttr] = utcOffset
        } else if (eventAttr === 'app_version' && version.number) {
          _attr[eventAttr] = version.number
        } else if (eventAttr === 'platform') {
          _attr[eventAttr] = 'Web'
        } else if (eventAttr === 'user_id' && (userInfo.userId || attr.userId)) {
          _attr[eventAttr] = userInfo.userId || attr.userId
        } else if (eventAttr === 'user_email_hash' && (userInfo.emailHash || attr.emailHash)) {
          _attr[eventAttr] = userInfo.emailHash || attr.emailHash
        } else if (eventAttr === 'user_type' && (userInfo.customerType || attr.customerType)) {
          _attr[eventAttr] = userInfo.customerType || attr.customerType
        } else if (eventAttr === 'user_source' && (userInfo.source || attr.source)) {
          _attr[eventAttr] = userInfo.source || attr.source
        } else if (eventAttr === 'event_action' && action) {
          _attr[eventAttr] = action
        } else if (attr[eventAttr] || attr[eventAttr] === 0) {
          _attr[eventAttr] = attr[eventAttr]
        }
        return null
      })
      return _attr
    }

    const findMatchingEvent = (service, type, action) => {
      const analyticConfig = analyticsIntegrations.filter((config) => {
        return config.analyticsService === service
      })
      if (utils.isNotNull(analyticConfig, 0, 'analyticEvents')) {
        let { analyticEvents = [] } = analyticConfig[0]
        analyticEvents = analyticEvents.filter((event) => (event.eventName === type) && (event.eventAction === action))
        return analyticEvents[0]
      }
      return {}
    }

    const sendEventByService = (service, type, attr, action) => {
      const matchingEvent = findMatchingEvent(service, type, action) || {}
      const { eventNameLabel = '', eventAttributes = [] } = matchingEvent
      const eventAttr = getEvent(eventAttributes, attr) || {}
      // if (service === 'Engage' && window.ENGAGE) {
      //   console.log(`eventNameLabel`, JSON.stringify(eventNameLabel));
      //   console.log(`eventAttributes`, JSON.stringify(eventAttributes));
      //   console.log(`eventAttr`, JSON.stringify(eventAttr));
      //   console.log(`attr`, JSON.stringify(attr));
      //   console.log(`userInfo`, JSON.stringify(userInfo));
      // }
      if (Object.keys(eventAttr).length > 0) {
        if (service === 'Firebase' && firebaseAnalytics) {
          firebaseAnalytics.logEvent(eventNameLabel, eventAttr)
        } else if (service === 'Engage' && window.ENGAGE) {
          try {
            window.ENGAGE.track(eventNameLabel, eventAttr)
          } catch (e) { }
        }
      }
    }

    sendEventByService('Firebase', type, attr, action)
    sendEventByService('Engage', type, attr, action)
  }, [])
  /* --------------------------------------------- */
  return {
    sendAnalytics
  }
}
/* --------------------------------------------- */
export default useAnalytics
