import React, { useState, useEffect, useContext } from 'react'
import { Context } from "./context";
import './ErrorToast.scss'
/* ---------------------------------------------------------------------------------------------------- */
// ErrorToast component which handles rendering and events.
const ErrorToast = () => {
    const [isActive, setActive] = useState(true)
    /* ------------------------------------------------------------------------------------------------ */
    const { errorToast, dispatch } = useContext(Context)
    const {
        title,
        primaryActionType,
        primaryActionLabel,
        onPrimaryBtnClick = () => { }
    } = errorToast
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Handles primary button click based on contentful config. */
    const handelePrimaryBtnClick = () => {
        if (primaryActionType === 'retry') window.location.reload()
        else if (primaryActionType === 'exit') window.close()
        else if (primaryActionType === 'cancel') {
            setActive(false)
            dispatch({ type: 'ERROR_TOAST', value: {} })
        }
        else if (primaryActionType === 'custom') onPrimaryBtnClick()
    }
    /* ------------------------------------------------------------------------------------------------ */
    /** It: removes toast after 5 seconds. */
    useEffect(() => {
        const timer = setTimeout(() => {
            setActive(false)
            dispatch({ type: 'ERROR_TOAST', value: {} })
        }, 5000)
        return () => {
            clearTimeout(timer)
        }
    }, [dispatch, title])
    /* --------------------------------------------- */
    return (
        <div className={`toast ${isActive ? 'toast___active' : ''}`}>
            <div className='text'>
                <p className="desc">{title}</p>
                <div className='toast___action'>
                    <p onClick={handelePrimaryBtnClick}>{primaryActionLabel}</p>
                </div>

            </div>
        </div>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
ErrorToast.propTypes = {}
/* ---------------------------------------------------------------------------------------------------- */
ErrorToast.defaultProps = {}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorToast
/* ---------------------------------------------------------------------------------------------------- */
