import React, { createContext, useReducer } from 'react'
/* --------------------------------------------- */
/** Player inital state */
const initialState = {
  isShowStats: false,
  thumbnailUrl: '',
  isShowThumbnail: false,
  releaseUrl: '',
  streamUrl: '',
  dispatch: () => { },
  quality: '',
  userAgent: '',
  playerVersion: '',
  playerState: '',
  startingQuality: '',
  startTime: new Date(),
  loadTime: new Date(),
  duration: 0,
  drmProvider: '',
  drmLicenseUrl: '',
  estimatedBandwidth: 0,
  corruptedFrames: 0,
  decodedFrames: 0,
  droppedFrames: 0,
  videoHeight: 0,
  videoWidth: 0,
  bufferingInfo: 0,
  bufferingTime: 0,
  bufferingHistory: [],
  streamHistory: [],
  trackHistory: [],
  allPlayerStats: [],
  videoId: '',
  originalVideoId: '',
  videoCodec: '',
  videoBandwidth: '',
  mimeType: '',
  frameRate: '',
  pixelAspectRatio: '',
  audioId: '',
  originalAudioId: '',
  audioCodec: '',
  audioSamplingRate: '',
  audioBandwidth: '',
  textTrackLanguage: '',
  textTrackLabel: '',
  originalTextId: ''
}
/* --------------------------------------------- */
/** Player context */
export const PlayerContext = createContext(initialState)
/* --------------------------------------------- */
/** Player reducer */
const playerReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_STATS':
      return { ...state, isShowStats: action.value }
    case 'THUMBNAIL_URL':
      return { ...state, thumbnailUrl: action.data }
    case 'SHOW_THUMBNAIL':
      return { ...state, isShowThumbnail: action.value }
    case 'RELEASE_URL':
      return { ...state, releaseUrl: action.value }
    case 'STREAM_URL':
      return { ...state, streamUrl: action.value }
    case 'QUALITY':
      return { ...state, quality: action.value }
    case 'USER_AGENT':
      return { ...state, userAgent: action.value }
    case 'PLAYER_VERSION':
      return { ...state, playerVersion: action.value }
    case 'PLAYER_STATE':
      return { ...state, playerState: action.value }
    case 'STARTING_QUALITY':
      return { ...state, startingQuality: action.value }
    case 'START_TIME':
      return { ...state, startTime: action.value }
    case 'LOAD_TIME':
      return { ...state, loadTime: action.value }
    case 'DURATION':
      return { ...state, duration: action.value }
    case 'DRM_PROVIDER':
      return { ...state, drmProvider: action.value }
    case 'DRM_LICENSE_URL':
      return { ...state, drmLicenseUrl: action.value }
    case 'ESTIMATED_BANDWIDTH':
      return { ...state, estimatedBandwidth: action.value }
    case 'CORRUPTED_FRAMES':
      return { ...state, corruptedFrames: action.value }
    case 'DECODED_FRAMES':
      return { ...state, decodedFrames: action.value }
    case 'DROPPED_FRAMES':
      return { ...state, droppedFrames: action.value }
    case 'VIDEO_HEIGHT':
      return { ...state, videoHeight: action.value }
    case 'VIDEO_WIDTH':
      return { ...state, videoWidth: action.value }
    case 'BUFFERING_INFO':
      return { ...state, bufferingInfo: action.value }
    case 'BUFFERING_TIME':
      return { ...state, bufferingTime: action.value }
    case 'BUFFERING_HISTORY':
      return { ...state, bufferingHistory: action.value }
    case 'STREAM_HISTORY':
      return { ...state, streamHistory: action.value }
    case 'TRACK_HISTORY':
      return { ...state, trackHistory: action.value }
    case 'ALL_PLAYER_STATS':
      return { ...state, allPlayerStats: action.value }
    case 'VIDEO_ID':
      return { ...state, videoId: action.value }
    case 'ORIGINAL_VIDEO_ID':
      return { ...state, originalVideoId: action.value }
    case 'VIDEO_CODEC':
      return { ...state, videoCodec: action.value }
    case 'VIDEO_BANDWIDTH':
      return { ...state, videoBandwidth: action.value }
    case 'MIME_TYPE':
      return { ...state, mimeType: action.value }
    case 'FRAME_RATE':
      return { ...state, frameRate: action.value }
    case 'PIXEL_ASPECT_RATIO':
      return { ...state, pixelAspectRatio: action.value }
    case 'AUDIO_ID':
      return { ...state, audioId: action.value }
    case 'ORIGINAL_AUDIO_ID':
      return { ...state, originalAudioId: action.value }
    case 'AUDIO_CODEC':
      return { ...state, audioCodec: action.value }
    case 'AUDIO_SAMPLING_RATE':
      return { ...state, audioSamplingRate: action.value }
    case 'AUDIO_BANDWIDTH':
      return { ...state, audioBandwidth: action.value }
    case 'TEXT_TRACK_LANGUAGE':
      return { ...state, textTrackLanguage: action.value }
    case 'TEXT_TRACK_LABEL':
      return { ...state, textTrackLabel: action.value }
    case 'ORIGINAL_TEXT_ID':
      return { ...state, originalTextId: action.value }
    default:
      return state
  }
}
/* --------------------------------------------- */
/** Player provider */
const PlayerProvider = (props) => {
  const [state, dispatch] = useReducer(playerReducer, initialState)
  /* --------------------------------------------- */
  return (
    <PlayerContext.Provider value={{ ...state, ...props, dispatch }}>
      {props.children}
    </PlayerContext.Provider>
  )
}
/* --------------------------------------------- */
export default PlayerProvider
