import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const VolumeIcon = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 16' {...rest}>
    <g id='Details-Page' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='3-Mobile---Details---VOD---Play-Button' transform='translate(-290.000000, -76.000000)' fill={color}>
        <g id='Group-2' transform='translate(0.000000, 56.000000)'>
          <g id='Group' transform='translate(253.000000, 20.000000)'>
            <g id='Page-1' transform='translate(37.000000, 0.000000)'>
              <g id='Group-3' transform='translate(0.000000, 0.272727)'>
                <path d='M9.91478889,0.1489375 L5.31556667,4.1770625 L1.33234444,4.1770625 C0.935733333,4.1770625 0.610622222,4.5089375 0.610622222,4.9145625 L0.610622222,9.8508125 C0.610622222,10.2564375 0.935733333,10.5883125 1.33234444,10.5883125 L5.10717778,10.5883125 L9.91234444,14.8489375 C10.3010111,15.1933125 10.9060111,14.9108125 10.9060111,14.3845625 L10.9060111,0.6151875 C10.9060111,0.0908125 10.3046778,-0.1923125 9.91478889,0.1489375' id='Fill-1' />
              </g>
              <path d='M13.3757818,10.2727273 C13.2417622,10.2727273 13.1151199,10.1988615 13.0647089,10.0788992 C13.0001582,9.92394774 13.0856111,9.75066873 13.2577462,9.6917983 C13.9014088,9.47020111 14.3354353,8.90315667 14.3354353,8.28001871 C14.3354353,7.64133007 13.8872691,7.0692872 13.2214749,6.85657611 C13.0487249,6.80103797 12.9577392,6.6294251 13.0192161,6.47280754 C13.0806929,6.31674536 13.2700416,6.23454891 13.4427915,6.29008705 C14.3741657,6.5877715 15,7.38752075 15,8.28001871 C15,9.15085679 14.3944531,9.94394147 13.4938173,10.2527335 C13.4550869,10.2666181 13.4145122,10.2727273 13.3757818,10.2727273' id='Fill-4' />
              <path d='M14.4410144,12.2727273 C14.3235587,12.2727273 14.2102765,12.2084133 14.1536353,12.0970548 C14.0725491,11.9386519 14.1351525,11.744519 14.2937476,11.6641265 C15.5654895,11.0150318 16.3554838,9.72696579 16.3554838,8.30193486 C16.3554838,6.84712895 15.5034823,5.50665898 14.1846389,4.88614823 C14.0236589,4.81051976 13.9550934,4.61876884 14.0308136,4.4579839 C14.1059376,4.29660347 14.2985173,4.2275255 14.4594973,4.30374947 C16.0031165,5.02966368 17,6.59880555 17,8.30193486 C17,9.9699297 16.0752594,11.4777351 14.5870889,12.2369973 C14.5405835,12.2614128 14.4905009,12.2727273 14.4410144,12.2727273' id='Fill-6' />
              <path d='M16.3869687,14.2727273 C16.2724587,14.2727273 16.1615271,14.2098788 16.1030792,14.1000466 C16.0183895,13.9395696 16.0762409,13.7394309 16.2330958,13.6521753 C18.1588928,12.5837521 19.3552845,10.5292798 19.3552845,8.29053342 C19.3552845,6.0218883 18.1356329,3.95399204 16.1722624,2.89472148 C16.014811,2.80990662 15.9539775,2.61037812 16.0374745,2.44929089 C16.120375,2.28759349 16.3154,2.22596542 16.4734477,2.31078028 C18.6485426,3.48476447 20,5.77598621 20,8.29053342 C20,10.7715208 18.6741881,13.0487084 16.5408417,14.2324555 C16.4919363,14.2599135 16.4388561,14.2727273 16.3869687,14.2727273' id='Fill-8' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
/* --------------------------------------------- */
VolumeIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
VolumeIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default VolumeIcon
/* --------------------------------------------- */
