import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import utils from '../../utils/utils'
import GoBackArrowIcon from '.././icons/GoBackArrowIcon'
/* --------------------------------------------- */
// Styles components for Header component.
export const HeaderWrapper = styled.div`
  background: ${({ theme: { header } }) => header && header.background.primary};
  color: ${({ theme: { header } }) => header && header.text.primary};
`
/* --------------------------------------------- */
export const HeaderLandingWrapper = styled.div`
  background: ${({ theme: { header } }) => header && header.text.primary};
  color: ${({ theme: { header } }) => header && header.text.secondary};
`;

export const LandingHeader = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 65px;
  left: 0px;
  top: 0px;
  background: ${({ theme: { header } }) => header && header.text.primary};
`;
/* --------------------------------------------- */
export const PopUpHeader = styled.p`
  color: ${({ theme: { header } }) =>  header && header?.text?.primary?.code};
`
export const MainWrapper = styled.div`
display: flex;
align-items: center;
padding: 15px 30px;
`
/* --------------------------------------------- */
export const SettingsWrapper = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};
`
export const SettingBodyWrapper = styled.p`
  color: ${({ theme: { body } }) =>  body && body?.text?.secondary?.code};
`
/* --------------------------------------------- */
export const LeftWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  `
/* --------------------------------------------- */
export const HeaderMenuStyle = css`
  color: ${({ buttonstyle: { normal } }) => normal && normal.text};
  &:hover{
    color: ${({ buttonstyle: { focussed } }) => focussed && focussed.text};
    background: ${({ buttonstyle: { focussed } }) => focussed && focussed.background};
  }
  &.active{
    color: ${({ buttonstyle: { selected } }) => selected && selected.text};
    background: ${({ buttonstyle: { selected } }) => selected && selected.background};
  }
  &.active::after{
    background: ${({ buttonstyle: { selected } }) => selected && selected.stroke};
  }
`
/* --------------------------------------------- */
export const HeaderMenuLink = styled(Link)`${HeaderMenuStyle}`
export const HeaderMenuLinkExternal = styled.a`${HeaderMenuStyle}`
/* --------------------------------------------- */
export const RightWrapper = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: flex-end;
.header__profile-list{
  background-color: ${({ theme: { body } }) => body && body.background.primary};
}
`
/* --------------------------------------------- */
export const MenuItemStyle = css`
  color: ${({ buttonStyle: { normal } }) => normal && normal.text};
  background: ${({ buttonStyle: { normal } }) => normal && normal.background};
  border-radius: ${({ buttonStyle: { edgeRadius } }) => edgeRadius && `0 ${edgeRadius * 20}px ${edgeRadius * 20}px 0`};
  border: ${({ buttonStyle: { normal } }) => normal && `solid 1px ${normal.stroke}`};
  border-top: ${({ showSeparatorBefore }) => showSeparatorBefore && 'soild 1px white'};
  &:hover{
    color: ${({ buttonStyle: { focussed } }) => focussed && focussed.text};
    background: ${({ buttonStyle: { focussed } }) => focussed && focussed.background};
  }
  &:before {
    ${({ buttonStyle: { normal } }) => {
    if (utils.isNotNull(normal, 'graphics', 'images', 0, 'url')) {
      return `
      background-image: url('${normal.graphics.images[0].url}');
      left: 50px !important;
    `
    }
  }}
  }
  &.active{
    color: ${({ buttonStyle: { selected } }) => selected && selected.text};
    background: ${({ buttonStyle: { selected } }) => selected && selected.background};
  }
  &.active::after{
    background: ${({ buttonStyle: { selected } }) => selected && selected.stroke};
  }
  &.active::before {
    ${({ buttonStyle: { selected } }) => {
    if (utils.isNotNull(selected, 'graphics', 'images', 0, 'url')) {
      return `
      background-image: url('${selected.graphics.images[0].url}');
    `
    }
  }}
  }
`
/* --------------------------------------------- */
export const MenuItem = styled.li`${MenuItemStyle}`
export const MenuItemLink = styled.a`${MenuItemStyle}`
/* --------------------------------------------- */
export const GoBackArrowIconWithTheme = styled(GoBackArrowIcon).attrs(
  ({ theme: { header } }) => ({
    color: header && header.accent.primary
  })
)`
  width: 20px;
  height: 20px;
`
/* --------------------------------------------- */
export const MenuImg = styled.div(
  ({ theme: { buttonStyle } }) => {
    let normalImg = ''
    let selectedImg = ''
    if (utils.isNotNull(buttonStyle, 'normal', 'graphics', 'images', 0, 'url')) {
      normalImg = buttonStyle.normal.graphics.images[0].url
    }
    if (utils.isNotNull(buttonStyle, 'selected', 'graphics', 'images', 0, 'url')) {
      selectedImg = buttonStyle.selected.graphics.images[0].url
    }
    return `
    width: 28px;
    height: 28px;
    background-image: url('${normalImg}');
    background-size: cover;
    background-repeat: no-repeat;
    &.active {
      background-image: url('${selectedImg}');
    }
  `
  }
)
/* --------------------------------------- ------ */
export const IconImg = styled.div(
  ({ theme: { buttonStyle } }) => {
    let normalImg = ''
    let selectedImg = ''
    if (utils.isNotNull(buttonStyle, 'normal', 'graphics', 'images', 0, 'url')) {
      normalImg = buttonStyle.normal.graphics.images[0].url
    }
    if (utils.isNotNull(buttonStyle, 'selected', 'graphics', 'images', 0, 'url')) {
      selectedImg = buttonStyle.selected.graphics.images[0].url
    }
    return `
    width: 28px;
    height: 28px;
    background-image: url('${normalImg}');
    background-size: contain;
    background-repeat: no-repeat;
    &.active {
      background-image: url('${selectedImg}');
    }
  `
  }
)
/* --------------------------------------------- */

export const ButtonWrapper = styled.div`
  font-size: 15px;
  padding: 12px 0px 11px;
  font-weight: 600;
  min-width: 90px;
  border-radius: 5px;
  transition: all .2s ease-in-out;
  padding: 12px 18px 11px;
  a {
    font-size: 15px;
    padding: 12px 18px 11px !important;
    letter-spacing: .2px;
    font-weight: 600;
    min-width: 90px;
    border-radius: 5px;
    ${({ theme }) => {
      return `
        color : ${ utils.isNotNull(theme, 'normal', 'text') ? theme.normal.text : 'transparent'};
        `
    }};
  }

  &:hover {
    cursor: pointer;
    outline: none;
    ${({ theme }) => {
      return `
        color : ${utils.isNotNull(theme, 'focussed', 'text') ? theme.focussed.text : 'transparent'};
        background: ${utils.isNotNull(theme, 'focussed', 'background') ? theme.focussed.background : 'transparent'};
        border: 1px solid ${utils.isNotNull(theme, 'focussed', 'stroke') ? theme.focussed.stroke : 'transparent'};
        `
    }};
    a {
      ${({ theme }) => {
        return `
          color : ${utils.isNotNull(theme, 'focussed', 'text') ? theme.focussed.text : 'transparent'};
          `
      }};
    }
  }
  &:focus {
      outline: none;
  }
  ${({ theme }) => {
    return `
      color : ${ utils.isNotNull(theme, 'normal', 'text') ? theme.normal.text : 'transparent'};
      background: ${ utils.isNotNull(theme, 'normal', 'background') ? theme.normal.background : 'transparent'};
      border: 1px solid ${ utils.isNotNull(theme, 'normal', 'stroke') ? theme.normal.stroke : 'transparent'};
      `
  }};
`

/* --------------------------------------------- */

export const AvatarImg = styled.div(
  ({ theme: { buttonStyle } }) => {
    let normalImg = ''
    if (utils.isNotNull(buttonStyle, 'normal', 'graphics', 'images', 0, 'url')) {
      normalImg = buttonStyle.normal.graphics.images[0].url
    }
    return `
    width: 30px;
    height: 30px;
    background-image: url('${normalImg}');
    background-size: cover;
    background-repeat: no-repeat;
  `
  }
)

export const AvatarWrapper = styled.div(
  ({ theme: { buttonStyle } }) => {
    return `
    background-color: ${buttonStyle.normal.background};
    &.header__profile-head-img---active {
      background-color: ${buttonStyle.selected.background};
    }
  `
  }
)

export const PlayBtnAction = styled.div`
  border: solid 1px
    ${({ theme: { header } }) => header && header.text.secondary};
  & p {
    color: ${({ theme: { header } }) => header && header.text.secondary};
  }
  &:hover {
    background-color: ${({ theme: { header } }) =>
      header && header.text.secondary};
    & p {
      color: ${({ theme: { header } }) => header && header.text.primary};
    }
  }
`;


// ---------------------------

export const MenuButton = styled.div`
  margin-left: 14px;
  color: ${({ buttonStyle: { normal } }) => normal && normal.text};
  cursor: pointer;
  background: ${({ buttonStyle: { normal } }) => normal && normal.background};
    &:hover{
    color: ${({ buttonStyle: { focussed } }) => focussed && focussed.text};
  }
  &.active{
    color: ${({ buttonStyle: { selected } }) => selected && selected.text};
  }
`