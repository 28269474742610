import moment from 'moment-timezone'
import visitorInfo from 'visitor-info'
import { version } from '../config'
import utils from './utils'
import { mergeThemes, getThemeData, getGraphics, handleTag } from './contentfulThemeMerger'
import { routeMap } from '../route'

const visitor = visitorInfo()

const osMap = {
  'Mac OS': 'mac'
}

/** Returns browser name */
export const getBrowserName = (browserName = '', engineName = '') => {
  browserName = (browserName || '').toLowerCase()
  engineName = (engineName || '').toLowerCase()
  if (browserName === 'edge' && engineName === 'blink') browserName = 'edgeChromium'
  if (browserName) {
    if (['chrome', 'ie', 'edge', 'firefox', 'safari', 'opera', 'mobile safari', 'edgeChromium'].indexOf(browserName) === -1) {
      browserName = 'other'
    }
  }
  if(browserName === 'mobile safari') browserName = 'safari'
  return browserName || ''
}

/** Returns boolean based on platform validation */
export const isValidPlatform = (platformArr = [], platform = '') => {
  platformArr = platformArr || []
  platform = platform || ''
  return !platform ||
    platformArr.length === 0 ||
    platformArr.indexOf('all') !== -1 ||
    platformArr.indexOf(platform) !== -1
}

/** Returns boolean based on browser validation */
export const isValidBrower = (browserArr = [], browserString = '') => {
  browserArr = browserArr || []
  browserString = browserString || ''
  return browserArr.length === 0 ||
    browserArr.indexOf(browserString.toLowerCase()) !== -1
}

/** Returns boolean based on orientation validation */
export const isValidOrientation = (displayOrientationArr = [], orientation = '') => {
  displayOrientationArr = displayOrientationArr || []
  orientation = orientation || ''
  return orientation === '' ||
    displayOrientationArr.length === 0 ||
    displayOrientationArr.indexOf(orientation) !== -1
}

/** Returns boolean based on age validation */
export const isValidAge = (ageLimit = '', ageString = '') => {
  ageLimit = ageLimit || ''
  ageString = ageString || ''
  const ageLimitArr = ageLimit.split('-')
  return ageLimitArr.length === 0 ||
    ageLimitArr[0] === ageString ||
    (
      ageLimitArr.length === 2 &&
      Number(ageLimitArr[0]) <= Number(ageString) &&
      Number(ageLimitArr[1]) >= Number(ageString)
    )
}

/** Returns boolean based on gender validation */
export const isValidGender = (genderArr = [], gender = '') => {
  genderArr = genderArr || []
  gender = gender || ''
  return genderArr.length === 0 ||
    genderArr.indexOf(gender) !== -1 ||
    genderArr.indexOf('all') !== -1
}

/** Returns boolean based on profile type validation */
export const isValidProfileType = (profileTypeArr = [], profileType = '') => {
  profileTypeArr = profileTypeArr || []
  profileType = profileType || ''
  return profileTypeArr.length === 0 ||
    profileTypeArr.indexOf(profileType) !== -1
}

/** Returns boolean based on content type validation */
export const isValidContentType = (contentTypeArr = [], contentTypeString = '') => {
  contentTypeArr = contentTypeArr || []
  contentTypeString = contentTypeString || ''
  return contentTypeArr.length === 0 ||
    contentTypeArr.indexOf(contentTypeString) !== -1 // don't apply toLowerCase here.
}

/** Returns boolean based on subscription type validation */
export const isValidSubscriptionType = (subscriptionTypeArr = [], subscriptionTypeString = '') => {
  subscriptionTypeArr = subscriptionTypeArr || []
  subscriptionTypeString = subscriptionTypeString || ''
  return subscriptionTypeArr.length === 0 ||
    subscriptionTypeArr.indexOf(subscriptionTypeString.toLowerCase()) !== -1
}

/** Returns boolean based on build type validation */
export const isValidBuildType = (buildTypeArr = [], buildTypeString = '') => {
  buildTypeArr = buildTypeArr || []
  buildTypeString = buildTypeString || ''
  return buildTypeArr.length === 0 ||
    buildTypeArr.indexOf(buildTypeString) !== -1
}

/** Returns boolean based on build number validation */
export const isValidMinimumBuildNumber = (minimumBuildNumber = '', buildNumberString = '') => {
  minimumBuildNumber = minimumBuildNumber || ''
  buildNumberString = buildNumberString || ''
  return !minimumBuildNumber ||
    Number(minimumBuildNumber) <= Number(buildNumberString)
}

/** Returns boolean based on start date validation */
export const isValidStartDate = (startDate = '', startDateMoment = '') => {
  startDate = startDate || ''
  startDateMoment = startDateMoment || ''
  return !startDate ||
    (!!startDateMoment &&
      moment(startDateMoment).isAfter(moment(startDate)))
}

/** Returns boolean based on end date validation */
export const isValidEndDate = (endDate = '', endDateMoment = '') => {
  endDate = endDate || ''
  endDateMoment = endDateMoment || ''
  return !endDate ||
    (!!endDateMoment &&
      moment(endDateMoment).isBefore(moment(endDate)))
}

/** Returns boolean based on OS validation */
export const isValidOperatingSystem = (operatingSystemArr = [], operatingSystemString = '') => {
  operatingSystemArr = operatingSystemArr || []
  operatingSystemString = operatingSystemString || ''
  return operatingSystemArr.length === 0 ||
    operatingSystemArr.indexOf(operatingSystemString.toLowerCase()) !== -1 ||
    operatingSystemArr.indexOf(osMap[operatingSystemString]) !== -1
}

/** Returns boolean based on user source validation */
export const isValidUserSource = (userSource = [], userSourceString = '') => {
  userSource = userSource || []
  userSourceString = userSourceString || ''
  return userSource.length === 0 ||
    userSource.indexOf(userSourceString.toLowerCase()) !== -1
}

/** Returns boolean based on user type validation */
export const isValidUserType = (userTypeArr = [], userTypeString = '') => {
  userTypeArr = userTypeArr || []
  userTypeString = userTypeString || ''
  return userTypeArr.length === 0 ||
    userTypeArr.indexOf(userTypeString) !== -1
}

/** Returns boolean based on static page validation */
export const checkStaticPage = () => {
  const staticPaths = [routeMap.contactUs, routeMap.faq, routeMap.tos, routeMap.pp, '/auth', '/callback', '/initiateLogin', '/feedback']
  const isStaticPage = staticPaths.filter((path) => {
    return utils.startsWith(decodeURIComponent(window?.location?.pathname), path)
  }).length > 0
  return isStaticPage
}

export const isNoHeaderPage = (pathname) => {
  const noHeaderPaths = [routeMap.player]
  return noHeaderPaths.filter((path) => {
    return utils.startsWith(decodeURIComponent(pathname || window?.location?.pathname), path)
  }).length > 0
}

/** Returns array of filtered objects after doing all validations */
export const filter = (arr, orientation, options = {}) => {
  const isStaticPage = checkStaticPage()
  const query = utils.parse(window.location.search)
  const subscriptionTypeString = options.subscriptionType
  const profileTypeString = options.profileType
  const operatingSystemString = visitor.os.name
  const browserName = (visitor.browser || {}).name || ''
  const engineName = (visitor.engine || {}).name || ''

  let userTypeValue = ''
  let userSourceValue = ''

  if (utils.isNotNull(options, 'subscription')) {
    userTypeValue = options.subscription.customerType
    userSourceValue = options.subscription.source
  }

  if (!arr) return []

  return arr.filter(({ filter = {} }) => {
    const {
      platforms = [],
      browser = [],
      displayOrientation = [],
      contentType = [],
      age = '',
      gender = [],
      profileType = [],
      subscriptionType = [],
      buildType = [],
      minimumBuildNumber = '',
      startDate = '',
      endDate = '',
      operatingSystem = [],
      userType = [],
      userSource = []
    } = filter || {}

    return (
      isValidPlatform(platforms, (isStaticPage && query.platforms) || 'web') &&
      isValidBrower(browser, (isStaticPage && getBrowserName(query.browser)) || getBrowserName(browserName, engineName) || '') &&
      isValidOrientation(displayOrientation, (isStaticPage && query.orientation) || orientation || '') &&
      isValidContentType(contentType, (isStaticPage && query.contentType) || options.contentType || '') &&
      isValidAge(age, (isStaticPage && query.age) || options.age || '') &&
      isValidGender(gender, (isStaticPage && query.gender) || options.gender || '') &&
      isValidProfileType(profileType, (isStaticPage && query.profileType) || profileTypeString || '') &&
      isValidSubscriptionType(subscriptionType, (isStaticPage && query.subscriptionType) || subscriptionTypeString || 'anonymous') &&
      isValidBuildType(buildType, (isStaticPage && query.buildType) || version.buildType || '') &&
      isValidMinimumBuildNumber(minimumBuildNumber, (isStaticPage && query.minimumBuildNumber) || version.buildNumber || '') &&
      isValidStartDate(startDate, (isStaticPage && query.startDate) || new Date()) &&
      isValidEndDate(endDate, (isStaticPage && query.endDate) || new Date()) &&
      isValidOperatingSystem(operatingSystem, (isStaticPage && query.operatingSystem) || operatingSystemString || '') &&
      isValidUserType(userType, (isStaticPage && query.userType) || userTypeValue || '') &&
      isValidUserSource(userSource, (isStaticPage && query.userSource) || userSourceValue || '')
    )
  })
}

/** Returns array of landscape & portrait objects in an array */
export const filterComponentStyle = (arr = [], options = {}) => {
  const defaultComponentStyle = [{
    itemEdgeRadius: 0.25,
    itemOrientation: 1.778,
    itemSize: 'medium',
    maxItemTitleLines: 3,
    maxItemTopLabelLines: 1,
    showComponentTitle: true,
    showItemTitle: true,
    showItemTopLabel: false,
    type: 'horizontal_list'
  }]
  let landscape = filter(arr, 'landscape', options)
  let portrait = filter(arr, 'portrait', options)
  if (landscape.length === 0) landscape = defaultComponentStyle
  if (portrait.length === 0) portrait = defaultComponentStyle

  return [landscape[0], portrait[0]]
}

/** Returns new array with oject of only with keys 'key' & 'value' */
export const hydrateLabel = (arr = []) => {
  const newArray = arr.map((item = {}) => {
    const { key = '', value = '' } = item
    return { key, value }
  })
  return newArray
}

/** Returns filtered curatedData */
export const hydrateCuratedData = (curatedData = [], options) => {
  curatedData = filter(curatedData, '', options)
  return curatedData.map((props) => {
    const graphics = getGraphics(props)
    if (utils.isNotNull(props, 'graphics')) {
      props = { ...props, graphics: graphics }
    }
    return { ...props }
  })
}

/** Returns filtered config, componentStyles, componentTheme, overflowTheme */
export const hydratePageComponent = (component = {}, options = {}, themeData = {}) => {
  const { defaultPageTheme = {}, defaultComponentTheme = {} } = themeData
  let pConfig = {}
  let pComponentStyles = []
  let pComponentTheme = {}
  let pOverflowTheme = {}
  if (component.tagConfiguration) {
    const clonedTagConfiguration = JSON.parse(JSON.stringify(component.tagConfiguration));

    if (clonedTagConfiguration && clonedTagConfiguration.length) {
      for (let index = 0; index < clonedTagConfiguration.length; index++) {
        const config = clonedTagConfiguration[index];

        if (config?.tagStyle && config?.tagStyle[0]) {
          const xTheme = handleTag(config?.tagStyle[0], "body");
          config.tagStyle = xTheme;
          clonedTagConfiguration[index] = JSON.parse(JSON.stringify(config));
        }
      }
    }

    pConfig.tagConfiguration = clonedTagConfiguration;
  }
  if (utils.isNotNull(component, 'title')) {
    pConfig.title = component.title
  }
  if (utils.isNotNull(component, 'identifierExt')) {
    pConfig.identifierExt = component.identifierExt
  }
  if (utils.isNotNull(component, 'contents')) {
    const configs = filter(component.contents, '', options)
    if (utils.isNotNull(configs, 0)) {
      pConfig = { ...pConfig, ...configs[0] }
      if (utils.isNotNull(pConfig, 'curatedData')) {
        pConfig.curatedData = hydrateCuratedData(pConfig.curatedData, options)
      }
    }
  }
  if (utils.isNotNull(component, 'componentStyle')) {
    pComponentStyles = filterComponentStyle(component.componentStyle, options)
  }
  if (utils.isNotNull(component, 'theme')) {
    const theme = filter(component.theme, '', options)
    if (utils.isNotNull(theme, 0)) {
      pComponentTheme = getThemeData(theme[0], options)
      pComponentTheme = mergeThemes(defaultComponentTheme, pComponentTheme)
    } else if (defaultComponentTheme) pComponentTheme = defaultComponentTheme
  } else if (defaultComponentTheme) pComponentTheme = defaultComponentTheme
  if (utils.isNotNull(component, 'maxItems')) {
    pConfig.maxItems = component.maxItems
  }
  if (utils.isNotNull(component, 'overflowListingTheme')) {
    const theme = filter(component.overflowListingTheme, '', options)
    if (utils.isNotNull(theme, 0)) {
      pOverflowTheme = getThemeData(theme[0], options)
      pOverflowTheme = mergeThemes(defaultPageTheme, pOverflowTheme)
    } else if (defaultPageTheme) pOverflowTheme = defaultPageTheme
  } else if (defaultPageTheme) pOverflowTheme = defaultPageTheme
  return {
    config: pConfig,
    componentStyles: pComponentStyles,
    componentTheme: pComponentTheme,
    overflowTheme: pOverflowTheme
  }
}

/** Returns array of objects matching sysId */
export const getNew = (arr, localArr) => {
  const newArr = []
  arr.map(({ sys = {} }) => {
    const { id = '' } = sys
    localArr.map((props) => {
      const { sysId = '' } = props
      if (id === sysId) {
        newArr.push(props)
      }
      return null
    })
    return null
  })
  return newArr
}

/** Returns an oject which matches sysId */
export const getNewObj = (obj = {}, localArr = []) => {
  let newObj = {}
  const { sys = {} } = obj
  const { id = '' } = sys
  localArr.map((innerArr) => {
    innerArr.map((props = {}) => {
      const { sysId = '' } = props
      if (id === sysId) {
        newObj = props
      }
      return null
    })
    return null
  })
  return newObj
}
