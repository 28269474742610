import { AUTH_CONFIG, GUEST_USER_CONFIG, USER_MANAGEMENT_CONFIG, USER_MANAGEMENT_THEMES } from "../constants/actionTypes";
import { mergeThemes, getThemeData } from "../utils/contentfulThemeMerger";
import { filter } from "../utils/contentfulHandler";
import utils from "../utils/utils";

/** UserManagementConfig store */
export const fetchUserManagementConfig = (userManagement = [], options, themeData = {}) => {
  const { appThemeData = {} } = themeData || {};
  return (dispatch) => {
    userManagement = filter(userManagement, "", options) || [];
    const [guestUserConfigs, nonGuestUserConfigs] = userManagement.reduce(([p, f], e) => (e?.loginMethods?.includes("guest") ? [[...p, e], f] : [p, [...f, e]]), [[], []]);
    if (guestUserConfigs?.length) {
      dispatch({
        type: GUEST_USER_CONFIG,
        value: guestUserConfigs[0],
      });
    }
    if (utils.isNotNull(nonGuestUserConfigs, 0)) {
      const userManagementConfig = nonGuestUserConfigs[0];
      if (utils.isNotNull(userManagementConfig, "authConfigurations")) {
        let authConfig = userManagementConfig.authConfigurations;
        authConfig = filter(authConfig, "", options);
        authConfig = authConfig
          .map((config) => {
            if (utils.isNotNull(config)) {
              return config;
            }
            return null;
          })
          .filter((config) => config);
        userManagementConfig.authConfig = authConfig;
        if (utils.isNotNull(authConfig, 0)) {
          dispatch({
            type: AUTH_CONFIG,
            value: authConfig[0],
          });
        }
      }

      dispatch({
        type: USER_MANAGEMENT_CONFIG,
        value: userManagementConfig,
      });

      dispatch({
        type: USER_MANAGEMENT_THEMES,
        value: {
          loginTheme: getFinalTheme(appThemeData || {}, userManagementConfig.loginTheme || [], options || {}),
          profileTheme: getFinalTheme(appThemeData || {}, userManagementConfig.profileTheme || [], options || {}),
          registrationTheme: getFinalTheme(appThemeData || {}, userManagementConfig.registrationTheme || [], options || {}),
          myAccountTheme: getFinalTheme(appThemeData || {}, userManagementConfig.myAccountTheme || [], options || {}),
          resetPasswordTheme: getFinalTheme(appThemeData || {}, userManagementConfig.resetPasswordTheme || [], options || {}),
          themeProfileList: getFinalTheme(appThemeData || {}, userManagementConfig.themeProfileList || [], options || {}),
          themeProfileDetails: getFinalTheme(appThemeData || {}, userManagementConfig.themeProfileDetails || [], options || {}),
          themeProfilePopup: getFinalTheme(appThemeData || {}, userManagementConfig.themeProfileDetails || [], options || {}),
        },
      });
    }
    return Promise.resolve();
  };
};

/** Pass array of theme to get filter theme with appTheme merged. */
export function getFinalTheme(appThemeData = {}, themeArr = [], options = {}) {
  let registrationTheme = appThemeData || {};
  if (utils.isNotNull(themeArr)) {
    const filteredThemeArr = filter(themeArr, "", options) || [];
    if (filteredThemeArr[0]) {
      registrationTheme = mergeThemes(appThemeData || {}, getThemeData(filteredThemeArr[0] || {}, options) || {}) || {};
    }
  }
  return registrationTheme;
}
