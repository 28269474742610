import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const CloseIcon = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' {...rest}>
    <line fill='none' stroke={color} strokeWidth='3' strokeLinejoin='round' strokeMiterlimit='10' x1='61.9' y1='61.5' x2='2.1' y2='1.7' />
    <line fill='none' stroke={color} strokeWidth='3' strokeLinejoin='round' strokeMiterlimit='10' x1='2.1' y1='61.5' x2='61.9' y2='1.7' />
  </svg>
)
/* --------------------------------------------- */
CloseIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
CloseIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default CloseIcon
/* --------------------------------------------- */
