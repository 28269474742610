import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const GoBackArrowIcon = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' {...rest}>
    <line fill='none' stroke={color} strokeWidth='3.0508' strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10' x1='63' y1='32.5' x2='3' y2='32.5' />
    <polyline fill='none' stroke={color} strokeWidth='3.0508' strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10' points='25.7,51.9 2.6,31.9 26.8,10.9 ' />
  </svg>
)
/* --------------------------------------------- */
GoBackArrowIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
GoBackArrowIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default GoBackArrowIcon
/* --------------------------------------------- */
