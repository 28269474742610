import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const RewindIcon = ({ color, text, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' style={{ width: 30, height: 30 }} viewBox='0 0 200 200' {...rest}>
    <g>
      <title>background</title>
      <g display='none' overflow='visible' y='0' x='0' height='100%' width='100%' id='canvasGrid'>
        <rect fill='url(#gridpattern)' strokeWidth='0' y='0' x='0' height='100%' width='100%' />
      </g>
      <polygon id='svg_8' points='91.58552831411362,38.65989303588867 91.38498204946518,4.545454978942871 67.55135625600815,21.739887237548828' fill='none' stroke={color} style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} />
      <path id='svg_9' d='m91.712223,20.431044c36.763792,3.514884 65.621732,34.378315 65.843392,72.10263c0.232215,40.247011 -32.193386,73.063154 -72.440397,73.295369s-73.063154,-32.193386 -73.295369,-72.440397c-0.042221,-6.280349 0.717754,-12.370705 2.174373,-18.186625' fill='none' stroke={color} style={{ strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} />
    </g>
    <text id='10' fontWeight='normal' fontSize='75' fill={color}>
      <tspan x='25%' y='60%'>{text}</tspan>
    </text>
  </svg>
)
/* --------------------------------------------- */
RewindIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Text inside icon. */
  text: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
RewindIcon.defaultProps = {
  color: 'transparent',
  text: 25,
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default RewindIcon
/* --------------------------------------------- */
