import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../context/global.context";
import useAuthApi from "../apis/useAuthApi";
import { useSelector } from "react-redux";
import useGeneralApi from "../apis/useGeneralApi";
import { getUserAuthInfo } from "../utils/localStorageService";

export const useGuestLoginController = () => {
  /**
   * Find if user is loggedin
   * Yes - Do NOTHING
   * NO - Call guestlogin api and save token
   * -
   * On playback checking - If FREE & Guest Login enabled -> Play with guest token else current flow.
   */
  const { guestLogin } = useAuthApi();
  const { isAuthenticated, guestLoginStatus = "", dispatch, authCheckCompleted, isAppLoaded } = useContext(GlobalContext);
  const { guestUserConfig = {} } = useSelector((store) => store.userManagement);
  const [timeUntilNextRefresh, setTimeUntilNextRefresh] = useState(0); // In seconds.

  //   const isPartnerLoginEnabled = partnerUserConfig?.loginMethods?.includes("partner");
  const { getSessionState } = useGeneralApi();
  // Enabling guest login after splash, if user is not already loggedin & userManagement for guest is enabled from contentful.
  const guestLoginNeeded = !isAuthenticated && authCheckCompleted && guestUserConfig?.loginMethods?.includes("guest") && guestLoginStatus === "UNKNOWN";
  function doGuestLogin() {
    if (!isAppLoaded) return () => {};
    if (!guestUserConfig?.loginMethods?.includes("guest")) {
      dispatch({ type: "GUEST_LOGIN_STATUS", value: "NOT_NEEDED" });
    }
    const sessionState = getSessionState();
    if (guestLoginNeeded && sessionState === "INVALID_SESSION") {
      dispatch({ type: "GUEST_LOGIN_STATUS", value: "INITIATE" });
    }
  }
  useEffect(doGuestLogin, [guestLoginNeeded]); // Trieggering guest login

  const refreshTimer = useRef();
  const triggerGuestLogin = () => {
    const authInfo = getUserAuthInfo();
    let userName = "";
    if (authInfo.source === "GUEST" && authInfo.guestLoginResponse.userId) {
      userName = authInfo.guestLoginResponse.userId;
    }
    if (guestLoginStatus === "INITIATE") {
      setTimeUntilNextRefresh(0);
      guestLogin({ username: userName })
        .then((response) => {
          if (!response.duration) return;
          setTimeUntilNextRefresh(response.duration - 600); // Scheduling next refresh, 10 mins before expiry
        })
        .catch(() => {
          dispatch({ type: "GUEST_LOGIN_STATUS", value: "FAILED" });
        });
    }
    if (guestLoginStatus === "SUCCESS" && timeUntilNextRefresh) {
      // Start timer
      refreshTimer.current = setTimeout(() => {
        setTimeUntilNextRefresh(0);
        guestLogin({ username: userName }).then((response) => {
          if (!response.duration) return;
          setTimeUntilNextRefresh(response.duration - 600); // Scheduling next refresh, 10 mins before expiry
        });
      }, timeUntilNextRefresh * 1000); // Convert Seconds to Milliseconds
    } else {
      // clear refresh timer
      clearTimeout(refreshTimer.current);
    }
  };
  useEffect(triggerGuestLogin, [guestLoginStatus, timeUntilNextRefresh]);
};
