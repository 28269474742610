import React, { useEffect, useState, useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
/* --------------------------------------------- */
import useErrorHandler from '../../containers/useErrorHandler'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
import utils from '../../utils/utils'
import useErrorUtils from '../../utils/useErrorUtils'
import { splitLanguage, removeLocalStorageData } from '../../utils/localStorageService'
import { setRecentlyWatchedLocal, removeSessionStorageData, setLoginStateLocal, setLandingPageRendered } from '../../utils/sessionStorageService'
/* --------------------------------------------- */
import Spinner from '../Spinner'
/* --------------------------------------------- */
import useLoggly from '../../containers/useLoggly'
/* --------------------------------------------- */
import CloseIcon from '../icons/CloseIcon'
/* --------------------------------------------- */
import { openAndListenNewWin } from './LoginPopup/LoginPopup.helper'
/* --------------------------------------------- */
import useAuthApi from '../../apis/useAuthApi'
import useDetailsApi from '../../apis/useDetailsApi'
/* --------------------------------------------- */
import uuidv1 from 'uuid/v1'
import { useHistory } from 'react-router-dom'
import { routeMap } from '../../route'
/* --------------------------------------------- */
// LoginPopup component having logic for login popup.
const LoginPopup = props => {
  const [isShowSpinner, setShowSpinner] = useState(false)
  const [authConfig, setAuthConfig] = useState({})
  const [authState, setAuthState] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  /* --------------------------------------------- */
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  const { popupTheme: theme } = useSelector(store => store.themeConfigReducers)
  const { appConfig, appMessages } = useSelector(store => store.appConfigReducers)
  const { appTheme } = useSelector(store => store.themeConfigReducers)
  const history = useHistory();
  /* --------------------------------------------- */
  const { showError, hideError, hideAllErrors } = useErrorUtils()
  /* --------------------------------------------- */
  const { log } = useLoggly()
  /* --------------------------------------------- */
  const { trustedLogin } = useAuthApi()
  const { getRecentlyWatched } = useDetailsApi()
  /* --------------------------------------------- */
  const uuid = useRef(uuidv1())
  const loginPopupRef = useRef(null)
  /* --------------------------------------------- */
  const _uuid = uuid.current
  const {
    dispatch,
    appLanguage,
    isAuthenticated,
    isShowLoginPopup,
    loginPopupDetails: {
      isShowCloseIcon = false,
      onCloseClick = null,
      // onSignupClick = null,
      onLoginClick = null
    },
    dispatch: globalDispatch
  } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const { handleError } = useErrorHandler()
  /* --------------------------------------------- */
  const { authConfig: userAuthConfig = [] } = userManagementConfig
  /* --------------------------------------------- */
  /**
   * It: Sets logo url.
   */
  useEffect(() => {
    const graphics = appTheme.graphics || null
    if (graphics) {
      let imageURL = ''
      graphics.map(({ id, images }) => {
        if (id !== 'app_popup_logo') return null
        if (utils.isNotNull(images, '0', 'url')) {
          imageURL = `${images[0].url}`
        }
        return null
      })
      setLogoUrl(imageURL)
    }
  }, [appTheme.graphics])
  /* --------------------------------------------- */
  /**
   * It: Calls recentlyWatched api.
   * It: Stores recentlyWatched data in local.
   */
  useEffect(() => {
    if (!appConfig.baseUrl) return
    if (authState !== 'LOGIN_SUCCESS') return

    let isMounted = true
    let params = {
      page: 1,
      language: splitLanguage(appLanguage),
      size: 20
    }
    params = utils.stringify(params)
    dispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: false })
    getRecentlyWatched(appConfig.baseUrl, params)
      .then((res) => {
        if (!isMounted) return null
        const content = (res.content || [])
        setRecentlyWatchedLocal(content)
        dispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
        setAuthState('')
      }).catch((err) => {
        if (!isMounted) return null
        dispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
        setAuthState('')
      })

    return () => {
      isMounted = false
    }
  }, [
    appLanguage,
    getRecentlyWatched,
    appConfig.baseUrl,
    dispatch,
    authState
  ])
  /* --------------------------------------------- */
  /**
   * It: Hides login popup.
   * It: Sets authenticate sate globally.
   */
  useEffect(() => {
    if (authState === 'LOGIN_SUCCESS') {
      // log({
      //   type: 'Info',
      //   attr: {
      //     module: 'Authentication',
      //     event: 'login_Success'
      //   }
      // })
      dispatch({ type: 'IS_APP_LOADED', value: false })
      dispatch({ type: 'AUTHENTICATED' })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
      console.log('Authenticated.')
      dispatch({ type: "USER_NAVIGATION_FROM_LOGIN", value: true });
    }
  }, [authState, dispatch, log])
  /* --------------------------------------------- */
  useEffect(() => {
    if (authState === 'LOGIN_FAILURE') {
      console.log('Authentication Failure.')
    }
  }, [authState, dispatch])
  /* --------------------------------------------- */
  /**
   * It: Sets authConfig.
   */
  useEffect(() => {
    const authConfig = userAuthConfig.filter((config) => config.identityProvider === 'vdt')
    if (authConfig.length > 0) {
      setAuthConfig(authConfig[0])
    }
  }, [
    userAuthConfig
  ])
  /* --------------------------------------------- */
  /**
   * It: Show/hide spinner.
   */
  useEffect(() => {
    if (authState === 'LOGIN_PROGRESS') {
      setShowSpinner(true)
    } else {
      setShowSpinner(false)
    }
  }, [authState])
  /* --------------------------------------------- */
  /**
   * It: Enable/disable body scroll.
   */
  useEffect(() => {
    let contentEl = loginPopupRef
    if (isShowLoginPopup) {
      utils.disableScroll(contentEl, { reserveScrollBarGap: true })
      globalDispatch({ 
        type: 'POPUP_DETAILS', 
        value: {
          inViewport: true,
          useMargin: utils.hasMargin()
        }
      })
    }
    else {
      utils.enableScroll(contentEl)
      globalDispatch({ 
        type: 'POPUP_DETAILS', 
        value: {
          inViewport: false,
          useMargin: utils.hasMargin()
        }
      })
    }
  }, [globalDispatch, isShowLoginPopup])
  /* --------------------------------------------- */
  /**
   * It: Calls login api.
   * It: Sends login log.
   */
  const saveToken = (url) => {
    console.log('Location changed. New URL:' + url)
    const { code } = utils.parse(url)
    const uuid = (utils.parse(utils.parseUrl(url).search) || {}).state
    if (!code || (_uuid && uuid !== _uuid)) {
      setAuthState('LOGIN_FAILURE')
      hideAllErrors()
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
      showError({ type: 'UserLoginFailed' })
      return
    }
    let redirectUri = authConfig.redirectUri
    if (window.location.hostname === 'localhost') redirectUri = `${window.location.origin}/callback`
    setAuthState('LOGIN_PROGRESS')
    trustedLogin(appConfig.baseUrl, {
      code,
      identityProvider: authConfig.identityProvider,
      logoutExtendPeriod: authConfig.logoutExtendPeriod,
      accessTokenRefreshInterval: authConfig.accessTokenRefreshInterval,
      redirectUri
    }).then(() => {
      setAuthState('LOGIN_SUCCESS')
      log({
        type: 'Info',
        attr: {
          module: 'Authentication',
          event: 'login_success'
        }
      })
    }).catch((error) => {
      error = error || {}
      const newError = new Error(error.message)
      const errorCode = error.errorCode
      if (errorCode >= 7001 && errorCode <= 7005) {
        newError.errorCode = errorCode
      } else newError.errorCode = 4010
      newError.custom = { isInitialTrustedLogin: true }
      handleError(newError, {
        setAuthState,
        subscriptionUri: authConfig.subscriptionUri
      }, error)
    })
  }
  /* --------------------------------------------- */
  /**
   * When: On login click.
   * It: Opens login page in new window.
   */
  const handleLoginClick = () => {
    // if (props.isIntroScreen) props.setIntroStatus("LOGIN_PROGRESS");
    if (onLoginClick) {
      onLoginClick()
    } else {
      if (!authConfig.identityProvider) {
        setAuthState('LOGIN_FAILURE')
        hideAllErrors()
        dispatch({ type: 'HIDE_LOGIN_POPUP' })
        showError({ type: 'UserLoginFailed' })
        return
      }
      setAuthState('LOGIN_PROGRESS')
      const url = `${window.location.origin}/auth/${authConfig.identityProvider}/login?state=${_uuid}`
      setLoginStateLocal()
      openAndListenNewWin(url, {
        maxTime: 5 * 60 * 1000, // 5 minutes
        onProgress: () => {
          hideAllErrors()
          setAuthState('LOGIN_PROGRESS')
          log({
            type: 'Info',
            attr: {
              module: 'Authentication',
              event: 'login_inprogress'
            }
          })
        },
        onBlock: () => {
          const onPrimaryBtnClick = () => {
            setAuthState('')
            hideError({ type: 'LoginPopupError' })
            dispatch({ type: 'SHOW_LOGIN_POPUP' })
            dispatch({ type: 'USER_INFO', value: {} })
            removeLocalStorageData()
            removeSessionStorageData()
          }
          log({
            type: 'Info',
            attr: {
              module: 'Authentication',
              event: 'login_blocked'
            }
          })
          setAuthState('LOGIN_FAILURE')
          hideAllErrors()
          dispatch({ type: 'HIDE_LOGIN_POPUP' })
          showError({ type: 'LoginPopupError', value: { onPrimaryBtnClick } })
        },
        onFailure: () => {
          // const onPrimaryBtnClick = () => {
          //   setAuthState('')
          //   hideError({ type: 'UserLoginFailed' })
          //   dispatch({ type: 'SHOW_LOGIN_POPUP' })
          //   dispatch({ type: 'USER_INFO', value: {} })
          //   removeLocalStorageData()
          //   removeSessionStorageData()
          // }
          // setAuthState('LOGIN_FAILURE')
          // hideAllErrors()
          // dispatch({ type: 'HIDE_LOGIN_POPUP' })
          // showError({ type: 'UserLoginFailed', value: { onPrimaryBtnClick } })
          setAuthState('')
          hideAllErrors()
          dispatch({ type: 'SHOW_LOGIN_POPUP' })
        },
        onSuccess: url => {
          hideAllErrors()
          saveToken(url)
        },
      })
    }
  }

  useEffect(()=>{
    if(window.location.pathname === routeMap.gettingStarted && isAuthenticated) {
      setLandingPageRendered(true)
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
      history.push('/')
    }
  },[isAuthenticated, dispatch, history])
  /* --------------------------------------------- */
  /**
   * When: On signup click.
   * It: Redirects to singup page.
   */
  // const handleSignupClick = () => {
  //   if (onSignupClick) {
  //     onSignupClick()
  //   } else {
  //     window.location.href = authConfig.registrationUri
  //   }
  // }
  /* --------------------------------------------- */
  return (
    <>
      {(isShowLoginPopup || props.isIntroScreen) && !isAuthenticated &&
        <div className='confirm-popup-wrapper'>
          <PopUpContent ref={loginPopupRef} {...{ theme }} className='confirm-popup__content'>
            {!props.isIntroScreen && <div className="confirm-popup-header" style={isShowCloseIcon ? { marginTop: 10 } : {}}>
              {isShowCloseIcon && <div className='confirm-popup-header-close-btn'>
                <CloseIcon color={theme && theme.header && theme.header.accent.primary} style={{ width: 18, height: 18 }} onClick={onCloseClick} />
              </div>}
              {!isShowSpinner && logoUrl &&
                <div className="logo">
                  <img className='logo_img' src={logoUrl} alt='' />
                </div>
              }
              <h2>{appMessages.login_poup_title}</h2>
            </div>}
            {!isShowSpinner && <img alt='intro_image' className='login-intro-image' src={props.introImageUrl} />}
            {!isShowSpinner && <p>{appMessages.login_poup_description}</p>}
            {isShowSpinner && <p>{appMessages.label_login_progress_description}</p>}
            <div className='confirm-popup__btn'>
              {
                !isShowSpinner &&
                <>
                  {/* Hiding as per DC 9249 changes */}
                  {/* <div className='popup-btn popup-btn__positive' onClick={handleSignupClick}>{appMessages.login_register}</div>
                  <span className='popup-btn-subtitle'>{appMessages.registration_poup_title}</span> */}
                  <div className='popup-btn popup-btn__negative' onClick={handleLoginClick}>{appMessages.login_login}</div>
                </>
              }
              {isShowSpinner && <Spinner size={30} />}
            </div>
          </PopUpContent>
        </div>
      }
    </>
  )
}
/* --------------------------------------------- */
const PopUpContent = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};

  div.logo {
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
    
    .logo_img{
      height: 50px;
    }
  }

  h2 {
    color: ${({ theme: { header } }) => header && header.text.primary};
  }

  p {
    color: ${({ theme: { body } }) => body && body.text.primary};
  }

  .confirm-popup__btn{
    .popup-btn__positive {
      background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.text};
      border: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'stroke') ? `1px solid ${compositeStyle.primaryButton.normal.stroke}` : ''};
      border-radius: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'primaryButton', 'edgeRadius') && compositeStyle.primaryButton.edgeRadius * 16}px;
      box-shadow: ${({ theme: { compositeStyle } }) => (utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'shadow') && compositeStyle.primaryButton.normal.shadow) ? '0 6px 8px 2px rgba(0, 0, 0, 0.48)' : ''};
    }

    .popup-btn__negative {
      background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.secondaryButton.normal.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.secondaryButton.normal.text};
      border: ${({ theme: { compositeStyle } }) => compositeStyle && `1px solid ${compositeStyle.secondaryButton.normal.stroke}`};
      border: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'stroke') ? `1px solid ${compositeStyle.primaryButton.normal.stroke}` : ''};
      border-radius: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'edgeRadius') && compositeStyle.secondaryButton.edgeRadius * 16}px;
      box-shadow: ${({ theme: { compositeStyle } }) => (utils.isNotNull(compositeStyle, 'secondaryButton', 'normal', 'shadow') && compositeStyle.secondaryButton.normal.shadow) ? '0 6px 8px 2px rgba(0, 0, 0, 0.48)' : ''};
    }
  }

  .login-intro-image {
    height: 30vh;
    width: 100%;
  }
`
/* --------------------------------------------- */
export default LoginPopup
/* --------------------------------------------- */
