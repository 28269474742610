import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const ForwardIcon = ({ color, text, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' style={{ width: 30, height: 30 }} viewBox='0 0 200 200' {...rest}>
    <g>
      <title>background</title>
      <rect id='canvas_background' x='-1' y='-1' fill='none' width='172' height='172' />
      <g id='canvasGrid' style={{ display: 'none' }}>
        <rect style={{ display: 'inline' }} fill='none' width='170' height='170' />
      </g>
    </g>
    <g>
      <title>Layer 1</title>
      <g id='svg_10'>
        <g id='svg_5' transform='matrix(1.0555208660662174,0,0,1.0555208660662174,-6.005889348348337e-7,0)' style={{ display: 'none' }}>
          <rect id='svg_7' x='-99.7' y='-98.9' style={{ display: 'inline' }} width='360' height='360' />
        </g>
        <polygon id='svg_8' fill='none' stroke={color} style={{ strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} points='101.9,21.7 78.1,4.5 77.9,38.7' />
        <path
          id='svg_9' fill='none' stroke={color} style={{ strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} d='M155.5,75.2c1.5,5.8,2.2,11.9,2.2,18.2c-0.2,40.2-33,72.7-73.3,72.4s-72.7-33-72.4-73.3C12.1,54.8,41,23.9,77.7,20.4'
        />
      </g>
    </g>
    <text id='10' fontWeight='normal' fontSize='75' fill={color}>
      <tspan x='25%' y='60%'>{text}</tspan>
    </text>
  </svg>
)
/* --------------------------------------------- */
ForwardIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Text inside icon. */
  text: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
ForwardIcon.defaultProps = {
  color: 'transparent',
  text: 25,
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default ForwardIcon
/* --------------------------------------------- */
