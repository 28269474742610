import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const ScrollToTop = ({ history, children }) => {

    /**
     * It: Scrolls page to top on route change.
     */
    useEffect(() => {
      if(history?.location?.pathname !== '/') {
        let isMounted = true
        const unlisten = history.listen(() => {
            setTimeout(() => {
                if (!isMounted) return
                window.scrollTo(0, 0);
            }, 100)
        });
        return () => {
            unlisten();
            isMounted = false
        }
      }
    }, [history]);

    return <>{children}</>;
}

export default withRouter(ScrollToTop);
