import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const ForwardIconFilled = ({ color, text, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' style={{ width: 30, height: 30 }} viewBox='0 0 200 200' {...rest}>
    <g>
      <title>background</title>
      <rect id='canvas_background' x='-1' y='-1' fill='none' width='172' height='172' />
      <g id='canvasGrid' style={{ display: 'none' }}>
        <rect style={{ display: 'inline' }} fill='none' width='170' height='170' />
      </g>
    </g>
    <g>
      <title>Layer 1</title>
      <g id='svg_10_1_'>
        <g id='svg_5_1_' transform='matrix(1.0555208660662174,0,0,1.0555208660662174,-6.005889348348337e-7,0)' style={{ display: 'none' }}>
          <rect id='svg_7_1_' x='-99.7' y='-98.9' style={{ display: 'inline' }} width='360' height='360' />
        </g>
        <polygon id='svg_8_1_' fill='none' stroke={color} style={{ strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} points='101.9,21.7 78.1,4.5 77.9,38.7' />
        <path
          id='svg_9_1_' fill='none' stroke={color} style={{ strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} d='M155.5,75.2c1.5,5.8,2.2,11.9,2.2,18.2c-0.2,40.2-33,72.7-73.3,72.4s-72.7-33-72.4-73.3C12.1,54.8,41,23.9,77.7,20.4'
        />
      </g>
      <g id='svg_28_1_'>
        <g id='svg_20_1_' transform='matrix(5.750410125562829,0,0,5.750410125562829,-60.628521792965856,319.27238534707556)'>
          <g id='svg_21_1_'>
            <g id='svg_22_1_'>
              <g id='svg_23_1_'>
                <g id='svg_24_1_'>
                  <path
                    id='svg_26_1_' fill={color} d='M24.6-48.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.4-0.4-0.4-0.6l0-5.9c0-0.3,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.1,0.7,0.1l4.1,3c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2-0.1,0.4-0.3,0.6L24.6-48.2L24.6-48.2z'
                  />
                  <path
                    id='svg_25_1_' fill={color} stroke={color} style={{ strokeWidth: '0.5' }} d='M25.5-26c-3.6,0-6.9-1.4-9.5-3.9c-2.5-2.5-3.9-5.9-3.9-9.5c0-6.9,5.2-12.5,12-13.2c0.4,0,0.7,0.2,0.8,0.6c0,0.4-0.2,0.7-0.6,0.8c-6.1,0.6-10.7,5.7-10.7,11.8c0,3.2,1.2,6.2,3.5,8.5c2.2,2.2,5.2,3.5,8.4,3.5c0,0,0.1,0,0.1,0c6.5,0,11.8-5.3,11.9-11.8c0-1-0.1-2-0.4-3c-0.1-0.4,0.1-0.8,0.5-0.9c0.4-0.1,0.8,0.1,0.9,0.5c0.3,1.1,0.4,2.2,0.4,3.3C38.7-31.9,32.7-26,25.5-26'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <text id='10' fontWeight='bold' fontSize='75' fill={color}>
      <tspan x='25%' y='60%'>{text}</tspan>
    </text>
  </svg>

)
/* --------------------------------------------- */
ForwardIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Text inside icon. */
  text: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
ForwardIconFilled.defaultProps = {
  color: 'transparent',
  text: 25,
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default ForwardIconFilled
/* --------------------------------------------- */
