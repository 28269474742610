import React from 'react'
import PropTypes from 'prop-types'
import './ToggleSwitch.scss'
/* ---------------------------------------------------------------------------------------------------- */
// ToggleSwitch components for displaying toggle switch.
const ToggleSwitch = ({ checked, onChange }) => {
    return (
        <div className='toggle-switch'>
            <label className="switch">
                <input type="checkbox" {...{ checked, onChange }} />
                <span className="slider round"></span>
            </label>
        </div>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
ToggleSwitch.propTypes = {
    /** Checked state of the component. */
    checked: PropTypes.bool,
    /** Handler function for onChange event. */
    onChange: PropTypes.func
}
/* ---------------------------------------------------------------------------------------------------- */
ToggleSwitch.defaultProps = {
    checked: false,
    onChange: () => { }
}
/* ---------------------------------------------------------------------------------------------------- */
export default ToggleSwitch
/* ---------------------------------------------------------------------------------------------------- */
