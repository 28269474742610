import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const NextBtnOutlineIcon = ({ color, next, ...rest }) => (

  <svg xmlns='http://www.w3.org/2000/svg' {...rest}>
    <defs>
      <path id='path_1' d='m0.000048,0.069023l16.3119,0l0,29.4548l-16.3119,0l0,-29.4548z' />
      <path id='path_2' d='m0,29.608457l23.3224,0l0,-29.6086l-23.3224,0l0,29.6086z' />
    </defs>
    <g>
      <title>background</title>
      <rect fill='none' id='canvas_background' height='402' width='582' y='-1' x='-1' />
    </g>
    <g>
      <title>Layer 1</title>
      <g id='Group-3'>
        <g id='Group-6'>
          <g id='Group-3'>
            <path fill='none' id='Clip-2' d='m6.293907,0.069023l16.3119,0l0,29.4548l-16.3119,0l0,-29.4548z' />
            <g id='svg_1'>
              <path fill={color} id='Fill-1' d='m6.715817,29.464823c0.09047,0.04 0.18619,0.059 0.28142,0.059c0.17429,0 0.34572,-0.0647 0.4781,-0.1876l14.90527,-13.8095c0.1423,-0.1319 0.2238,-0.3167 0.2252,-0.5105c0.0014,-0.1943 -0.0771,-0.3805 -0.2176,-0.5143l-14.90478,-14.23807c-0.20381,-0.19428 -0.50285,-0.24904 -0.76285,-0.13809c-0.25857,0.11048 -0.42667,0.36476 -0.42667,0.64667l0,28.04759c0,0.2795 0.16571,0.5329 0.42191,0.6448zm0.98523,-2.2553l0,-24.79186l13.17476,12.58526l-13.17476,12.2066z' />
            </g>
          </g>
          <path fill='none' id='Clip-5' d='m6.293859,29.608457l23.3224,0l0,-29.6086l-23.3224,0l0,29.6086z' />
          <g id='svg_2'>
            <path fill={color} id='Fill-4' d='m28.188619,29.523657l1.42762,0l0,-29.5238l-1.42762,0l0,29.5238z' />
          </g>
        </g>
      </g>
      <text stroke={color} transform='matrix(0.5852863788604736,0,0,0.5852863788604736,1.6782941855490208,21.072635874152184) ' textAnchor='start' fontFamily='Open Sans, Helvetica, Arial, sans-serif' fontSize='24' id='svg_6' y='45.752632' x='4.052632' strokeOpacity='null' strokeWidth='0' fill={color}>{next} </text>
    </g>
  </svg>

  //   <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 30" style={{ width: 23, height: 30 }} >
  //     <defs>
  //         <path id="a" d="M0 .069h16.312v29.455H0z"/>
  //         <path id="c" d="M0 29.608h23.322V0H0z"/>
  //     </defs>
  //     <g fill="none" fill-rule="evenodd" transform="translate(.51)">
  //         <g transform="translate(0 .085)">
  //             <mask id="b" fill="#fff">
  //                 <use href="#a"/>
  //             </mask>
  //             <path fill="#FFD200" d="M1.407 2.418V27.21l13.175-12.207L1.407 2.418zM.703 29.524A.704.704 0 0 1 0 28.82V.772A.703.703 0 0 1 1.19.264l14.904 14.238a.703.703 0 0 1-.007 1.025L1.18 29.337a.702.702 0 0 1-.478.187z" mask="url(#b)"/>
  //         </g>
  //         <mask id="d" fill="#fff">
  //             <use href="#c"/>
  //         </mask>
  //         <path fill="#FFD200" d="M21.895 29.524h1.427V0h-1.427z" mask="url(#d)"/>
  //         <text stroke='#ffd400' transform='matrix(0.5852863788604736,0,0,0.5852863788604736,1.6782941855490208,21.072635874152184) ' text-anchor='start' font-family='Open Sans, Helvetica, Arial, sans-serif' font-size='24' id='svg_6' y='45.752632' x='4.052632' stroke-opacity='null' stroke-width='0' fill={color}>{next} </text>

  //     </g>
  // </svg>

)
/* --------------------------------------------- */
NextBtnOutlineIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
NextBtnOutlineIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default NextBtnOutlineIcon
/* --------------------------------------------- */
