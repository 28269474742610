import { getNewObj } from '../utils/contentfulHandler'
import getButtonStyle from './getButtonStyle'
import getThemeSection from './getThemeSection'

// Helper function to form contentful tree
const getCompositeStyle = (contentful) => {
  const buttonStyleLocal = getButtonStyle(contentful)
  const themeSectionLocal = getThemeSection(contentful)
  const compositeStyleLocal = contentful.compositeStyle
  return compositeStyleLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      primaryButton = {}, secondaryButton = {}, primaryToggle = {}, secondaryToggle = {},
      tertiaryButton = {}, sliderIndicator = {}, selectorStyle = {}, dropdownStyle = {},
      toastTheme = {}, inputBox = {}, calendarStyle = {}
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      primaryButton: getNewObj(primaryButton, [buttonStyleLocal]),
      secondaryButton: getNewObj(secondaryButton, [buttonStyleLocal]),
      primaryToggle: getNewObj(primaryToggle, [buttonStyleLocal]),
      secondaryToggle: getNewObj(secondaryToggle, [buttonStyleLocal]),
      tertiaryButton: getNewObj(tertiaryButton, [buttonStyleLocal]),
      sliderIndicator: getNewObj(sliderIndicator, [buttonStyleLocal]),
      selectorStyle: getNewObj(selectorStyle, [buttonStyleLocal]),
      dropdownStyle: getNewObj(dropdownStyle, [buttonStyleLocal]),
      toastTheme: getNewObj(toastTheme, [themeSectionLocal]),
      inputBox: getNewObj(inputBox, [themeSectionLocal]),
      calendarStyle: getNewObj(calendarStyle, [themeSectionLocal])
    }
  })
}

export default getCompositeStyle
