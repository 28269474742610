import React, { createContext, useReducer } from "react";

/** Global initial state */
const INITIAL_STATE = {
    isOpenSettingsPopup: false,
    settingsTitle: ''
};

/** Global context */
const GlobalNewStateContext = createContext();
const GlobalNewDispatchContext = createContext();

/** Global reducer */
const GlobalNewReducer = (state, action) => {
    switch (action.type) {
        case "OPEN_SETTINGS_POPUP":
            return { ...state, isOpenSettingsPopup: true };
        case "CLOSE_SETTINGS_POPUP":
            return { ...state, isOpenSettingsPopup: false };
        case "SETTINGS_TITLE":
            return { ...state, settingsTitle: action.value };
        default:
            return state;
    }
};

/** Global provider */
const GlobalNewProvider = (props) => {
    const [state, dispatch] = useReducer(GlobalNewReducer, INITIAL_STATE);

    return (
        <GlobalNewStateContext.Provider value={state}>
            <GlobalNewDispatchContext.Provider value={dispatch}>
                {props.children}
            </GlobalNewDispatchContext.Provider>
        </GlobalNewStateContext.Provider>
    );
};

/** Custom hook for global state */
export const useGlobalNewState = () => {
    const context = React.useContext(GlobalNewStateContext);
    if (context === undefined) {
        throw new Error("useGlobalNewDispatch must be used within a GlobalNewProvider");
    }
    return context;
};

/** Custom hook for global dispatch */
export const useGlobalNewDispatch = () => {
    const context = React.useContext(GlobalNewDispatchContext);
    if (context === undefined) {
        throw new Error("useGlobalNewDispatch must be used within a GlobalNewProvider");
    }
    return context;
};

/** Custom hook for global state & dispatch */
export const useGlobalNew = () => {
    return [useGlobalNewState(), useGlobalNewDispatch()];
};

export default GlobalNewProvider;
