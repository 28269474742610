import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const PlayOutlineIcon = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='23' height='40' viewBox='0 0 23 40' {...rest}>
    <defs>
      <path id='play-icon-a' d='M0 0h21.48v39.609H0z' />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(.91)'>
      <mask id='play-icon-b' fill='#fff'>
        <use xlinkHref='#play-icon-a' />
      </mask>
      <path fill={color} d='M1.867 3.156V36.51L19.2 20.146 1.867 3.156zM.933 39.609A.934.934 0 0 1 0 38.675V.933A.935.935 0 0 1 1.588.267L21.2 19.493a.932.932 0 0 1-.013 1.345L1.574 39.354a.93.93 0 0 1-.64.255z' mask='url(#play-icon-b)' />
    </g>
  </svg>
)
/* --------------------------------------------- */
PlayOutlineIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
PlayOutlineIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default PlayOutlineIcon
/* --------------------------------------------- */
