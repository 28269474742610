import React, { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import useAuthApi from '../../apis/useAuthApi'
/* --------------------------------------------- */
import utils from '../../utils/utils'
import useErrorUtils from '../../utils/useErrorUtils'
import { removeLocalStorageData } from '../../utils/localStorageService'
import { getStateUUID, removeStateUUID, removeSessionStorageData, getLoginStateLocal } from '../../utils/sessionStorageService'
/* --------------------------------------------- */
import useLoggly from '../../containers/useLoggly'
import useLogout from '../../containers/useLogout'
/* --------------------------------------------- */
import useErrorHandler from '../../containers/useErrorHandler'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
import Spinner from '../../components/Spinner'
/* --------------------------------------------- */

const InitiateLogin = () => {
  const [authConfig, setAuthConfig] = useState({})
  const [authState, setAuthState] = useState('')
  const [isShowSpinner, setShowSpinner] = useState(false)
  /* --------------------------------------------- */
  const { showError, hideError, hideAllErrors } = useErrorUtils()
  /* --------------------------------------------- */
  const { trustedLogin } = useAuthApi()
  const logout = useLogout()
  /* --------------------------------------------- */
  const { handleError } = useErrorHandler()
  /* --------------------------------------------- */
  const { log } = useLoggly()
  /* --------------------------------------------- */
  const { dispatch: globalDispatch, isForceLogout } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  const { appConfig } = useSelector(store => store.appConfigReducers)
  /* --------------------------------------------- */
  const { authConfig: userAuthConfig = [] } = userManagementConfig
  /* --------------------------------------------- */
  /**
   * It: Sets authConfig.
   */
  useEffect(() => {
    const authConfig = userAuthConfig.filter((config) => config.identityProvider === 'vdt')
    if (authConfig.length > 0) {
      setAuthConfig(authConfig[0])
    }
  }, [ userAuthConfig ])
  /* --------------------------------------------- */
  /**
   * It: Calls login api.
   * It: Sends login log.
   */
  useEffect(() => {
    const onPrimaryBtnClick = () => {
      setAuthState('')
      hideError({ type: 'UserLoginFailed' })
      removeLocalStorageData()
      removeSessionStorageData()
      window.location.href = window.location.origin
  }
  const saveToken = (url) => {
    // Get state stored in session
    const _uuid = getStateUUID()
    removeStateUUID()
    const { code } = utils.parse(url)
    const uuid = (utils.parse(utils.parseUrl(url).search) || {}).state
    // Don't login if state stored in session and the state received during login are different
    if (!code || (_uuid && uuid !== _uuid)) {
      setAuthState('LOGIN_FAILURE')
      hideAllErrors()
      showError({ type: 'UserLoginFailed', value: { onPrimaryBtnClick } })
      return
    }
    let redirectUri = authConfig.redirectUri
    if (window.location.hostname === 'localhost') {
      redirectUri = `${window.location.origin}/callback`
    }
    setAuthState('LOGIN_PROGRESS')
    // Perform login
    if(!getLoginStateLocal()) {
      trustedLogin(appConfig.baseUrl, {
        code,
        identityProvider: authConfig.identityProvider,
        logoutExtendPeriod: authConfig.logoutExtendPeriod,
        accessTokenRefreshInterval: authConfig.accessTokenRefreshInterval,
        redirectUri
      }).then(() => {
        setAuthState('LOGIN_SUCCESS')
        window.location.href = window.location.origin
        log({
          type: 'Info',
          attr: {
            module: 'Authentication',
            event: 'login_success'
          }
        })
      }).catch((error) => {
        setAuthState('LOGIN_FAILURE')
        error = error || {}
        const newError = new Error(error.message)
        const errorCode = error.errorCode
        if (errorCode >= 7001 && errorCode <= 7005) {
          newError.errorCode = errorCode
        } else newError.errorCode = 4010
        newError.custom = { isInitialTrustedLogin: true }
        handleError(newError, {
          setAuthState,
          subscriptionUri: authConfig.subscriptionUri
        }, error)
      })
    }
  }
  /* --------------------------------------------- */
  if (!authConfig.identityProvider) {
    return
  }
    setAuthState('LOGIN_PROGRESS')
    const url = String(window.location.href)
    saveToken(url)
  }, [authConfig, authConfig.identityProvider,
    hideAllErrors, hideError, showError, log, globalDispatch,
    appConfig.baseUrl, 
    authConfig.accessTokenRefreshInterval, authConfig.logoutExtendPeriod, 
    authConfig.redirectUri, authConfig.subscriptionUri, handleError, trustedLogin])
  /* --------------------------------------------- */
  // This useEffect exectues code to logout a user.
  useEffect(() => {
    if (isForceLogout) {
      logout()
    }
  }, [isForceLogout, logout])
  /* --------------------------------------------- */
  /**
   * It: Show/hide spinner.
   */
  useEffect(() => {
    if (authState === 'LOGIN_PROGRESS') {
      setShowSpinner(true)
    } else {
      setShowSpinner(false)
    }
  }, [authState])
  /* --------------------------------------------- */
  /**
   * It: Perform side effects if user login was successful
   */
  useEffect(() => {
    if (authState === 'LOGIN_SUCCESS') {
      globalDispatch({ type: 'IS_APP_LOADED', value: false })
      globalDispatch({ type: 'AUTHENTICATED' })
      globalDispatch({ type: 'USER_NAVIGATION_FROM_LOGIN', value: true });
    }
  }, [authState, globalDispatch, log])
  /* --------------------------------------------- */
  if(isShowSpinner) {
    return <div><Spinner size={50} /></div>
  }
  return <></>
}
/* --------------------------------------------- */
export default InitiateLogin