import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useScript from 'react-script-hook'

/** Custom hook for engage initialization */
const useEngage = () => {
  const [isEngageLoading] = useScript({ src: '/static/js/engage-core.js', checkForExisting: true })
  const { analyticsIntegrations } = useSelector(store => store.analytics)

  useEffect(() => {
    if (isEngageLoading) return
    if (!window.ENGAGE) return
    if (!analyticsIntegrations || analyticsIntegrations.length === 0) return

    const config = analyticsIntegrations.filter((config) => {
      return config.analyticsService === 'Engage'
    })
    if (config.length > 0) {
      if (config[0].analyticsConfiguration) {
        const analyticsConfig = JSON.parse(config[0].analyticsConfiguration)
        if (analyticsConfig) {
          const ACCOUNT_ID = analyticsConfig.accountId
          const PROJECT_ID = analyticsConfig.projectId
          const OPTIONS = {}
          if (analyticsConfig.api_key) OPTIONS.apiKey = analyticsConfig.api_key
          if (analyticsConfig.endpoint) OPTIONS.endpoint = analyticsConfig.endpoint
          if (analyticsConfig.eventsOnly) OPTIONS.eventsOnly = analyticsConfig.eventsOnly
          try {
            window.ENGAGE.initialize(ACCOUNT_ID, PROJECT_ID, OPTIONS)
          } catch (e) {
            console.log('ENGAGE ERROR:', e)
          }
        }
      }
    }
  }, [analyticsIntegrations, isEngageLoading])

  return null
}

export default useEngage
