import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
/* ---------------------------------------------------------------------------------------------------- */
/** Custom hook consists of custom errors that are not available in contentful */
/* ---------------------------------------------------------------------------------------------------- */
const useCustomErrorConfigs = () => {
  const { appMessages } = useSelector(store => store.appConfigReducers)
  const { appTheme } = useSelector(store => store.themeConfigReducers)
  const { catalogConfig = {} } = useSelector(store => store.catalog)
  /* ------------------------------------------------------------------------------------------------ */
  const appMessagesRef = useRef(appMessages)
  const themeRef = useRef(catalogConfig.theme)
  const appThemeRef = useRef(appTheme)
  /* ------------------------------------------------------------------------------------------------ */
  useEffect(() => {
    appMessagesRef.current = appMessages
  }, [appMessages])
  /* ------------------------------------------------------------------------------------------------ */
  useEffect(() => {
    themeRef.current = catalogConfig.theme
  }, [catalogConfig.theme])
  /* ------------------------------------------------------------------------------------------------ */
  useEffect(() => {
    appThemeRef.current = appTheme
  }, [appTheme])
  /* ------------------------------------------------------------------------------------------------ */
  const getErrors = useCallback(() => {
    const appMessages = appMessagesRef.current
    const theme = themeRef.current
    const errorConfigs = [{
      type: 'AddFavouriteSuccess',
      title: appMessages.label_details_add_favourite,
      uiType: 'toast',
      primaryActionType: 'custom',
      primaryActionLabel: appMessages.label_details_go,
      theme
    }, {
      type: 'RemoveFavouriteSuccess',
      title: appMessages.label_error_removed_from_favourites,
      uiType: 'toast',
      primaryActionType: 'custom',
      primaryActionLabel: appMessages.label_details_go,
      theme
    }, {
      type: 'CopyToClipboardSuccess',
      title: appMessages.label_details_url_copied,
      uiType: 'toast',
      theme
    },
    {
      type: 'LoginFailed',
      title: 'Something went wrong',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: 'Close',
      theme
    },
    {
      type: 'LoginFailedWrongCredentials',
      title: 'Incorrect username or password.',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: 'Close',
      theme
    },
    {
      type: 'failedToGetUserDetails',
      title: 'Error while fetching user details',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: 'Close',
      theme
    },
    {
      type: 'changePasswordFailedCheckPassword',
      title: 'Incorrect password',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: 'Close',
      theme
    },
    {
      type: 'changePasswordFailed',
      title: 'Error while updating password',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: 'Close',
      theme
    },
    {
      type: 'SignupFailedUserExist',
      title: 'An account with the given email already exists',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: 'Close',
      theme
    },
    {
      type: 'ForgetPasswordFailed',
      title: 'Something went wrong',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: 'Close',
      theme
    },
    {
      type: 'SignupFailed',
      title: 'Something went wrong',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: 'Close',
      theme
    },
    {
      type: 'profileUpdateFailed',
      title: 'Error while updating user details',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: 'Close',
      theme
    },
    {
      type: 'incorrectPIN',
      title: 'Please Enter the correct PIN.',
      uiType: 'toast',
      theme
    },
    {
      type: 'pinNotMatching',
      title: 'Please Enter the same PIN you entered earlier.',
      uiType: 'toast',
      theme
    },
    {
      type: 'newProfileCreated',
      title: 'New profile created.',
      uiType: 'toast',
      theme
    },
    {
      type: 'profileDeleted',
      title: 'Profile deleted.',
      uiType: 'toast',
      theme
    },
    {
      type: 'errorDeletingProfile',
      title: 'Error while deleting profile.',
      uiType: 'toast',
      theme
    },
    {
      type: 'profileUpdated',
      title: 'Profile updated.',
      uiType: 'toast',
      theme
    },
    {
      type: 'errorUpdatingProfile',
      title: 'Error in updating profile.',
      uiType: 'toast',
      theme
    },
    {
      type: 'errorCreatingProfile',
      title: 'Error in Creating Profile. Please Retry',
      uiType: 'toast',
      theme
    },
    {
      type: 'tosNotAccepted',
      title: 'Please accept Terms of Use & Privacy Policy.',
      uiType: 'toast',
      theme
    },
    {
      type: 'ServerError',
      title: 'Erreur',
      description: 'Une erreur de serveur s’est produite. Veuillez rouvrir l’application et réessayer.',
      uiType: 'fullscreen',
      code: 'A-6001',
      theme: appThemeRef.current,
      primaryActionType: 'retry',
      primaryActionLabel: 'RÉESSAYER'
    }]
    /* -------------------------------------------------------------------------------------------- */
    return errorConfigs
  }, [])
  /* ------------------------------------------------------------------------------------------------ */
  return { getErrors }
}
/* ---------------------------------------------------------------------------------------------------- */
export default useCustomErrorConfigs
/* ---------------------------------------------------------------------------------------------------- */
