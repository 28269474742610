import { useCallback, useContext, useEffect, useRef } from 'react'
/* --------------------------------------------- */
import { getFavouritesLocal, setFavouritesLocal } from '../utils/sessionStorageService'
/* --------------------------------------------- */
import useAuthApi from './useAuthApi'
import useGeneralApi from './useGeneralApi'
import { GlobalContext } from '../context/global.context'
/* --------------------------------------------- */
import VERSION from '../constants/apiVersionMap'
/* --------------------------------------------- */
const useFavApi = () => {
  const { verifySession } = useAuthApi()
  const { getData, postData, deleteData } = useGeneralApi()
  const { userInfo, userToken, selectedProfile } = useContext(GlobalContext)
  const userInfoRef = useRef(userInfo)
  const userTokenRef = useRef(userToken)
  /* --------------------------------------------- */
  useEffect(() => {
    userInfoRef.current = userInfo
  }, [userInfo])
  /* --------------------------------------------- */
  useEffect(() => {
    userTokenRef.current = userToken
  }, [userToken])
  /* --------------------------------------------- */
  const getFavourites = useCallback((baseUrl, { language, size, page }) => {
    const userInfo = userInfoRef.current || {}
    const userId = userInfo.userId || ''
    const profileId = selectedProfile ? selectedProfile.profileId : ''
    let url = `${baseUrl}${VERSION.getFavourites}/accounts/profiles/favourites?limit=50&language=${language}&page=${page}`
    if (userId) url += `&userId=${userId}`
    if (profileId) url += `&profileId=${profileId}`
    if (size) url += `&size=${size}`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return getData(url, {}, { Authorization })
    }, { url })
  }, [getData, selectedProfile, verifySession])
  /* --------------------------------------------- */
  const deleteFavourites = useCallback((baseUrl, mediaId) => {
    const userInfo = userInfoRef.current
    const userId = userInfo.userId || ''
    const profileId = selectedProfile ? selectedProfile.profileId : ''
    let url = `${baseUrl}${VERSION.deleteFavourites}/accounts/profiles/favourites?`
    if (userId) url += `&userId=${userId}`
    if (profileId) url += `&profileId=${profileId}`
    const favourites = getFavouritesLocal() || []
    updateFavouritesDB(favourites, false, mediaId)
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return deleteData(url, { media_id: mediaId }, { Authorization })
    }, { url })
  }, [deleteData, selectedProfile, verifySession])
  /* --------------------------------------------- */
  const addFavourites = useCallback((baseUrl, mediaId, { language }, details) => {
    const userInfo = userInfoRef.current
    const userId = userInfo.userId || ''
    const profileId = selectedProfile ? selectedProfile.profileId : ''
    let url = `${baseUrl}${VERSION.addFavourites}/accounts/profiles/favourites?language=${language}`
    if (userId) url += `&userId=${userId}`
    if (profileId) url += `&profileId=${profileId}`
    const favourites = getFavouritesLocal() || []
    updateFavouritesDB(favourites, true, mediaId, details)
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return postData(url, { media_id: mediaId }, { Authorization })
    }, { url })
  }, [postData, selectedProfile, verifySession])
  /* --------------------------------------------- */
  const isFavouritedItem = useCallback((baseUrl, mediaId) => {
    const userInfo = userInfoRef.current
    const userId = userInfo.userId || ''
    const profileId = selectedProfile ? selectedProfile.profileId : ''
    let url = `${baseUrl}${VERSION.isFavouritedItem}/accounts/profiles/favourites/${mediaId}?`
    if (userId) url += `&userId=${userId}`
    if (profileId) url += `&profileId=${profileId}`
    const favourites = getFavouritesLocal() || []
    verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return getData(url, {}, { Authorization })
        .then((res) => {
          updateFavouritesDB(favourites, res.isFavorite, mediaId)
        })
        .catch(() => { })
    }, {
      isSkipSessionError: true,
      url
    }).catch(() => {})
    const [isFavourited] = checkIfFavourited(favourites, mediaId)
    return isFavourited
  }, [
    getData,
    verifySession,
    selectedProfile
  ])
  /* --------------------------------------------- */
  return {
    getFavourites,
    addFavourites,
    deleteFavourites,
    isFavouritedItem
  }
}
/* --------------------------------------------- */
const checkIfFavourited = (DB, mediaId) => {
  let isFavourited = false
  let i = 0
  for (; i < DB.length; i++) {
    const uid = (DB[i] || {}).uid || null
    if (uid && mediaId === uid) {
      isFavourited = true
      break
    }
  }
  return [isFavourited, i]
}
/* --------------------------------------------- */
const updateFavouritesDB = (DB, isFavorite, mediaId, details) => {
  const [isAlreadyFavourited, index] = checkIfFavourited(DB, mediaId)
  if (isAlreadyFavourited !== isFavorite) {
    if (isFavorite) {
      DB.unshift(details)
    } else {
      DB.splice(index, 1)
    }
    setFavouritesLocal(DB)
  }
}
/* --------------------------------------------- */
export default useFavApi
