import React, { useLayoutEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import PropTypes from 'prop-types'
import { GlobalContext } from '../context/global.context'
import localAppTheme from '../defaults/theme.default.json'
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
/* --------------------------------------------- */
import utils from '../utils/utils'
/* --------------------------------------------- */
// Spinner component having spinner logic & theming logic.
const Spinner = ({ IsLoading, size, isUseGlobalState }) => {
  const { appTheme } = useSelector(store => store.themeConfigReducers)
  const { isShowSpinner } = useContext(GlobalContext)
  const [CurrentIsLoading, updateIsLoading] = useState(false)
  const [spinnerColor, updateSpinnerColor] = useState(localAppTheme.body.accent.secondary)
  /* --------------------------------------------- */
  /**
   * It: Show/hide spinner.
   */
  useLayoutEffect(() => {
    updateIsLoading(IsLoading)
  }, [IsLoading])
  /* --------------------------------------------- */
  /**
   * It: Updates spinner color.
   */
  useLayoutEffect(() => {
    if (utils.isNotNull(appTheme, 'body', 'accent', 'secondary')) {
      updateSpinnerColor(appTheme.body.accent.secondary)
    }
  }, [appTheme])
  /* --------------------------------------------- */
  // useEffect(() => {
  //   if (CurrentIsLoading) {
  //     disableBodyScroll()
  //   }
  //   return () => {
  //     enableBodyScroll()
  //   }
  // }, [CurrentIsLoading])
  /* --------------------------------------------- */
  if (!isShowSpinner && isUseGlobalState) return null
  return (
    <div className={`spinner-wrapper ${CurrentIsLoading ? 'spinner-open' : 'spinner-close'}`}>
      <ClipLoader
        sizeUnit='px'
        size={size}
        color={spinnerColor}
        loading
        margin='5px'
      />
    </div>
  )
}
/* --------------------------------------------- */
Spinner.propTypes = {
  /** Size of the spinner. */
  size: PropTypes.number,
  /** loading state. */
  IsLoading: PropTypes.bool
}
/* --------------------------------------------- */
Spinner.defaultProps = {
  size: 70,
  IsLoading: true
}
/* --------------------------------------------- */
export default Spinner
/* --------------------------------------------- */
