import { useContext, useCallback, useRef } from 'react'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
/* --------------------------------------------- */
import { removeLocalStorageData } from '../utils/localStorageService'
import { removeSessionStorageData } from '../utils/sessionStorageService'
/* --------------------------------------------- */
import useAnalytics from './useAnalytics'
import useLoggly from './useLoggly'
/* --------------------------------------------- */
import useErrorUtils from '../utils/useErrorUtils'
/* --------------------------------------------- */
// Custom hook to handle api errors.
const useErrorHandler = () => {
  const { dispatch, userInfo } = useContext(GlobalContext)
  const { sendAnalytics } = useAnalytics()
  const { log } = useLoggly()
  const { showError, hideAllErrors } = useErrorUtils()
  /* --------------------------------------------- */
  const userInfoRef = useRef(userInfo)
  /* --------------------------------------------- */
  const handleError = useCallback((error = {}, other = {}, resErrorInfo = {}) => {
    const {
      errorCode = -1, errorStatus = '', errorMessage = '', apiUrl = '', custom = {},
      requestBody = '', responseBody = '', responseId = '', code = '', errorType = '', retry_count
    } = error
    const { isHideErrorScreens = false, isInitialTrustedLogin = false } = custom
    const { setAuthState = () => { }, subscriptionUri = '' } = other
    /* --------------------------------------------- */
    const errorInfo = {
      error_message: errorMessage,
      request_url: apiUrl,
      mw_error_code: errorCode,
      response_code: errorStatus,
      request_body: requestBody,
      request_id: responseId,
      response_body: responseBody,
      retry_count: retry_count,
      ...resErrorInfo
    }
    // User details that is to be sent to analytics during user logout 
    const uInfo = {
      user_id: userInfoRef.current.userId || '',
      user_email_hash: userInfoRef.current.emailHash || '',
      user_type: userInfoRef.current.customerType || '',
      user_source: userInfoRef.current.source || ''
    }
    /* --------------------------------------------- */
    if (!isHideErrorScreens && (errorCode === 7001 || errorCode === 4010)) {
      if (errorCode === 4010 && isInitialTrustedLogin) {
        const onPrimaryBtnClick = () => {
          dispatch({ type: 'FORCE_LOGOUT' })
        }
        sendAnalytics({ type: '_user.logout', attr: uInfo })
        hideAllErrors()
        setAuthState('LOGIN_FAILURE')
        showError({ type: 'UserLoginFailed', value: { onPrimaryBtnClick }, errorMessage, errorInfo })
        dispatch({ type: 'HIDE_LOGIN_POPUP' })
      } else {
        const onPrimaryBtnClick = () => {
          removeLocalStorageData()
          dispatch({ type: 'SESSION_ERROR', value: false })
          dispatch({ type: 'FORCE_LOGOUT' })
        }
        sendAnalytics({ type: '_user.logout', attr: uInfo })
        hideAllErrors()
        dispatch({ type: 'SESSION_ERROR', value: true })
        dispatch({ type: 'UNAUTHENTICATED' })
        setAuthState('LOGIN_FAILURE')
        showError({ type: 'LoginSessionExpire', value: { onPrimaryBtnClick }, errorMessage, errorInfo })
        dispatch({ type: 'HIDE_LOGIN_POPUP' })
        dispatch({ type: 'USER_INFO', value: {} })
        removeSessionStorageData()
      }
    } else if (!isHideErrorScreens && errorCode === 7002) {
      const onPrimaryBtnClick = () => {
        dispatch({ type: 'FORCE_LOGOUT' })
      }
      const onSecondaryBtnClick = () => {
        window.close()
      }
      sendAnalytics({ type: '_user.logout', attr: uInfo })
      dispatch({ type: 'UNAUTHENTICATED' })
      setAuthState('LOGIN_FAILURE')
      showError({ type: 'LoginUnsupportedUserType', value: { onPrimaryBtnClick, onSecondaryBtnClick }, errorMessage, errorInfo })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
    } else if (!isHideErrorScreens && errorCode === 7003) {
      sendAnalytics({ type: '_user.logout', attr: uInfo })
      dispatch({ type: 'UNAUTHENTICATED' })
      setAuthState('LOGIN_FAILURE')
      showError({ type: 'UserAccountCreationFailed', errorMessage, errorInfo })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
    } else if (!isHideErrorScreens && errorCode === 7004) {
      sendAnalytics({ type: '_user.logout', attr: uInfo })
      dispatch({ type: 'UNAUTHENTICATED' })
      setAuthState('LOGIN_FAILURE')
      showError({ type: 'UserProfileSavingFailed', errorMessage, errorInfo })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
    } else if (!isHideErrorScreens && errorCode === 7005) {
      const onPrimaryBtnClick = () => {
        window.location.href = subscriptionUri
      }
      const onSecondaryBtnClick = () => {
        dispatch({ type: 'FORCE_LOGOUT' })
      }
      sendAnalytics({ type: '_user.logout', attr: uInfo })
      dispatch({ type: 'UNAUTHENTICATED' })
      setAuthState('LOGIN_FAILURE')
      showError({ type: 'LoginUnsupportedSubscriptionType', value: { onPrimaryBtnClick, onSecondaryBtnClick }, errorMessage, errorInfo })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
    } else if (isHideErrorScreens && (errorCode === 7005 || errorCode === 7002)){
      return// refresh token case. dont log error for 7005 and 7002 mw error codes 
    }else if (apiUrl) {
      log({
        type: 'Error',
        attr: {
          module: 'General',
          error_code: code || 'A-4001',
          error_type: errorType || 'API Error',
          error_message: errorMessage,
          request_url: apiUrl,
          mw_error_code: errorCode,
          response_code: errorStatus,
          request_body: requestBody,
          request_id: responseId,
          response_body: responseBody,
          errorInfo,
          error_shown: false,
          pageUrl:window.location.href,
          retry_count: retry_count
        }
      })
    }
  }, [dispatch, log, sendAnalytics, showError, hideAllErrors])
  /* --------------------------------------------- */
  return {
    handleError
  }
}
/* --------------------------------------------- */
export default useErrorHandler
