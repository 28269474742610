import { getNew } from "../utils/contentfulHandler";
import getThemeSection from "./getThemeSection";

// Helper function to form contentful tree
const getTagConfigurationComponent = (contentful) => {
  const themeSectionLocal = getThemeSection(contentful);

  const tagConfigurationLocal = contentful.tagConfiguration;
  return tagConfigurationLocal.map(({ sys = {}, fields = {} }) => {
    const { id = "", contentType = {} } = sys;
    const { sys: contentTypeSys = {} } = contentType;
    const { id: contentTypeId = "" } = contentTypeSys;
    const { tagStyle = [] } = fields;
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      tagStyle: getNew(tagStyle, themeSectionLocal),
    };
  });
};

export default getTagConfigurationComponent;
