import React, { useEffect, useState, useRef, useContext } from 'react'
import styled from 'styled-components'
/* --------------------------------------------- */
import useGeneralApi from '../../apis/useGeneralApi'
/* --------------------------------------------- */
import { PlayerContext } from '../../context/player.context'
/* --------------------------------------------- */
// Thumbnails component to display thumbnails in player
const Thumbnails = ({ duration }) => {
  const [imgArray, updateImgArray] = useState([])
  const [isShowThumbnail, setShowThumbnail] = useState(null)
  // const [height, updateHeight] = useState('')
  // const [width, updateWidth] = useState('')
  const [scale, updateScale] = useState('')
  const [imgurl, updateImgurl] = useState('')
  const { getData } = useGeneralApi()
  const { thumbnailUrl } = useContext(PlayerContext)

  const thumbnail = useRef(null)

  /**
   * It: Show/hide thumbnail.
   */
  useEffect(() => {
    if (thumbnailUrl) setShowThumbnail(true)
    else setShowThumbnail(false)
  }, [thumbnailUrl])

  /**
   * It: Fetches thumbnail.
   * It: Sets thumbnail in a state.
   */
  useEffect(() => {
    let isMounted = true
    const mouseOutEventHandler = () => {
      if (thumbnail && thumbnail.current) {
        thumbnail.current.classList.remove('thumbnail-wrapper---open')
        thumbnail.current.style.left = null + 'px'
      }
    }

    thumbnailUrl && getData(thumbnailUrl).then((res) => {
      if (!isMounted) return
      const response = {
        count: res.imageCount,
        startTime: res.startTime,
        endTime: res.endTime
      }
      updateImgArray(res.thumbnails)
      // updateHeight(res.height)
      // updateWidth(res.width)

      const inialScale = getScale(0, duration, response.count)
      updateScale(inialScale)
    }).catch((err) => {
      console.error(err)
      setShowThumbnail(false)
    })

    const scrubber = document.getElementById('rail-track')
    scrubber.addEventListener('mouseout', mouseOutEventHandler)

    return () => {
      isMounted = false
      scrubber.removeEventListener('mouseout', mouseOutEventHandler)
    }
  }, [thumbnailUrl, getData, duration])

  /**
   * It: Sets scrubber events.
   */
  useEffect(() => {
    let isMounted = true
    const mouseMoveEventHandler = (event) => {
      if (!isMounted) return
      if (thumbnail && thumbnail.current) {
        const scrubber = document.getElementById('rail-track')
        const totalW = scrubber.offsetWidth
        const half = thumbnail.current.offsetWidth / 2
        const currentPos = event.offsetX
        let left = currentPos - half
        thumbnail.current.classList.add('thumbnail-wrapper---open')
        const mousePositionInPercentage = currentPos * 100 / totalW
        const mousePositionInMillisecond = Math.floor(duration * mousePositionInPercentage / 100)
        const imgUrl = getImageFor(mousePositionInMillisecond, imgArray, scale)
        updateImgurl(imgUrl)
        if (currentPos <= half) {
          left = 0
        } else if (currentPos >= totalW - half) {
          left = totalW - (half * 2)
        }
        thumbnail.current.style.left = left + 'px'
      }
    }

    const touchMoveEventHandler = (e) => {
      var rect = e.target.getBoundingClientRect()
      var rectWidht = e.target.clientWidth
      var x = e.touches[0].clientX - rect.left // x position within the element.
      if (x <= 0) {
        mouseMoveEventHandler({ offsetX: 0 })
      } else if (x >= rectWidht) {
        mouseMoveEventHandler({ offsetX: rectWidht })
      } else {
        mouseMoveEventHandler({ offsetX: x })
      }
    }

    const touchStartEventHandler = (e) => {
      var rect = e.target.getBoundingClientRect()
      var x = e.touches[0].clientX - rect.left // x position within the element.
      mouseMoveEventHandler({ offsetX: x })
    }

    const scrubber = document.getElementById('rail-track')
    scrubber.addEventListener('mousemove', mouseMoveEventHandler)
    scrubber.addEventListener('touchmove', touchMoveEventHandler)
    scrubber.addEventListener('touchstart', touchStartEventHandler)

    return () => {
      isMounted = false
      scrubber.removeEventListener('mousemove', mouseMoveEventHandler)
      scrubber.removeEventListener('touchmove', touchMoveEventHandler)
      scrubber.removeEventListener('touchstart', touchStartEventHandler)
    }
  }, [imgArray, scale, duration])

  /**
   * It: Gets scale.
   */
  const getScale = (startTime, endTime, count) => {
    return parseFloat(endTime - startTime) / parseFloat(count)
  }

  /**
   * It: Gets image in the scale.
   */
  const getImageFor = (milliSeconds, thumbnails, scale) => {
    if (scale !== 0) {
      const imageIndex = parseInt(parseFloat(milliSeconds) / scale)
      if (imageIndex < thumbnails.length) {
        return thumbnails[imageIndex]
      }
    }
    return null
  }

  if (!isShowThumbnail) return null
  return (
    <div className='thumbnail-wrapper' ref={thumbnail}>
      <ImgContainer url={imgurl} />
    </div>
  )
}

const ImgContainer = styled.div`
  background-image:url(${({ url }) => url}) ;
  height:100%;
  width:100%;
  border-radius: 3px;
  background-size: contain;
`

export default Thumbnails
