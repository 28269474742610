import styled from "styled-components";
import utils from "../../utils/utils";
/* ---------------------------------------------------------------------------------------------------- */
// Styled components used in Button component.
export const Wrapper = styled.div`
  button {
    ${({ theme }) => {
        return `
            color : ${ utils.isNotNull(theme, 'normal', 'text') ? theme.normal.text : 'transparent'};
            background: ${ utils.isNotNull(theme, 'normal', 'background') ? theme.normal.background : 'transparent'};
            border: 1px solid ${ utils.isNotNull(theme, 'normal', 'stroke') ? theme.normal.stroke : 'transparent'};
            `
    }};
  }

  button:hover {
    ${({ theme }) => {
        return `
            color : ${utils.isNotNull(theme, 'focussed', 'text') ? theme.focussed.text : 'transparent'};
            background: ${utils.isNotNull(theme, 'focussed', 'background') ? theme.focussed.background : 'transparent'};
            border: 1px solid ${utils.isNotNull(theme, 'focussed', 'stroke') ? theme.focussed.stroke : 'transparent'};
            `
    }};
  }

  button:active {
    ${({ theme }) => {
        return `
            color : ${utils.isNotNull(theme, 'pressed', 'text') ? theme.pressed.text : 'transparent'};
            background: ${utils.isNotNull(theme, 'pressed', 'background') ? theme.pressed.background : 'transparent'};
            border: 1px solid ${utils.isNotNull(theme, 'pressed', 'stroke') ? theme.pressed.stroke : 'transparent'};
            `
    }};
  }

  button:focus {
    ${({ theme }) => {
        return `
            color : ${utils.isNotNull(theme, 'selected', 'text') ? theme.selected.text : 'transparent'};
            background: ${utils.isNotNull(theme, 'selected', 'background') ? theme.selected.background : 'transparent'};
            border: 1px solid ${utils.isNotNull(theme, 'selected', 'stroke') ? theme.selected.stroke : 'transparent'};
            `
    }};
  }

  button:active:focus {
    ${({ theme }) => {
        return `
            color : ${ utils.isNotNull(theme, 'selectedFocussed', 'text') ? theme.selectedFocussed.text : 'transparent'};
            background: ${ utils.isNotNull(theme, 'selectedFocussed', 'background') ? theme.selectedFocussed.background : 'transparent'};
            border: 1px solid ${ utils.isNotNull(theme, 'selectedFocussed', 'stroke') ? theme.selectedFocussed.stroke : 'transparent'};
            `
    }};
  }

  button:disabled {
    ${({ theme }) => {
        return `
            color : ${ utils.isNotNull(theme, 'disabled', 'text') ? theme.disabled.text : 'transparent'};
            background: ${ utils.isNotNull(theme, 'disabled', 'background') ? theme.disabled.background : 'transparent'};
            border: 1px solid ${ utils.isNotNull(theme, 'disabled', 'stroke') ? theme.disabled.stroke : 'transparent'};
            `
    }};
  }

  `
/* ---------------------------------------------------------------------------------------------------- */
