import {
  APP_CONFIG, MAINTENANCE_CONFIG,
  ALLOWED_LOCALES, APP_MESSAGES, SELECTED_LOCALE, APPSTORE_CONFIG
} from '../constants/actionTypes'
import { filter } from '../utils/contentfulHandler'
import { themeConfig } from './themeConfig'

/** AppConfig store */
export const appConfig = (config = {}, options = {}) => {
  return (dispatch) => {
    let { contentfulTree: application = [], appId = '', locale = '' } = config || {}
    const selectedLang = locale || ''
    dispatch({
      type: SELECTED_LOCALE,
      value: selectedLang
    })

    application = application.filter((app) => {
      return app.sysId === appId
    })

    const { type = '', baseConfiguration = [] } = application[0] || {}

    const {
      baseApiUrl = '',
      maintenanceConfig = [],
      localizationConfig = [],
      appStoreConfig = []
    } = baseConfiguration[0] || {}

    dispatch({
      type: APP_CONFIG,
      value: { type, baseUrl: baseApiUrl }
    })

    const maintenance = filter(maintenanceConfig, '', options)
    if (maintenance.length > 0) {
      const { maintenanceMessage, showMaintenanceMessage } = maintenance[0]
      dispatch({
        type: MAINTENANCE_CONFIG,
        value: { maintenanceMessage, showMaintenanceMessage }
      })
    }

    const appStore = filter(appStoreConfig, '', options)
    if (appStore.length > 0) {
      dispatch({
        type: APPSTORE_CONFIG,
        value: appStore[0]
      })
    }

    const filteredLocaleConfig = filter(localizationConfig, '', options)
    if (filteredLocaleConfig.length > 0) {
      let { languages = [], labelGroups = [] } = filteredLocaleConfig[0]
      languages = languages || []
      labelGroups = labelGroups || []
      languages = filter(languages, '', options)
      labelGroups = filter(labelGroups, '', options)
      languages = languages.map((item) => {
        return item
      })
      dispatch({
        type: ALLOWED_LOCALES,
        value: languages
      })

      const messages = {}
      if (labelGroups.length > 0) {
        labelGroups.map(({ labels = [] }) => {
          labels.map(({ key = '', value = '' }) => {
            messages[key] = value
            return null
          })
          return null
        })
      }
      dispatch({
        type: APP_MESSAGES,
        value: messages
      })
    }

    const themePromise = dispatch(themeConfig(options, application))

    return Promise.all([themePromise])
  }
}
