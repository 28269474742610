import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const SearchIcon = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' width='18' height='18' {...rest}>
    <g id='Layer_2_1_' style={{ display: 'none' }}>
      <rect x='-148' y='-148' style={{ display: 'inline' }} width='360' height='360' />
    </g>
    <g>
      <g id='Ellipse_80'>
        <path
          fill={color} d='M26,8.1c10.6,0,19.2,8.5,19.2,18.9S36.6,45.8,26,45.8S6.9,37.4,6.9,27S15.5,8.1,26,8.1 M26,2.4
          C12.3,2.4,1.2,13.4,1.2,27S12.3,51.6,26,51.6s24.9-11,24.9-24.6S39.8,2.4,26,2.4L26,2.4z'
        />
      </g>
      <path id='Tracé_5468' fill='none' stroke={color} strokeWidth='5.7106' d='M41.3,43l19.5,16.7' />
    </g>
  </svg>
)
/* --------------------------------------------- */
SearchIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
SearchIcon.defaultProps = {
  color: 'transparent'
}
/* --------------------------------------------- */
export default SearchIcon
/* --------------------------------------------- */
