/** Store inital state */
export default {
  /** appConfigReducers */
  isAppConfig: false,
  contentfulApplicationRes: {},
  contentfulLocalesRes: {},
  appConfig: {},
  maintenanceConfig: {},
  allowedLocales: [],
  appMessages: {},
  isAppMessagesReady: false,
  selectedLocale: "",
  appStoreConfig: {},
  /** featuresReducers */
  isFeatureConfigRecieved: false,
  playerFeature: {},
  supportInformation: {},
  catalogFeature: {},
  revenueFeature: {},
  introConfig: {},
  introThemeHeaderConfig: {},
  /** themeConfigReducers */
  isMenuList: false,
  contentfulNavigationRes: {},
  footerList: [],
  maxVisisbleMenuItems: 1,
  specialList: [],
  isAppTheme: false,
  contentfulDefaultPageThemeRes: {},
  contentfulDefaultComponentThemeRes: {},
  contentfulDefaultPopupThemeRes: {},
  contentfulFeaturesRes: {},
  appTheme: {},
  componentTheme: {},
  pageTheme: {},
  supportPageTheme: {},
  playerTheme: {},
  metaTheme: {},
  popupTheme: {},
  /** analytics */
  analyticsIntegrations: [],
  /** search */
  searchConfig: {},
  searchComponents: {},
  /** support */
  supportConfig: {},
  /** userManagement */
  userManagementConfig: {},
  /** navigation */
  pageComponent: {},
  footerMenuConfig: [],
  primaryMenuConfig: {},
  specialMenuConfig: [],
  secondaryMenuConfig: {},
  catalogConfig: {},
  guestUserConfig: {},
};
