import styled from "styled-components";
import utils from "../../utils/utils";
/* ---------------------------------------------------------------------------------------------------- */
// Styled components for ToggleSwitch component.
export const Wrapper = styled.div`

.slider {
    background-color: ${({ theme }) => {
      if (
        utils.isNotNull(
          theme,
          "compositeStyle",
          "primaryToggle",
          "normal",
          "background"
        )
      ) {
        return theme.compositeStyle.primaryToggle.normal.background;
      } else {
        return "transparent";
      }
    }};
  }

  input:checked + .slider {
    background-color: ${({ theme }) => {
      if (
        utils.isNotNull(
          theme,
          "compositeStyle",
          "primaryToggle",
          "selected",
          "background"
        )
      ) {
        return theme.compositeStyle.primaryToggle.selected.background;
      } else {
        return "transparent";
      }
    }};
  }

  .slider:before {
    background-color: ${({ theme }) => {
      if (
        utils.isNotNull(
          theme,
          "compositeStyle",
          "primaryToggle",
          "selected",
          "image"
        )
      ) {
        return theme.compositeStyle.primaryToggle.selected.image;
      } else {
        return "transparent";
      }
    }};
  }
`;
/* ---------------------------------------------------------------------------------------------------- */
