import { combineReducers } from 'redux'
/* --------------------------------------------- */
import appConfigReducers from './appConfigReducers'
import featuresReducers from './featuresReducers'
import themeConfigReducers from './themeConfigReducers'
import analytics from './analytics.reducer'
import search from './search.reducer'
import support from './support.reducer'
import userManagement from './userManagement.reducer'
import profileManagement from './profileManagement.reducer'
import navigation from './navigation.reducer'
import catalog from './catalog.reducer'
/* --------------------------------------------- */
/** Root reducer */
const rootReducer = combineReducers({
  appConfigReducers,
  featuresReducers,
  themeConfigReducers,
  analytics,
  search,
  support,
  userManagement,
  profileManagement,
  navigation,
  catalog
})
/* --------------------------------------------- */
export default rootReducer
/* --------------------------------------------- */
