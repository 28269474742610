import { RECENTLY_WATCHED, FAVOURITES, RECENT_SEARCHES, STATE_UUID, LOGIN_WINDOW, LANDING_PAGE_RENDERED } from './keys'

/** Utility functions to read & write in session storage */

const sessionStorage = window.store.sessionStorage

export const getSessionStorage = (key = '') => {
  if (!key) return {}
  return JSON.parse(sessionStorage.getItem(key)) || null
}

export const setSessionStorage = (key = '', value = {}) => {
  if (!key) return false
  value = JSON.stringify(value || {})
  sessionStorage.setItem(key, value)
  return true
}

export const removeSessionStorage = (key = '') => {
  sessionStorage.removeItem(key)
  return true
}

export const getRecentlyWatchedLocal = () => getSessionStorage(RECENTLY_WATCHED)

export const setRecentlyWatchedLocal = (recentlyWatched = []) => setSessionStorage(RECENTLY_WATCHED, recentlyWatched)

export const removeRecentlyWatchedLocal = () => removeSessionStorage(RECENTLY_WATCHED)

export const getFavouritesLocal = () => getSessionStorage(FAVOURITES)

export const setFavouritesLocal = (favourites = []) => setSessionStorage(FAVOURITES, favourites)

export const removeFavouritesLocal = () => removeSessionStorage(FAVOURITES)

export const setLandingPageRendered = (landingPageRendered = false) => setSessionStorage(LANDING_PAGE_RENDERED, landingPageRendered)

export const getLandingPageRendered = () => getSessionStorage(LANDING_PAGE_RENDERED)

export const removeLandingPageRendered = () => removeSessionStorage(LANDING_PAGE_RENDERED)

export const getRecentSearchesLocal = () => getSessionStorage(RECENT_SEARCHES)

export const setRecentSearchesLocal = (recentSearches = []) => setSessionStorage(RECENT_SEARCHES, recentSearches)

export const removeRecentSearchesLocal = () => removeSessionStorage(RECENT_SEARCHES)

export const setLoginStateLocal = () => setSessionStorage(LOGIN_WINDOW, true)

export const getLoginStateLocal = () => getSessionStorage(LOGIN_WINDOW)

export const removeLoginStateLocal = () => removeSessionStorage(LOGIN_WINDOW)

export const removeSessionStorageData = () => {
  removeRecentlyWatchedLocal()
  removeFavouritesLocal()
  removeRecentSearchesLocal()
  removeLoginStateLocal()
  removeLandingPageRendered()
}

export const setStateUUID = (uuid) => setSessionStorage(STATE_UUID, uuid)

export const getStateUUID = () => getSessionStorage(STATE_UUID)

export const removeStateUUID = () => removeSessionStorage(STATE_UUID)
