import { USER_AUTH_INFO, APP_LANGUAGE, SELECTED_PLAYER_QUALITY, AUTO_PLAY_NEXT_EPISODE, SELECTED_SUBTITLE_TRACK, SELECTED_PROFILE, GRAPHICS } from './localStorageKeys'

/** Utility functions to read & write in local storage */

const localStorage = window.store.localStorage

export const getUserAuthInfo = () => {
  return JSON.parse(localStorage.getItem(USER_AUTH_INFO)) || {}
}

export const setUserAuthInfo = (authInfo = null, options = {}) => {
  const { merge = false } = options
  if (merge) {
    const oldInfo = getUserAuthInfo()
    authInfo = { ...oldInfo, ...authInfo }
  }
  authInfo = JSON.stringify(authInfo || {})
  localStorage.setItem(USER_AUTH_INFO, authInfo)
  return true
}

export const getAppLanguage = () => {
  return (localStorage && localStorage.getItem && localStorage.getItem(APP_LANGUAGE)) || ''
}

export const splitLanguage = (appLanguageLocal) => {
  return appLanguageLocal.split('-')[0] || ''
}

export const getAppLanguageWithoutRegion = () => {
  const appLanguageLocal = getAppLanguage()
  return splitLanguage(appLanguageLocal)
}

export const setAppLanguage = (appLanguage = '') => {
  localStorage.setItem(APP_LANGUAGE, appLanguage)
  return true
}

export const getSelectedPlayerQuality = () => {
  return localStorage.getItem(SELECTED_PLAYER_QUALITY) || ''
}

export const setSelectedPlayerQuality = (selectedPlayerQuality = '') => {
  localStorage.setItem(SELECTED_PLAYER_QUALITY, selectedPlayerQuality)
  return true
}

export const removeSelectedPlayerQuality = () => {
  localStorage.removeItem(SELECTED_PLAYER_QUALITY)
  return true
}

export const getAutoPlayNextEpisode = () => {
  return localStorage.getItem(AUTO_PLAY_NEXT_EPISODE) || ''
}

export const setAutoPlayNextEpisode = (autoPlayNextEpisode = '') => {
  localStorage.setItem(AUTO_PLAY_NEXT_EPISODE, autoPlayNextEpisode)
  return true
}

export const removeAutoPlayNextEpisode = () => {
  localStorage.removeItem(AUTO_PLAY_NEXT_EPISODE)
  return true
}

export const getSelectedSubtitleTrack = () => {
  return JSON.parse(localStorage.getItem(SELECTED_SUBTITLE_TRACK)) || { language: 'none' }
}

export const setSelectedSubtitleTrack = (selectedSubtitleTrack = {}) => {
  localStorage.setItem(SELECTED_SUBTITLE_TRACK, JSON.stringify(selectedSubtitleTrack))
  return true
}

export const removeSelectedSubtitleTrack = () => {
  localStorage.removeItem(SELECTED_SUBTITLE_TRACK)
  return true
}

export const setSelectedProfile = (selectedProfile = {}) => {
  localStorage.setItem(SELECTED_PROFILE, JSON.stringify(selectedProfile))
  return true
}

export const removeSelectedProfile = () => {
  localStorage.removeItem(SELECTED_PROFILE)
  return true
}

export const getGraphics = () => {
  return JSON.parse(localStorage.getItem(GRAPHICS))
}

export const setGraphics = (graphics) => {
  localStorage.setItem(GRAPHICS, JSON.stringify(graphics))
  return true
}

export const removeGraphics = () => {
  localStorage.removeItem(GRAPHICS)
  return true
}

export const removeLocalStorageData = () => {
  setUserAuthInfo({})
  removeSelectedPlayerQuality()
  removeAutoPlayNextEpisode()
  removeSelectedSubtitleTrack()
  removeSelectedProfile()
  return true
}