import { useCallback, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import visitorInfo from 'visitor-info'
import device from 'current-device'
import moment from 'moment-timezone'
/* --------------------------------------------- */
import { version, appName } from '../config'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
/* --------------------------------------------- */
const { browser, os, engine } = visitorInfo()
const osMap = {
  'Mac OS': 'mac'
}
let osName = os.name || ''
if (osMap[os.name]) osName = osMap[os.name] || ''
let browserName = (browser || {}).name || ''
const engineName = (engine || {}).name || ''
if (browserName === 'edge' && engineName === 'blink') browserName = 'edgeChromium'
/* --------------------------------------------- */
// Custom hook to handle loggly events.
const useLoggly = () => {
  const { supportConfig } = useSelector(store => store.support)
  /* --------------------------------------------- */
  const { logger, userInfo } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const logLevelsRef = useRef([])
  const userInfoRef = useRef({})
  /* --------------------------------------------- */
  useEffect(() => {
    logLevelsRef.current = supportConfig.logLevel
  }, [supportConfig.logLevel])
  /* --------------------------------------------- */
  useEffect(() => {
    userInfoRef.current = userInfo
  }, [userInfo])
  /* --------------------------------------------- */
  const log = useCallback(({ type, attr }) => {
    const logLevels = logLevelsRef.current
    const userInfo = userInfoRef.current
    /* --------------------------------------------- */
    const isSendLog = (type) => {
      return !logLevels || logLevels.length === 0 || logLevels.indexOf(type) > -1
    }
    /* --------------------------------------------- */
    const sendLog = (type, attr) => {
      if (isSendLog(type)) {
        let logAttributes = {
          timestamp: moment().format(`yyyy-MM-DDTHH:mm:ss.SSSZ`),
          app_name: appName,
          app_version: `${version.number} - ${version.buildNumber}`,
          app_env: version.env,
          os_name: osName,
          os_version: os.version,
          platform: 'Web',
          browser: browserName,
          browser_version: browser.version,
          user_id: userInfo.userId || '',
          subscription_type: userInfo.subscriptionType || '',
          user_type: userInfo.customerType || '',
          device_brand: '',
          device_model: '',
          device_category: device.type,
          level: type,
          ...attr
        }
        //filter empty or null values
        logAttributes = Object.entries(logAttributes).reduce((obj,[key,value]) => {
            if (value && value !=='-' && value !== '_'){
              if(typeof value === 'string' && value.trim().length === 0 ){
                return obj
              }
              obj[key]=value
              return obj
            }else{
              return obj
            }
          }, {})
        
        logger.push(logAttributes)
      }
    }
    /* --------------------------------------------- */
    sendLog(type, attr)
  }, [logger])
  /* --------------------------------------------- */
  return {
    log
  }
}
/* --------------------------------------------- */
export default useLoggly
