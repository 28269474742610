import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const UpArrowIcon = ({ color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 10" {...rest}>
    <path fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M16 9L8.498 1 1 9" />
  </svg>
)
/* --------------------------------------------- */
UpArrowIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
UpArrowIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 17,
    height: 17
  }
}
/* --------------------------------------------- */
export default UpArrowIcon
/* --------------------------------------------- */
