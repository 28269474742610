import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const EditCircleIconHover  = ({ pencilColor, backgroundColor, ...rest }) => (
    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"{...rest}>
    <g fill="none" fill-rule="evenodd">
        <circle cx="12.5" cy="12.5" r="12" fill={backgroundColor} stroke={pencilColor}/>
        <path fill={pencilColor} fill-rule="nonzero" d="M7.377 15.022l2.97 2.62-.454.508c-.036.037-.084.06-.135.067l-3.183 1.016c-.123.059-.271.007-.33-.116l-.009-.02v-.136l.677-3.385c0-.068 0-.068.068-.135l.396-.419zm6.489-7.058l2.893 2.624c-2.111 2.281-4.192 4.57-6.269 6.892l.243-.275-2.954-2.607c2.052-2.177 4.073-4.413 6.087-6.634zm2.596-2.207c.414-.058.833.066 1.151.338l.677.609c.29.287.46.675.474 1.084-.026.425-.192.83-.474 1.151l-1.364 1.468.226-.25-2.895-2.625 1.12-1.233c.263-.335.66-.534 1.085-.542z"/>
    </g>
</svg>




)
/* --------------------------------------------- */
EditCircleIconHover.propTypes = {
    /** Color of icon. */
    color: PropTypes.string,
    /** Icon css styles. */
    style: PropTypes.object
}
/* --------------------------------------------- */
EditCircleIconHover.defaultProps = {
    color: 'transparent',
    
}
/* --------------------------------------------- */
export default EditCircleIconHover
/* --------------------------------------------- */
