import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const RewindIconFilled = ({ color, text, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' style={{ width: 30, height: 30 }} viewBox='0 0 200 200' {...rest}>
    <g>
      <title>background</title>
      <rect fill='none' id='canvas_background' height='172' width='172' y='-1' x='-1' />
      <g display='none' overflow='visible' y='0' x='0' height='100%' width='100%' id='canvasGrid'>
        <rect fill='url(#gridpattern)' strokeWidth='0' y='0' x='0' height='100%' width='100%' />
      </g>
    </g>
    <g>
      <title>Layer 1</title>
      <g stroke='null' id='svg_10'>
        <g stroke='null' transform='matrix(1.0555208660662174,0,0,1.0555208660662174,-6.005889348348337e-7,0)' style={{ display: 'none' }} id='svg_5'>
          <rect stroke='null' id='svg_7' height='360' width='360' style={{ display: 'inline' }} y='-98.893635' x='-99.73188' />
        </g>
        <polygon id='svg_8' points='91.58552831411362,38.65989303588867 91.38498204946518,4.545454978942871 67.55135625600815,21.739887237548828 ' fill='none' stroke={color} style={{ strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} />
        <path id='svg_9' d='m91.712223,20.431044c36.763792,3.514884 65.621732,34.378315 65.843392,72.10263c0.232215,40.247011 -32.193386,73.063154 -72.440397,73.295369s-73.063154,-32.193386 -73.295369,-72.440397c-0.042221,-6.280349 0.717754,-12.370705 2.174373,-18.186625' fill='none' stroke={color} style={{ strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} />
      </g>
      <g stroke='null' id='svg_28'>
        <g transform='matrix(5.750410125562829,0,0,5.750410125562829,-60.628521792965856,319.27238534707556) ' fillRule='evenodd' fill='none' id='svg_20'>
          <g stroke='null' fill='#FFD200' id='svg_21'>
            <g stroke='null' id='svg_22'>
              <g stroke='null' id='svg_23'>
                <g stroke='null' id='svg_24'>
                  <path stroke='null' id='svg_26' d='m25.917221,-48.162687l-4.14286,-2.95238c-0.18762,-0.13381 -0.29905,-0.34952 -0.3,-0.58c-0.00048,-0.23 0.11,-0.44667 0.29667,-0.58095l4.09523,-2.95286c0.21667,-0.15619 0.50286,-0.1781 0.74096,-0.05762c0.23809,0.12095 0.38904,0.36429 0.39095,0.63143l0.04762,5.90476c0.00238,0.26857 -0.14619,0.51524 -0.38429,0.63952c-0.10381,0.05381 -0.21714,0.08048 -0.33,0.08048c-0.14571,0 -0.29095,-0.04476 -0.41428,-0.13238z' />
                  <path stroke='#FFD200' strokeWidth='0.5' id='svg_25' d='m25.096641,-26.029967c-7.28428,0.00047 -13.23857,-5.91667 -13.28857,-13.23334c0,-1.08428 0.13429,-2.19762 0.40048,-3.31333c0.09143,-0.38381 0.47667,-0.61905 0.86,-0.52905c0.38381,0.09095 0.62048,0.47619 0.52952,0.86c-0.24333,1.02143 -0.36143,1.99571 -0.36143,2.97762c0.04477,6.52667 5.35953,11.80952 11.86477,11.80952c0.02762,0 0.05476,0 0.08285,0c3.15715,0 6.12334,-1.23904 8.35715,-3.48762c2.25095,-2.26666 3.47904,-5.27142 3.45714,-8.46c0,-6.11857 -4.61524,-11.17666 -10.73572,-11.77c-0.39238,-0.03809 -0.68,-0.38762 -0.6419,-0.78c0.0381,-0.39285 0.39,-0.67762 0.78,-0.6419c6.85571,0.66524 12.02619,6.33428 12.02619,13.18667c0.02429,3.56761 -1.35095,6.93285 -3.87238,9.4719c-2.52524,2.54286 -5.89714,3.91286 -9.4581,3.90953' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <text id='10' fontWeight='bold' fontSize='75' fill={color}>
      <tspan x='25%' y='60%'>{text}</tspan>
    </text>
  </svg>
)
/* --------------------------------------------- */
RewindIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Text inside icon. */
  text: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
RewindIconFilled.defaultProps = {
  color: 'transparent',
  text: 25,
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default RewindIconFilled
/* --------------------------------------------- */
