import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { fullWidthClassName } from 'react-remove-scroll-bar'
/* --------------------------------------------- */
import { routeMap } from '../../route'
/* --------------------------------------------- */
import { device } from '../../constants/breakpoints'
/* --------------------------------------------- */
// import GoBackArrowIcon from '.././icons/GoBackArrowIcon'
import ConfirmPopUp from '.././ConfirmPopUp'
/* --------------------------------------------- */
import Menu from '.././Sidebar'
import { GlobalContext } from '../../context/global.context'
import { useGlobalNewDispatch } from '../../context/global.new.context'
import { NavigationDispatchContext, NavigationStateContext } from '../../context/navigation.context'
/* --------------------------------------------- */
import useLogout from '../../containers/useLogout'
/* --------------------------------------------- */
import SearchBar from '.././SearchBar'//
/* --------------------------------------------- */
import { removeLocalStorageData, setUserAuthInfo, splitLanguage } from '../../utils/localStorageService'
import { isNoHeaderPage } from '../../utils/contentfulHandler.js'
/* --------------------------------------------- */
import useFirebaseTrace from '../../hooks/useFirebaseTrace'
/* --------------------------------------------- */
// import Avatar from './Avatar'
/* --------------------------------------------- */
import styled from 'styled-components'
import utils from '../../utils/utils'
import Avatar from '../../components/MultiProfileManagement/Avatar'
import { isMobile } from 'react-device-detect'

/* --------------------------------------------- */
import {
  HeaderWrapper, LeftWrapper, HeaderMenuLink, HeaderMenuLinkExternal, RightWrapper, MenuItem, MenuItemLink, GoBackArrowIconWithTheme,
  MenuImg, IconImg, AvatarImg, ButtonWrapper, MenuButton
} from './style'
import { removeSessionStorageData, setLandingPageRendered, setLoginStateLocal, setRecentlyWatchedLocal } from '../../utils/sessionStorageService'
import useErrorUtils from '../../utils/useErrorUtils'
import useLoggly from '../../containers/useLoggly'
import useDetailsApi from '../../apis/useDetailsApi'
import useAuthApi from '../../apis/useAuthApi'
import { openAndListenNewWin } from '../pages/LoginPopup/LoginPopup.helper'
import useErrorHandler from '../../containers/useErrorHandler'
import uuidv1 from 'uuid/v1'
import Spinner from '../Spinner'
import CloseIcon from '../icons/CloseIcon'

/* --------------------------------------------- */
// Header component for displaying header with menu handling.
const Header = ({ isShowLogo, isShowSearchIcon }) => {
  const [isShowSpinner, setShowSpinner] = useState(false)
  const [isMenuOpen, updateIsMenuOpen] = useState(false)
  const [isShowSidebar, updateIsShowSidebar] = useState(true)
  const [isShowGoBackArrowIcon, updateIsShowGoBackArrowIcon] = useState(false)
  const [isShowMenuIcon, updateIsShowMenuIcon] = useState(true)
  const [authConfig, setAuthConfig] = useState({})
  const [authState, setAuthState] = useState('')

  const [isConfirmPopup, updateIsConfirmPopup] = useState(false)
  const [maxVisisbleMenuItems, udpateMaxVisisbleMenuItems] = useState(1)
  const [theme, updateTheme] = useState(null)
  const [title, updateTitle] = useState(null)
  const [isShowSecondaryNavDropDown, updateIsShowSecondaryNavDropDown] = useState(false)
  const [isShowHeader, setShowHeader] = useState(false)
  // const [headerTheme, setHeaderTheme] = useState(null)
  /* --------------------------------------------- */
  const { showError, hideError, hideAllErrors } = useErrorUtils()
  /* --------------------------------------------- */
  const { log } = useLoggly()
  /* --------------------------------------------- */
  const headerProfileList = useRef(null)
  /* --------------------------------------------- */
  const logout = useLogout()
  /* --------------------------------------------- */
  const history = useHistory()
  const location = useLocation()
  /* --------------------------------------------- */
  const firebaseTrace = useFirebaseTrace()
  /* --------------------------------------------- */
  const { appTheme } = useSelector(store => store.themeConfigReducers)
  const { appMessages, appConfig } = useSelector(store => store.appConfigReducers)
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  const { primaryMenuConfig = {}, secondaryMenuConfig = {}, specialMenuConfig = {} } = useSelector(store => store.navigation)
  /* --------------------------------------------- */
  const { dispatch, showHeaderBackBtn, pageTitle, isAuthenticated, userInfo, selectedProfile, popupDetails, appLanguage } = useContext(GlobalContext)
  const navigationDispatch = useContext(NavigationDispatchContext)
  const { isSideMenuOpen } = useContext(NavigationStateContext)
  const globalNewDispatch = useGlobalNewDispatch()
  /* --------------------------------------------- */
  const { loginMethods = [], authConfig: userAuthConfig = [] } = userManagementConfig
  const { trustedLogin } = useAuthApi()
  const { getRecentlyWatched } = useDetailsApi()
  const { handleError } = useErrorHandler()
  const uuid = useRef(uuidv1())
  /* --------------------------------------------- */
  const _uuid = uuid.current //uuid
  const loginPopupRef = useRef(null) //login popup ref

  /* --------------------------------------------- */
  /**
   * When: On outside click.
   * It: Hides profile menu.
   */
  const handleClickOutside = useCallback((event) => {
    if (!(headerProfileList.current && headerProfileList.current.contains(event.target))) {
      updateIsShowSecondaryNavDropDown(false)
    }
  }, [])

  // useEffect(() => {
  //   setHeaderTheme(introThemeHeaderConfig)
  // }, [introThemeHeaderConfig])
  /* --------------------------------------------- */
  /**
   * It: Updates user auth info.
   */
  useEffect(() => {
    if (!userInfo || !userInfo.profileType) {
      dispatch({ type: 'USER_INFO', value: { ...userInfo, profileType: 'normal' } })
      setUserAuthInfo({ profileType: 'normal' }, { merge: true })
    }
  }, [userInfo, dispatch])

  /* --------------------------------------------- */
  /**
   * It: Show/hide spinner.
   */
  useEffect(() => {
    if (authState === 'LOGIN_PROGRESS') {
      setShowSpinner(true)
    } else {
      setShowSpinner(false)
    }
  }, [authState])

  /* --------------------------------------------- */
  /**
   * It: Calls recentlyWatched api.
   * It: Stores recentlyWatched data in local.
   */
  useEffect(() => {
    if (!appConfig.baseUrl) return
    if (authState !== 'LOGIN_SUCCESS') return

    let isMounted = true
    let params = {
      page: 1,
      language: splitLanguage(appLanguage),
      size: 20
    }
    params = utils.stringify(params)
    dispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: false })
    getRecentlyWatched(appConfig.baseUrl, params)
      .then((res) => {
        if (!isMounted) return null
        const content = (res.content || [])
        setRecentlyWatchedLocal(content)
        dispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
        setAuthState('')
      }).catch((err) => {
        if (!isMounted) return null
        dispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
        setAuthState('')
      })

    return () => {
      isMounted = false
    }
  }, [
    appLanguage,
    getRecentlyWatched,
    appConfig.baseUrl,
    dispatch,
    authState
  ])
  /* --------------------------------------------- */
  /**
   * It: Hides login popup.
   * It: Sets authenticate sate globally.
   */
  useEffect(() => {
    if (authState === 'LOGIN_SUCCESS') {
      // log({
      //   type: 'Info',
      //   attr: {
      //     module: 'Authentication',
      //     event: 'login_Success'
      //   }
      // })
      dispatch({ type: 'IS_APP_LOADED', value: false })
      dispatch({ type: 'AUTHENTICATED' })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
      dispatch({ type: "USER_NAVIGATION_FROM_LOGIN", value: true });
    }
  }, [authState, dispatch, log])
  /* --------------------------------------------- */
  useEffect(() => {
    if (authState === 'LOGIN_FAILURE') {
      console.log('Authentication Failure.')
    }
  }, [authState, dispatch])
  /* --------------------------------------------- */
  /**
   * It: Sets authConfig.
   */
  useEffect(() => {
    const authConfig = userAuthConfig.filter((config) => config.identityProvider === 'vdt')
    if (authConfig.length > 0) {
      setAuthConfig(authConfig[0])
    }
  }, [
    userAuthConfig
  ])

  /* --------------------------------------------- */
  /**
   * It: Calls login api.
   * It: Sends login log.
   */
  const saveToken = (url) => {
    console.log('Location changed. New URL:' + url)
    const { code } = utils.parse(url)
    const uuid = (utils.parse(utils.parseUrl(url).search) || {}).state
    if (!code || (_uuid && uuid !== _uuid)) {
      setAuthState('LOGIN_FAILURE')
      hideAllErrors()
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
      showError({ type: 'UserLoginFailed' })
      return
    }
    let redirectUri = authConfig.redirectUri
    if (window.location.hostname === 'localhost') redirectUri = `${window.location.origin}/callback`
    setAuthState('LOGIN_PROGRESS')
    trustedLogin(appConfig.baseUrl, {
      code,
      identityProvider: authConfig.identityProvider,
      logoutExtendPeriod: authConfig.logoutExtendPeriod,
      accessTokenRefreshInterval: authConfig.accessTokenRefreshInterval,
      redirectUri
    }).then(() => {
      setAuthState('LOGIN_SUCCESS')
      log({
        type: 'Info',
        attr: {
          module: 'Authentication',
          event: 'login_success'
        }
      })
    }).catch((error) => {
      error = error || {}
      const newError = new Error(error.message)
      const errorCode = error.errorCode
      if (errorCode >= 7001 && errorCode <= 7005) {
        newError.errorCode = errorCode
      } else newError.errorCode = 4010
      newError.custom = { isInitialTrustedLogin: true }
      handleError(newError, {
        setAuthState,
        subscriptionUri: authConfig.subscriptionUri
      }, error)
    })
  }
  /* --------------------------------------------- */
  /**
   * When: On login click.
   * It: Opens login page in new window.
   */
  const handleLoginClickVdt = () => {
    if (!authConfig.identityProvider) {
      setAuthState('LOGIN_FAILURE')
      hideAllErrors()
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
      showError({ type: 'UserLoginFailed' })
      return
    }
    setAuthState('LOGIN_PROGRESS')
    const url = `${window.location.origin}/auth/${authConfig.identityProvider}/login?state=${_uuid}`
    setLoginStateLocal()
    openAndListenNewWin(url, {
      maxTime: 5 * 60 * 1000, // 5 minutes
      onProgress: () => {
        hideAllErrors()
        setAuthState('LOGIN_PROGRESS')
        log({
          type: 'Info',
          attr: {
            module: 'Authentication',
            event: 'login_inprogress'
          }
        })
      },
      onBlock: () => {
        const onPrimaryBtnClick = () => {
          setAuthState('')
          hideError({ type: 'LoginPopupError' })
          // dispatch({ type: 'SHOW_LOGIN_POPUP' })
          dispatch({ type: 'USER_INFO', value: {} })
          removeLocalStorageData()
          removeSessionStorageData()
        }
        log({
          type: 'Info',
          attr: {
            module: 'Authentication',
            event: 'login_blocked'
          }
        })
        setAuthState('LOGIN_FAILURE')
        hideAllErrors()
        dispatch({ type: 'HIDE_LOGIN_POPUP' })
        showError({ type: 'LoginPopupError', value: { onPrimaryBtnClick } })
      },
      onFailure: () => {
        // const onPrimaryBtnClick = () => {
        //   setAuthState('')
        //   hideError({ type: 'UserLoginFailed' })
        //   dispatch({ type: 'SHOW_LOGIN_POPUP' })
        //   dispatch({ type: 'USER_INFO', value: {} })
        //   removeLocalStorageData()
        //   removeSessionStorageData()
        // }
        // setAuthState('LOGIN_FAILURE')
        // hideAllErrors()
        // dispatch({ type: 'HIDE_LOGIN_POPUP' })
        // showError({ type: 'UserLoginFailed', value: { onPrimaryBtnClick } })
        setAuthState('')
        hideAllErrors()
        // dispatch({ type: 'SHOW_LOGIN_POPUP' })
      },
      onSuccess: url => {
        hideAllErrors()
        saveToken(url)
      },
    })
  }
  /* --------------------------------------------- */
  /**
   * When: On login click.
   * It: Shows login popup.
   * It: Redirects to login page.
   */
  const handleLoginClick = (loginMethods, loginButtonData = {}) => {
    const loginLink = loginButtonData?.page?.[0]?.components?.[0]?.contents?.[0]?.data; //getting login url link from contentful
    updateIsShowSecondaryNavDropDown(false)
    if ((loginMethods || []).indexOf('vdt') > -1) {
      if (!loginLink) { //will show the login popup if the link is not configured 
        dispatch({ type: 'SHOW_LOGIN_POPUP' })
        dispatch({
          type: 'LOGIN_POPUP_DETAILS',
          value: {
            isShowCloseIcon: true,
            onCloseClick: () => {
              dispatch({ type: 'HIDE_LOGIN_POPUP' })
            }
          }
        })
      } else {
        handleLoginClickVdt()
      }
    } else {
      history.push(routeMap.login)
    }
  }

  const onCloseClick = () => {
    setShowSpinner(false);
  }
  /* --------------------------------------------- */
  /**
   * When: On logout click.
   * It: Hides logout popup.
   * It: Hides profile menu.
   * It: Updates authenticated & user info state globally.
   */
  const handleLogoutClick = useCallback(() => {
    updateIsConfirmPopup(false)
    updateIsShowSecondaryNavDropDown(false)
    dispatch({ type: 'USER_INFO', value: {} })
    dispatch({ type: 'UNAUTHENTICATED' })
    // dispatch({ type: 'SELECTED_PROFILE', value: null })
    // dispatch({ type: 'SET_SHOW_PROFILE_DETAILS', value: false })
    // dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: false })
    // window.localStorage.clear()
    logout()
  }, [dispatch, logout])
  /* --------------------------------------------- */
  /**
   * It: Show/Hide menu icon.
   * It: Show/Hide back button icon.
   * It: Updates title.
   */
  useEffect(() => {
    if (showHeaderBackBtn) {
      updateIsShowMenuIcon(false)
      updateIsShowGoBackArrowIcon(true)
      updateTitle(pageTitle)
    } else {
      updateIsShowMenuIcon(true)
      updateIsShowGoBackArrowIcon(false)
      updateTitle(null)
    }
  }, [showHeaderBackBtn, pageTitle])

  /* --------------Toggle sidebar menu------------------------------- */
  useEffect(() => {
    updateIsMenuOpen(isSideMenuOpen)
  }, [isSideMenuOpen])

  /* ------------------------------------------------- */
  /**
   * It: Sets moused down event listener.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])
  /* --------------------------------------------- */
  /**
   * It: Updates theme.
   */
  useEffect(() => {
    updateTheme(appTheme)
  }, [appTheme])
  /* --------------------------------------------- */
  /**
   * It: Updates max visible menu items state.
   */
  useEffect(() => {
    udpateMaxVisisbleMenuItems(primaryMenuConfig.maxVisibleItems || 1)
  }, [primaryMenuConfig.maxVisibleItems])
  /* --------------------------------------------- */
  /**
   * It: Show/Hide sidebar.
   */
  useEffect(() => {
    if (maxVisisbleMenuItems === 1) {
      updateIsShowSidebar(true)
    } else if (maxVisisbleMenuItems >= primaryMenuConfig.menuItems.length) {
      updateIsShowSidebar(false)
    } else updateIsShowSidebar(true)
  }, [primaryMenuConfig.menuItems, maxVisisbleMenuItems])
  /* --------------------------------------------- */
  /**
   * It: Sets setting popup title.
   */
  useEffect(() => {
    if (!secondaryMenuConfig.menuItems) return
    /* --------------------------------------------- */
    secondaryMenuConfig.menuItems.map((config) => {
      const { title, identifierExt } = config
      if (identifierExt === 'appsetting') {
        globalNewDispatch({ type: 'SETTINGS_TITLE', value: title })
      }
      return null
    })
  }, [secondaryMenuConfig.menuItems, globalNewDispatch])
  /* --------------------------------------------- */
  /**
   * It: Handles search text on route change.
   */
  useEffect(() => {
    const updateSearchText = () => {
      const query = utils.parse(location.search)
      const searchQuery = query.search || ''
      const modeQuery = query.mode || 'title'
      if (searchQuery) {
        if (modeQuery !== 'title') {
          dispatch({ type: 'SEARCH_TEXT', value: '' })
        } else if (searchQuery) {
          dispatch({ type: 'SEARCH_TEXT', value: searchQuery })
        }
      } else {
        dispatch({ type: 'SEARCH_TEXT', value: '' })
      }
    }

    const unlisten = history.listen((location) => {
      const isNoHeader = isNoHeaderPage(decodeURIComponent(location.pathname))
      setShowHeader(!isNoHeader)
      updateSearchText()
    })

    const isNoHeader = isNoHeaderPage()
    setShowHeader(!isNoHeader)
    updateSearchText()

    return () => {
      unlisten()
    }
  }, [
    history, location,
    dispatch
  ])
  /* --------------------------------------------- */
  const { graphics } = appTheme
  let imageURL = ''
  let logoPosition = ''
  graphics && graphics.map(({ id, images, anchor }) => {
    if (id !== 'app_header_logo') return null
    if (utils.isNotNull(images, '0', 'url')) {
      imageURL = `${images[0].url}`
    }
    logoPosition = anchor
    return null
  })
  /* --------------------------------------------- */
  /**
   * When: On back arrow click.
   * It: Redriects to previous page.
   */
  const goBackArrowOnClick = () => {
    if (decodeURIComponent(window.location.pathname) === routeMap.accountInfo) history.push('/')
    else history.goBack()
  }
  /* --------------------------------------------- */
  /**
   * When: On menu icon click.
   * It: Opens side menu.
   */
  const onClickHandler = () => {
    navigationDispatch({ type: 'SHOW_MENU_SIDEBAR' })
    updateIsMenuOpen(true)
  }
  /* --------------------------------------------- */
  /**
   * When: On outside menu click.
   * It: Closes side menu.
   */
  const clickOutsideHandler = () => {
    navigationDispatch({ type: 'HIDE_MENU_SIDEBAR' })
    updateIsMenuOpen(false)
  }
  /* --------------------------------------------- */
  /**
   * When: On profile icon click.
   * It: Toggles profile menu.
   */
  const profileOnclickHandler = () => {
    if (isShowSecondaryNavDropDown) updateIsShowSecondaryNavDropDown(false)
    else updateIsShowSecondaryNavDropDown(true)
  }
  /* --------------------------------------------- */
  /**
   * When: On popup positive button click.
   * It: Closes popup.
   */
  const handlePositiveBtn = () => {
    updateIsConfirmPopup(false)
  }
  /* --------------------------------------------- */
  /**
   * When: On settings click.
   * It: Opens settings popup.
   * It: Addes firebase performance trace.
   * It: Closes profile menu.
   */
  const handleSettingsClick = () => {
    const trace = firebaseTrace('static_page_load', {
      pageTitle: 'settings'
    })
    globalNewDispatch({ type: 'OPEN_SETTINGS_POPUP' })
    trace.stop()
    updateIsShowSecondaryNavDropDown(false)
  }
  /* --------------------------------------------- */
  /**
   * When: On logout click.
   * It: Opens logout popup.
   * It: Closes profile menu.
   */
  const handleLogoutMenuItemClick = () => {
    updateIsConfirmPopup(true)
    updateIsShowSecondaryNavDropDown(false)
  }
  /* --------------------------------------------- */
  /**
   * When: On signup click.
   * It: Closes profile menu.
   * It: Redirects to signup page.
   */
  const handleSignupMenuItemClick = () => {
    updateIsShowSecondaryNavDropDown(false)
    history.push(routeMap.signup)
  }
  /* -------------------------------------------- */
  /**
   * When: On profile click.
   * It: Opens profile selection popup.
   * It: Closes profile menu.
   */
  const handleProfileClick = () => {
    dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: true })
    updateIsShowSecondaryNavDropDown(false)
  }
  /* -------------------------------------------- */

  /* Should we show subscribe button in header */
  const subscriptionButton = specialMenuConfig.filter((m) => m.id === 'menu_subscription_special') || {}
  const showSubscribeButton = specialMenuConfig.find((m) => m.id === 'menu_subscription_special') || false
  // get redirection url from the component data
  const subscriptionRedirectUrl = subscriptionButton?.[0]?.components?.[0]?.contents?.[0]?.data || null
  const subscribeCuratedData = subscriptionButton?.[0]?.components?.[0]?.contents?.[0]?.curatedData?.[0];
  const subscibeBtnActionType = subscribeCuratedData?.ctaAction;
  const subscibeBtnLink = subscribeCuratedData?.ctaLink;

  const scroll = (id) => {
    const element = document.getElementById(id)
    const scrollPosition = element.offsetTop - 160 // to remove extra spacing frm specific div
    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth'
    })
  }

  const onSubscribeClick = () => {
    if (subscibeBtnActionType === 'sectionNavigation' && subscibeBtnLink && subscibeBtnLink.length) {
      const [link] = subscibeBtnLink;
      const id = link.sys.id;
      if (window.location.pathname !== routeMap.gettingStarted) {
        history.push(routeMap.gettingStarted);
        setTimeout(() => {
          scroll(id);
        }, 500)
      } else {
        scroll(id);
      }
    }
  }
  /**
   * When: On selected profile click.
   * It: Sets profile detsails globally.
   * It: Closes profile menu.
   */
  const handleSelectedProfileClick = () => {
    dispatch({ type: 'SET_SHOW_PROFILE_DETAILS', value: { flag: true, profile: selectedProfile, hideListOnCancel: true } })
    updateIsShowSecondaryNavDropDown(false)
  }
  /* -------------------------------------------- */
  if (!isShowHeader) return null
  /* -------------------------------------------- */
  // const graphicsHeader = headerTheme?.graphics
  // let headerImageURL = ''
  // graphicsHeader && graphicsHeader.map(({ id, images }) => {
  //   if (id !== 'landing_header_logo') return null
  //   if (utils.isNotNull(images, '0', 'url')) {
  //     headerImageURL = `${images[0].url}`
  //   }
  //   return null
  // })

  // if (location.pathname.startsWith(routeMap.gettingStarted)) {
  //   return (
  //       <HeaderLandingWrapper theme= {{...headerTheme}}>
  //         <LandingHeader theme = {{...headerTheme }}>
  //           <LeftWrapper>
  //             <img className='headerLogo' src={headerImageURL} alt='' style={{marginLeft: `${uiDirection === "ltr" ? "30px": "30px"}`, marginRight: `${uiDirection === "rtl" ? "30px": "15px"}`}}/>
  //           </LeftWrapper>
  //           <div className='header-section-btn__action'>
  //             <PlayBtnAction theme={{...headerTheme }} className='action__single' onClick={() => handleLoginClick(loginMethods)}>
  //               {<p>{appMessages.label_forgot_password_log_in}</p>}
  //             </PlayBtnAction>
  //           </div>
  //           </LandingHeader>
  //       </HeaderLandingWrapper>
  //   )
  // }
  let loginButtonData = null
  if (secondaryMenuConfig.menuItems && secondaryMenuConfig.menuItems.length > 0) {
    loginButtonData = secondaryMenuConfig.menuItems.length > 0 && secondaryMenuConfig.menuItems.find((m) => m.id === 'login')
  }

  return (
    <>
      <div className='gostHeader' />
      {isShowSpinner &&
        <div className='confirm-popup-wrapper'>
          <PopUpContent ref={loginPopupRef} {...{ theme }} className='confirm-popup__content'>
            <div className="confirm-popup-header" style={{ marginTop: 10 }}>
              <div className='confirm-popup-header-close-btn'>
                <CloseIcon color={theme && theme.header && theme.header.accent.primary} style={{ width: 18, height: 18 }} onClick={onCloseClick} />
              </div>
              <h2>{appMessages.login_poup_title}</h2>
            </div>
            <p>{appMessages.label_login_progress_description}</p>
            <div className='confirm-popup__btn'>
              <Spinner size={30} />
            </div>
          </PopUpContent>
        </div>
      }
      <HeaderWrapper {...{ theme }} className={`header ${fullWidthClassName} ${popupDetails.inViewport && popupDetails.useMargin ? 'no-scroll-reserve-gap' : 'no-scroll-no-reserve-gap'}`}>
        <div className='main-container'>
          <LeftWrapper>
            {isShowGoBackArrowIcon && <GoBackArrowIconWithTheme {...{ theme }} className='header__goBackArrowIcon' onClick={goBackArrowOnClick} style={{ width: 20, height: 20 }} />}
            {isShowMenuIcon && isShowSidebar && <MenuImg theme={primaryMenuConfig.overflowItem} className='header__menuIcon header__menuIcon---desktop' onClick={onClickHandler} style={{ width: 20, height: 20 }} />}
            {!showHeaderBackBtn && <MenuImg theme={primaryMenuConfig.overflowItem} className='header__menuIcon header__menuIcon---responsive' onClick={onClickHandler} style={{ width: 20, height: 20 }} />}
            {title && <div className='header__title'>{title}</div>}
            <Menu isOpenMenu={isMenuOpen} maxVisisbleMenuItems={maxVisisbleMenuItems} clickOutsideHandler={clickOutsideHandler} />
            {isShowLogo && !isMobile && ['left', 'top-left'].indexOf(logoPosition) > -1 && <NavLink to='/'><img className='headerLogo headerLogo-margin' src={imageURL} alt='' /></NavLink>}
            {maxVisisbleMenuItems !== 1 &&
              <div className='header___primary-nav'>
                <ul>
                  {primaryMenuConfig.menuItems.map(({ title, type, identifierExt, url, buttonStyle, isHome }, index) => {
                    if (!isAuthenticated && url === routeMap.myAccount) return null
                    if (identifierExt !== 'favorites' && location?.pathname !== '/') {
                      setLandingPageRendered(true)
                    }
                    if (identifierExt === 'favorites') {
                      if (!isAuthenticated) return null
                    }
                    if (identifierExt === 'tv-guide') url = routeMap.tvGuide
                    let isActive = false
                    if (
                      decodeURIComponent(location.pathname) === url ||
                      (location.pathname === '/' && isHome)
                    ) {
                      isActive = true
                    }
                    let _return = null
                    if (maxVisisbleMenuItems > index && !pageTitle) {
                      if (url && type === 'external') {
                        _return = (
                          <li key={index}>
                            <HeaderMenuLinkExternal
                              buttonstyle={buttonStyle}
                              href={url}
                              target='_blank'
                            >
                              {title}
                            </HeaderMenuLinkExternal>
                          </li>
                        )
                      } else if (url && type === 'webview') {
                        _return = (
                          <li key={index}>
                            <HeaderMenuLinkExternal
                              buttonstyle={buttonStyle}
                              href={url}
                            >
                              {title}
                            </HeaderMenuLinkExternal>
                          </li>
                        )
                      } else {
                        _return = (
                          <li key={index}>
                            <HeaderMenuLink
                              buttonstyle={buttonStyle}
                              className={(isActive) ? 'active' : ''}
                              to={url}
                            > {title}
                            </HeaderMenuLink>
                          </li>
                        )
                      }
                      return _return
                    } else return null
                  })}
                </ul>
              </div>}
          </LeftWrapper>
          <CenterWrapper {...{ showHeaderBackBtn }}>
            {isShowLogo && ((['center', 'center-left'].indexOf(logoPosition) > -1) || isMobile) && <NavLink to='/'><img className='headerLogo' src={imageURL} alt='' /></NavLink>}
          </CenterWrapper>
          <RightWrapper theme={secondaryMenuConfig.overflowTheme}>
            {isShowLogo && logoPosition === 'right' && <img className='headerLogo' src={imageURL} alt='' />}
            {isShowSearchIcon && <SearchBar {...{ theme }} />}
            {showSubscribeButton && !isAuthenticated ?
              subscibeBtnActionType === 'externalNavigation' && subscriptionRedirectUrl
                ? <ButtonWrapper
                  className='header__subscription-button'
                  text={showSubscribeButton.title} {...{ theme: showSubscribeButton.buttonStyle }} dangerouslySetInnerHTML={{ __html: subscriptionRedirectUrl }}
                />
                : subscibeBtnActionType === 'sectionNavigation' ?
                  <ButtonWrapper
                    className='header__subscription-button'
                    {...{ theme: showSubscribeButton.buttonStyle }}
                    onClick={() => onSubscribeClick()}
                  >{showSubscribeButton.title}</ButtonWrapper>
                  : null
              : null
            }
            {secondaryMenuConfig.menuItems && secondaryMenuConfig.menuItems.length > 0
              ? <div className='header__profile-wrapper' ref={headerProfileList}>

                {isAuthenticated && selectedProfile
                  ? <div className='header__profile-btn' onClick={profileOnclickHandler}>
                    <div className={`header__profile-head-img ${isShowSecondaryNavDropDown ? 'header__profile-head-img---active' : ''}`}>
                      <Avatar image={selectedProfile && selectedProfile.avatar ? selectedProfile.avatar : ''} showBorder height='40px' />
                    </div>
                  </div>
                  : isAuthenticated &&
                  <div className='header__profile-btn' onClick={profileOnclickHandler}>
                    <div className={`header__profile-head-img ${isShowSecondaryNavDropDown ? 'header__profile-head-img---active' : ''}`}>
                      <AvatarImg theme={secondaryMenuConfig.overflowItem} className={`${isShowSecondaryNavDropDown ? 'active' : ''}`} />
                    </div>
                  </div>}
                <div className={`header__profile-list ${isShowSecondaryNavDropDown ? 'header__profile-list---open' : ''}`}>
                  <ul>
                    {
                      isAuthenticated && selectedProfile &&
                      <li style={{ cursor: 'pointer' }} onClick={handleSelectedProfileClick}>
                        <div className='header__profile-item'>
                          <Avatar image={selectedProfile && selectedProfile.avatar ? selectedProfile.avatar : ''} showBorder height='22px' />
                          <h3>{selectedProfile && selectedProfile.name ? selectedProfile.name : ''}</h3>
                        </div>
                      </li>
                    }
                    {
                      secondaryMenuConfig.menuItems.map((config, i) => {
                        const { id, title, buttonStyle, identifierExt, url, type } = config
                        if (url && type === 'external') {
                          return (
                            <MenuItemLink href={url} target='_blank' key={i} onClick={() => handleProfileClick()} buttonStyle={buttonStyle}>
                              <IconImg theme={{ buttonStyle }} />
                              <h3>{title}</h3>
                            </MenuItemLink>
                          )
                        } else if (url && type === 'webview') {
                          return (
                            <MenuItemLink href={url} key={i} onClick={() => handleProfileClick()} buttonStyle={buttonStyle}>
                              <IconImg theme={{ buttonStyle }} />
                              <h3>{title}</h3>
                            </MenuItemLink>
                          )
                        } else {
                          if (id === 'profiles') {
                            if (loginMethods.indexOf('vdt') === -1 && loginMethods.indexOf('mpx') === -1) {
                              return null
                            } else {
                              return isAuthenticated && (
                                <MenuItem key={i} onClick={() => handleProfileClick()} buttonStyle={buttonStyle}>
                                  <IconImg theme={{ buttonStyle }} />
                                  <h3>{title}</h3>
                                </MenuItem>
                              )
                            }
                          } else if (id === 'login') {
                            if (loginMethods.indexOf('vdt') === -1 && loginMethods.indexOf('mpx') === -1) {
                              return null
                            } else {
                              return !isAuthenticated && (
                                <MenuItem key={i} onClick={() => handleLoginClick(loginMethods)} buttonStyle={buttonStyle}>
                                  <IconImg theme={{ buttonStyle }} />
                                  <h3>{title}</h3>
                                </MenuItem>
                              )
                            }
                          } else if (id === 'logout') {
                            if (loginMethods.indexOf('vdt') === -1 && loginMethods.indexOf('mpx') === -1) {
                              return null
                            } else {
                              return isAuthenticated && (
                                <MenuItem key={i} onClick={() => handleLogoutMenuItemClick()} buttonStyle={buttonStyle}>
                                  <IconImg theme={{ buttonStyle }} />
                                  <h3>{title}</h3>
                                </MenuItem>
                              )
                            }
                          } else if (id === 'menu_sign_up') {
                            if (loginMethods.indexOf('mpx') === -1) {
                              return null
                            } else {
                              return !isAuthenticated && (
                                <MenuItem key={i} onClick={() => handleSignupMenuItemClick()} buttonStyle={buttonStyle}>
                                  <IconImg theme={{ buttonStyle }} />
                                  <h3>{title}</h3>
                                </MenuItem>
                              )
                            }
                          } else if (identifierExt === 'appsetting') {
                            return (
                              <MenuItem key={i} onClick={() => handleSettingsClick()} buttonStyle={buttonStyle}>
                                <IconImg theme={{ buttonStyle }} />
                                <h3>{title}</h3>
                              </MenuItem>
                            )
                          } else if (identifierExt === 'favorites') {
                            return (
                              <MenuItem key={i} onClick={() => history.push(url)} buttonStyle={buttonStyle}>
                                <IconImg theme={{ buttonStyle }} />
                                <h3>{title}</h3>
                              </MenuItem>
                            )
                          } else if (identifierExt === 'tv-guide') {
                            return (
                              <MenuItem key={i} onClick={() => history.push(routeMap.tvGuide)} buttonStyle={buttonStyle}>
                                <IconImg theme={{ buttonStyle }} />
                                <h3>{title}</h3>
                              </MenuItem>
                            )
                          } else {
                            return null
                          }
                        }
                      })
                    }
                  </ul>
                </div>
              </div>
              : (isAuthenticated && selectedProfile && selectedProfile.avatar &&
                <div className='header__profile-wrapper'>
                  <div className='header__profile-btn' onClick={() => dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: true })}>
                    <Avatar height='40px' image={selectedProfile.avatar} showBorder />
                  </div>
                </div>
              )}
            {
              !isAuthenticated && loginButtonData && (
                <div className='header__login-btn'>
                  <AvatarImg theme={secondaryMenuConfig.overflowItem} className={`${isShowSecondaryNavDropDown ? 'active' : ''}`} />
                  <MenuButton key={111} onClick={() => handleLoginClick(loginMethods, loginButtonData)} buttonStyle={loginButtonData.buttonStyle}>
                    <h3>{loginButtonData.title}</h3>
                  </MenuButton>
                </div>
              )
            }
          </RightWrapper>
        </div>

      </HeaderWrapper>
      {isConfirmPopup && (
        <ConfirmPopUp
          onPositiveBtnClick={handlePositiveBtn}
          {...{
            title: appMessages.logout_popup_title,
            desc: appMessages.logout_popup_description,
            negtiveText: appMessages.label_logout_cancel,
            positiveText: appMessages.logout_popup_ok
          }}
          onNegativeBtnClick={handleLogoutClick}
        />)}
    </>
  )
}

export const CenterWrapper = styled.div`
${({ showHeaderBackBtn }) => {
    if (!showHeaderBackBtn) return
    const mobile = ['mobileS', 'mobileM', 'mobileL']
    return mobile.map((resolution) => {
      return `
      @media ${device[resolution]} {
        display: none;
      }
    `
    })
  }}
`

const PopUpContent = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};

  div.logo {
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
    
    .logo_img{
      height: 50px;
    }
  }

  h2 {
    color: ${({ theme: { header } }) => header && header.text.secondary};
  }

  p {
    color: ${({ theme: { body } }) => body && body.text.primary};
  }

  .confirm-popup__btn{
    .popup-btn__positive {
      background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.text};
      border: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'stroke') ? `1px solid ${compositeStyle.primaryButton.normal.stroke}` : ''};
      border-radius: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'primaryButton', 'edgeRadius') && compositeStyle.primaryButton.edgeRadius * 16}px;
      box-shadow: ${({ theme: { compositeStyle } }) => (utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'shadow') && compositeStyle.primaryButton.normal.shadow) ? '0 6px 8px 2px rgba(0, 0, 0, 0.48)' : ''};
    }

    .popup-btn__negative {
      background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.secondaryButton.normal.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.secondaryButton.normal.text};
      border: ${({ theme: { compositeStyle } }) => compositeStyle && `1px solid ${compositeStyle.secondaryButton.normal.stroke}`};
      border: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'stroke') ? `1px solid ${compositeStyle.primaryButton.normal.stroke}` : ''};
      border-radius: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'edgeRadius') && compositeStyle.secondaryButton.edgeRadius * 16}px;
      box-shadow: ${({ theme: { compositeStyle } }) => (utils.isNotNull(compositeStyle, 'secondaryButton', 'normal', 'shadow') && compositeStyle.secondaryButton.normal.shadow) ? '0 6px 8px 2px rgba(0, 0, 0, 0.48)' : ''};
    }
  }

  .login-intro-image {
    height: 30vh;
    width: 100%;
  }
`

/* --------------------------------------------- */
Header.propTypes = {
  /** Show/Hide Menu icon. */
  isShowMenuIcon: PropTypes.bool,
  /** Show/Hide Logo. */
  isShowLogo: PropTypes.bool,
  /** Force Show Logo. */
  isForceShowLogo: PropTypes.bool,
  /** Show/Hide Title. */
  isShowTitle: PropTypes.bool,
  /** history object sent as props from redux */
  history: PropTypes.object,
  /** Show/Hide Back arrow icon. */
  isShowGoBackArrowIcon: PropTypes.bool,
  /** Show/Hide Search icon. */
  isShowSearchIcon: PropTypes.bool,
  /** Header title */
  title: PropTypes.string,
  /** Header theme */
  theme: PropTypes.object
}
/* --------------------------------------------- */
Header.defaultProps = {
  isShowMenuIcon: true,
  isShowLogo: true,
  isForceShowLogo: false,
  isShowTitle: false,
  history: {},
  isShowGoBackArrowIcon: false,
  isShowSearchIcon: true,
  title: '',
  theme: {}
}
/* --------------------------------------------- */
export default Header
/* --------------------------------------------- */
