import React, { useCallback } from 'react'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'
import { SliderRail, Handle, Track } from './PlayerSeekerComponents'
import Thumbnails from './Thumbnails'
import PropTypes from 'prop-types'

// Container component to handle player seek control
const PlayerSeek = ({ onSlideStart, onSlideEnd, onUpdate, resetBingePopOnClick, buffered, ...props }) => {
  const _onSlideStart = useCallback((props) => {
    if (onSlideStart) onSlideStart(props)
  }, [onSlideStart])

  const _onSlideEnd = useCallback((props) => {
    if (onSlideEnd) onSlideEnd(props)
  }, [onSlideEnd])

  const _onUpdate = useCallback((props) => {
    if (onUpdate) onUpdate(props)
  }, [onUpdate])

  return (
    <>
      <Slider
        mode={1}
        step={1}
        domain={[0, (props.duration || 1)]}
        values={[props.currentPos || 0]}
        onSlideEnd={_onSlideEnd}
        onUpdate={_onUpdate}
        >
        <Rail>
          {({ getRailProps }) => <SliderRail resetBingePopOnClick={resetBingePopOnClick} getRailProps={getRailProps}
            onSlideStart={_onSlideStart}
            bufferedPercentage={buffered}
            isFromCast={props.isFromCast || false}
            seekBarColor={props.seekBarColor}
          />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className='slider-handles'>
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={[0, props.duration || 1]}
                  color={props.trackColor}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className='slider-tracks'>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                  color={props.trackColor}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>

      {props.isShowThumbnail && <Thumbnails duration={props.duration || 0} />}
    </>
  )
}
/* --------------------------------------------- */
PlayerSeek.propTypes = {
  isShowThumbnail: PropTypes.bool
}
/* --------------------------------------------- */
PlayerSeek.defaultProps = {
  isShowThumbnail: true
}
/* --------------------------------------------- */
export default PlayerSeek
