import React from 'react'
import Button from './Button'
import { Wrapper } from './style'
import './button.scss'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component of Button component.
const ButtonWrapper = (props) => {
    return (
        <Wrapper
        {...{
            theme: props.theme,
            type: props.type
            }}
        className={props.buttonClassname}    
        >
        <Button
            theme = {props.theme}
            size = {props.size}
            text = {props.text}
            onClick= {props.onClick}
        />
        </Wrapper>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
export default ButtonWrapper
/* ---------------------------------------------------------------------------------------------------- */
