import { useCallback, useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
/* --------------------------------------------- */
import useFirebaseTrace from '../hooks/useFirebaseTrace'
/* --------------------------------------------- */
import { getUserAuthInfo, removeLocalStorageData } from '../utils/localStorageService'
import { removeSessionStorageData } from '../utils/sessionStorageService'
/* --------------------------------------------- */
import useAuthApi from '../apis/useAuthApi'
/* --------------------------------------------- */
// Custom hook to handle logout event.
const useLogoutHelper = () => {
  const { dispatch } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const { logout } = useAuthApi()
  /* --------------------------------------------- */
  const firebaseTrace = useFirebaseTrace()
  /* --------------------------------------------- */
  const { appConfig } = useSelector(store => store.appConfigReducers)
  const baseUrlRef = useRef();

  baseUrlRef.current = appConfig?.baseUrl || null;
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  /* --------------------------------------------- */
  const redirectLogout = useCallback((identityProvider, idToken, apiSuccess) => {
    const userAuthConfig = userManagementConfig.authConfig || []
    const authConfig = userAuthConfig.filter((config) => config.identityProvider === identityProvider)[0] || {}
    let logoutRedirectUri = authConfig.logoutRedirectUri;
    if (logoutRedirectUri.includes("{id_token}")) {
      if(idToken && idToken !== "" ) {
        logoutRedirectUri = authConfig.logoutRedirectUri?.replace("{id_token}", idToken); // to replace the idToken in the redirection url
      } else {
        logoutRedirectUri = null;
      }
    }
    if(!apiSuccess) {logoutRedirectUri = null}
    /* --------------------------------------------- */
    if (!logoutRedirectUri) return Promise.reject(new Error('Missing logoutRedirectUri.'))
    /* --------------------------------------------- */
    if (window.location.hostname === 'localhost') {
      if (identityProvider === 'vdt') {
        logoutRedirectUri = 'https://ppx-auth.videotron.ca/logout?client_id=23039vrqg3teoajo6o70lthdpq&logout_uri=https%3A%2F%2Fclub-ppx-auth.videotron.ca%2Flogout%3Fclient_id%3Dui9lk35ik9c8v6s7epb0hcn8g%26logout_uri%3Dhttps%3A%2F%2Fppx-id.videotron.com%2Fvl-sso-bin%2Flogout.pl%3Fend_url%3Dhttp%3A%2F%2Flocalhost%3A3000%2Fcallback%3Faction%3Dlogout'
      } else if (['google', 'facebook', 'apple'].indexOf(identityProvider) > -1) {
        logoutRedirectUri = 'http://auth.tapgo.tv/logout?client_id=4dveuqvpvc7ni55hig1n403ro2&logout_uri=http://localhost:3000/logout'
      }
    }
    /* --------------------------------------------- */
    removeLocalStorageData()
    removeSessionStorageData()
    /* --------------------------------------------- */
    // setTimeout(()=> {
      window.location.href = logoutRedirectUri
    // }, 1500)
    /* --------------------------------------------- */
    return Promise.resolve()
  }, [userManagementConfig.authConfig])
  /* --------------------------------------------- */
  const initLogout = useCallback(() => {
    let trace = null
    const userInfo = getUserAuthInfo()
    const identityProvider = userInfo.identityProvider || ''
    /* --------------------------------------------- */
    const onLogout = (idToken = '', apiSuccess = false) => {
      if (trace) trace.stop()
      if (['google', 'facebook', 'apple', 'vdt'].indexOf(identityProvider) > -1) {
        redirectLogout(identityProvider, idToken, apiSuccess)
          .catch(() => {
            dispatch({ type: 'SHOW_SPLASH', value: false })
            if (identityProvider === 'vdt') {
              window.location.reload()
              dispatch({ type: 'SHOW_LOGIN_POPUP' })
            }
          })
      } else {
        dispatch({ type: 'SHOW_SPLASH', value: false })
        if (!identityProvider) window.location.reload()
      }
    }
    /* --------------------------------------------- */
    dispatch({ type: 'SHOW_SPLASH', value: true })
    if (appConfig?.baseUrl) {
      trace = firebaseTrace('app_logout')
      logout(baseUrlRef.current)
        .then((res) => {
          onLogout(res?.idToken || '',true)
        })
        .catch((err) => {
          onLogout(err)
    })
    } else {
      dispatch({ type: 'SHOW_SPLASH', value: false })
      if (identityProvider === 'vdt') {
        dispatch({ type: 'SHOW_LOGIN_POPUP' })
      }
    }
  }, [appConfig.baseUrl, logout, redirectLogout, dispatch, firebaseTrace])
  /* --------------------------------------------- */
  return initLogout
}
/* --------------------------------------------- */
export default useLogoutHelper
