import React, { useContext } from 'react'
import visitorInfo from 'visitor-info'
import { Context } from "./context"
import { routeMap } from '../../../route'
import utils from '../../../utils/utils'
import { getBrowserName } from '../../..//utils/contentfulHandler'
/* ---------------------------------------------------------------------------------------------------- */
import { WarningIcon, PrimaryButtonWrapper, SecondaryButtonWrapper, FavouritesIcon, SearchIconOutline } from './style'
import './ErrorPage.scss'
/* ---------------------------------------------------------------------------------------------------- */
const visitor = visitorInfo()
const osInfo = visitor.os || {}
const osMap = {
    'Mac OS': 'mac'
}
/* ---------------------------------------------------------------------------------------------------- */
// export const testFunctionObj = { testFunction() { } }
// ErrorPage component which handles rendering and events.
const ErrorPage = () => {
    const { errorPage = {}, errorDispatch, appMessages = {}, userInfo = {}, version = {} } = useContext(Context)
    /* ------------------------------------------------------------------------------------------------ */
    const {
        title,
        description,
        code,
        primaryActionType,
        secondaryActionType,
        primaryActionLabel,
        secondaryActionLabel,
        secondaryActionDescription,
        onPrimaryBtnClick = () => { },
        onSecondaryBtnClick = () => { },
        faqDescription,
        faqLabel,
        faqItem = []
    } = errorPage || {}
    /* ------------------------------------------------------------------------------------------------ */
    const {
        subscriptionType = '',
        customerType = '',
        source = ''
    } = userInfo || {}
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Handles primary button click based on contentful config. */
    const handelePrimaryBtnClick = () => {
        if (primaryActionType === 'retry') window.location.reload()
        else if (primaryActionType === 'exit') window.close()
        else if (primaryActionType === 'cancel') {
            onPrimaryBtnClick()
            errorDispatch({ type: 'ERROR_PAGE', value: {} })
        }
        else if (primaryActionType === 'custom') onPrimaryBtnClick()
    }
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Handles secondary button click based on contentful config. */
    const handeleSecondaryBtnClick = () => {
        if (secondaryActionType === 'retry') window.location.reload()
        else if (secondaryActionType === 'exit') window.close()
        else if (secondaryActionType === 'cancel') {
            onSecondaryBtnClick()
            errorDispatch({ type: 'ERROR_PAGE', value: {} })
        }
        else if (secondaryActionType === 'custom') onSecondaryBtnClick()
    }
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Contstructs faq url on FAQ button click and opens in new tab */
    const handleFAQBtnClick = () => {
        // testFunctionObj.testFunction() //This function is created & used only for testing.
        if (faqItem.length === 0) return
        const { id } = faqItem[0]
        if (!id) return
        const browserName = (visitor.browser || {}).name || ''
        const engineName = (visitor.engine || {}).name || ''
        const filters = utils.clearMissingValues({
            platforms: 'web',
            browser: getBrowserName(browserName, engineName),
            displayOrientation: window.innerWidth > window.innerHeight ? 'landscape' : 'portrait',
            buildType: version.buildType || '',
            minimumBuildNumber: version.buildNumber || '',
            operatingSystem: osMap[osInfo.name] || '',
            subscriptionType,
            userType: customerType,
            userSource: source,
            id
        })
        const query = utils.stringify(filters)
        window.open(`${window.location.origin}${routeMap.faq}?${query}`, '_blank')
    }
    /* ---------------------------------------------------------------------------------------------------- */
    const isFavourites = utils.startsWith(decodeURIComponent(window.location.pathname), routeMap.favourites)
    const isSearch = utils.startsWith(decodeURIComponent(window.location.pathname), routeMap.listing)
    /* ------------------------------------------------------------------------------------------------ */
    return (
        <div className='error-page-wrapper'>
            <div className='error-page__content'>
                <div className="error-page-header">
                    {isFavourites && <FavouritesIcon />}
                    {isSearch && <SearchIconOutline />}
                    {!isFavourites && !isSearch && <WarningIcon />}
                    {title && <h2>{title}</h2>}
                </div>
                {description && <p>{description}</p>}
                {code && <p className='error-code'>{appMessages.error_code}: {code}</p>}
                <div className='error-page__btn'>
                    {
                        primaryActionLabel && <PrimaryButtonWrapper
                            text={primaryActionLabel}
                            ButtonOnClick={handelePrimaryBtnClick}
                        />
                    }
                    {
                        secondaryActionDescription &&
                        <span className='popup-btn-subtitle'>{secondaryActionDescription}</span>
                    }
                    {
                        secondaryActionLabel && <SecondaryButtonWrapper
                            text={secondaryActionLabel}
                            ButtonOnClick={handeleSecondaryBtnClick}
                        />
                    }
                </div>
                {
                    faqItem.length > 0 &&
                    <div className='error-page__faq'>
                        {
                            faqDescription &&
                            <span className='popup-btn-subtitle faq-description'>{faqDescription}</span>
                        }
                        {
                            faqLabel && <SecondaryButtonWrapper
                                text={faqLabel}
                                buttonSize='small'
                                ButtonOnClick={handleFAQBtnClick}
                            />
                        }
                    </div>
                }
            </div>
        </div>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
ErrorPage.propTypes = {}
/* ---------------------------------------------------------------------------------------------------- */
ErrorPage.defaultProps = {}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorPage
/* ---------------------------------------------------------------------------------------------------- */
