
import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const VolumeMuteOutlineIcon = ({ color, ...rest }) => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox="0 0 64 64" {...rest}>
		<g id="Layer_1_1_">
		<line className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' x1="17.5" y1="20.5" x2="17.5" y2="43.5"/>
		<polyline className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' points="2.5,32.5 2.5,43.5 18.2,43.5 36.5,55.7 36.5,29.5 	"/>
		<polyline className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' points="2.5,31.5 2.5,20.5 18.2,20.5 36.5,8.3 36.5,34.5 	"/>
		<line className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' x1="17.5" y1="20.5" x2="17.5" y2="43.5"/>
		<polyline className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' points="2.5,32.5 2.5,43.5 18.2,43.5 36.5,55.7 36.5,29.5 	"/>
		<polyline className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' points="2.5,31.5 2.5,20.5 18.2,20.5 36.5,8.3 36.5,34.5 	"/>
		<line className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' x1="45" y1="23.6" x2="61.6" y2="41.3"/>
		<line className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' x1="61.6" y1="23.6" x2="45" y2="41.3"/>
		<line className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' x1="45" y1="23.6" x2="61.6" y2="41.3"/>
		<line className="st0" fill='none' stroke={color} strokeWidth = '3' strokeLinecap = 'round' strokeLinejoin = 'round' x1="61.6" y1="23.6" x2="45" y2="41.3"/>
	</g>
<g id="Layer_2_2_"></g>
	</svg>
)
/* --------------------------------------------- */
VolumeMuteOutlineIcon.propTypes = {
	/** Color of icon. */
	color: PropTypes.string,
	/** Icon css styles. */
	style: PropTypes.object
}
/* --------------------------------------------- */
VolumeMuteOutlineIcon.defaultProps = {
	color: 'transparent',
	style: {
		width: 25,
		height: 25
	}
}
/* --------------------------------------------- */
export default VolumeMuteOutlineIcon
/* --------------------------------------------- */
