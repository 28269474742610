import React from 'react'
import { useSelector } from 'react-redux'
import { version } from '../../../config'
import ErrorFullscreen from './ErrorFullscreen'
import Provider from './context'
import { Wrapper } from './style'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component for ErrorFullscreen component.
const ErrorFullscreenWrapper = () => {
    const { appMessages } = useSelector(store => store.appConfigReducers)
    return (
        <Provider value={{ appMessages, version }}>
            <Wrapper>
                <ErrorFullscreen />
            </Wrapper>
        </Provider>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorFullscreenWrapper
/* ---------------------------------------------------------------------------------------------------- */
