import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { version } from '../../config'

// Helper component to conditionaly initalize chromecast.
const ConditionalInitChromecast = (props) => {
    const [isInit, setInit] = useState(version.project !== 'tap')
    const { playerFeature = {} } = useSelector(store => store.featuresReducers)

    useEffect(() => {
        if (playerFeature.allowCasting) {
            setInit(true)
        }
    }, [playerFeature.allowCasting])
    return (
        <>
            {isInit && props.children}
        </>
    )
}

export default ConditionalInitChromecast