import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const SubtitleIcon = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' {...rest}>
    <g id='Layer_2_1_' style={{ display: 'none' }}>
      <rect x='-148' y='-148' style={{ display: 'inline' }} width='360' height='360' />
    </g>
    <rect x='2.5' y='10.5' fill='none' stroke={color} strokeWidth='2.89' strokeMiterlimit='10' width='59' height='38' />
    <g>
      <line fill='none' stroke={color} strokeWidth='2.89' strokeMiterlimit='10' x1='6' y1='33.5' x2='43' y2='33.5' />
      <line fill='none' stroke={color} strokeWidth='2.89' strokeMiterlimit='10' x1='50' y1='33.5' x2='58' y2='33.5' />
      <line fill='none' stroke={color} strokeWidth='2.89' strokeMiterlimit='10' x1='58' y1='43.5' x2='20' y2='43.5' />
      <line fill='none' stroke={color} strokeWidth='2.89' strokeMiterlimit='10' x1='13' y1='43.5' x2='6' y2='43.5' />
    </g>
  </svg>
)
/* --------------------------------------------- */
SubtitleIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
SubtitleIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default SubtitleIcon
/* --------------------------------------------- */
