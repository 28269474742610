import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const SelectedTickIcon = ({ tickColor, backgroundColor, ...rest }) => (

<svg  viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" {...rest}>
{/* <svg  viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> */}
    <g id="Multi--Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="3.1_Apple-TV_Profiles_3-profiles" transform="translate(-788.000000, -476.000000)">
            <g id="Group-7" transform="translate(698.000000, 320.000000)">
                <g id="Group-9-Copy" transform="translate(90.000000, 156.000000)">
                    <circle id="Oval" fill={backgroundColor} cx="15" cy="15" r="12"></circle>
                    <path d="M15,0 C6.71582278,0 0,6.71582278 0,15 C0,23.2841772 6.71582278,30 15,30 C23.2841772,30 30,23.2841772 30,15 C30,6.71582278 23.2841772,0 15,0 Z M13.0727848,21.3911392 L6.64367089,15.1651899 L7.97278481,13.7924051 L13.05,18.7101266 L23.5310127,8.22911392 L24.8829114,9.58101266 L13.0727848,21.3911392 Z" id="Shape" fill={tickColor} fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </g>
</svg>





)
/* --------------------------------------------- */
SelectedTickIcon.propTypes = {
    /** Color of icon. */
    color: PropTypes.string,
    /** Icon css styles. */
    style: PropTypes.object
}
/* --------------------------------------------- */
SelectedTickIcon.defaultProps = {
    color: 'transparent',

}
/* --------------------------------------------- */
export default SelectedTickIcon
/* --------------------------------------------- */
