import React, { useContext, useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import styled from 'styled-components';
import { version } from '../../../config';
import { GlobalContext } from '../../../context/global.context';
import { getGraphics } from '../../../utils/contentfulThemeMerger';
import utils from '../../../utils/utils';
import RightArrowIconForIntro from '../../icons/RightArrowIconForIntro';

let setTimeoutIntroNext = false;

const IntroPopup = props => {
    let setTimeOutTracker = false
    const { introContent = [], allowSkip = true, showIntro = '', showStrategy = [], showProgressIndicator = true, allowBackNavigation = true } = useSelector(store => store.featuresReducers.introConfig)
    const { introThemeConfig = [] } = useSelector(store => store.themeConfigReducers)
    const { appMessages } = useSelector(store => store.appConfigReducers)
    const [showIntroPopUp, setShowIntroPopUp] = useState(false)
    const [activeItem, setActiveItem] = useState(0)
    const { hasUserNavigatedFromLoginPage, isUserLoginRefresh, dispatch: globalDispatch } = useContext(GlobalContext); //isAuthenticated
    const sliderRef = useRef(null)
    const isFirstLoad = useRef(null)
    const introPopupRef = useRef(null)

    const sliderConf = {
        speed: 1000,
        initialSlide: 0,
        slidesToScroll: 1,
        // is used to move the slides in sync with the animation
        beforeChange: (prevI, i) => {
            if (setTimeOutTracker) return
            setTimeOutTracker = setTimeout(() => {
                setActiveItem(i);
                setTimeOutTracker = false;
            }, 1000)

        },
    }

    // Show intro popup ad update version
    const show = () => {
        setShowIntroPopUp(true)
        localStorage.version = version.appVersion
    }

    // returns `true` if showStrategy applies on the app load.
    const checkShowStrategy = useCallback(() => {

        // if showStrategy is 'Always' then show the pop-up
        if (showStrategy.find(el => el === 'Always')) {
            return true
        }

        // if isFirstLoad is true (which is set to false forever on close), then show the pop-up 
        if (isFirstLoad.current) {
            return true
        }

        // if there is no version set in localStorage, then the app is loading for the first time.
        if (showStrategy.find(el => el === 'OnFirstLaunch') && !localStorage.version) {
            isFirstLoad.current = true; // runs only once on page load
            return true
        }

        if (showStrategy.find(el => el === 'OnAppUpdate')) {
            if (!localStorage.version) {
                isFirstLoad.current = true; // runs only once on page load
                return true
            }
            // if there is a change in version the show pop up
            if (localStorage.version !== version.appVersion) {
                return true
            }
        }
        return false
    }, [showStrategy])

    useEffect(() => {
        if (!checkShowStrategy()) return 

        // case: refresh when user is already logged in
        if (isUserLoginRefresh) {
            show()
            return
        }
        // case: user logged in from login menu
        if (hasUserNavigatedFromLoginPage) {
            showIntro === 'OnLogin' && show()
        }
        else {
            showIntro === 'OnSplash' && show()
        }
    }, [hasUserNavigatedFromLoginPage, isUserLoginRefresh, showIntro, checkShowStrategy, showStrategy])

    const changeSlide = () => {
        if (setTimeoutIntroNext) return
        setTimeoutIntroNext = setTimeout(() => {
            setTimeoutIntroNext = false
        }, 1000)
        sliderRef.current.slickNext()
        next()
    }

    const next = () => {
        const nextActiveItemIndex = activeItem + 1 < introContent.length ? activeItem + 1 : activeItem;
        setActiveItem(nextActiveItemIndex);
    }

    const hidePopUp = (action) => {
        setShowIntroPopUp(false)
        isFirstLoad.current = null
    }

    const showDots = () => {
        if ((activeItem === introContent.length - 1 && !allowBackNavigation) || !showProgressIndicator) return false
        return true
    }

    /**
     * It: Enables / Disables background scrolling when popup is open
     */
     useEffect(() => {
        let contentEl = introPopupRef
        if (showIntroPopUp) {
            utils.disableScroll(contentEl, { reserveScrollBarGap: true })
            globalDispatch({ 
                type: 'POPUP_DETAILS', 
                value: {
                    inViewport: true,
                    useMargin: utils.hasMargin()
                }
            })
        }
        else {
            utils.enableScroll(contentEl)
            globalDispatch({ 
                type: 'POPUP_DETAILS', 
                value: {
                    inViewport: false,
                    useMargin: utils.hasMargin()
                }
            })
        }
    }, [showIntroPopUp, globalDispatch])

    if (!introContent.length || !showIntroPopUp) return <></>
    return showIntroPopUp && introContent.length && <IntroPopupMask>
        <IntroWrapper className='intro-popup-wrapper' {...introThemeConfig}>
            <div className='pop-up' ref={introPopupRef}>
                <Slider dots={showDots()} ref={sliderRef} {...{ ...sliderConf, swipe: !(!allowBackNavigation && activeItem === introContent.length - 1) }}>
                    {introContent.map(({ title, description, graphics, sysId }, index) => <div key={sysId}>
                        <div className='intro-img-container'>
                            {/* <IntroImage alt={title} src={graphics[0]?.images[2].imageUrl} /> */}
                            <IntroImage alt={title} graphics={graphics} />
                        </div>
                        <div className='intro-desc-contents'>
                            <IntroTitle {...introThemeConfig} className='intro-title'>{title}</IntroTitle>
                            <IntroDescription {...introThemeConfig} className='intro-desc'>{description}</IntroDescription>
                        </div>

                    </div>)}
                </Slider>
            </div>
            <div className='bottom-nav'>
                {/* <div className='intro-nav'>{introContent.map((el, i) => <span onClick={() => goto(i)} className={`intro-nav-ele ${activeItem === i ? ' active' : ''}`} key={i}></span>)}</div> */}
                <div className='intro-control-btns'>
                    {(introContent.length - 1 === activeItem) ? <div className='get-started-btn-container' onClick={() => hidePopUp('GET_STARTED')}>
                            <GetStartedBtn {...introThemeConfig} className='get-started-btn'>{appMessages.button_intro_get_started}</GetStartedBtn>
                        </div> :
                        <>
                            <SecondaryButton className='next-btn'  {...introThemeConfig} >
                                <span onClick={changeSlide} className='next-label'>{appMessages.label_intro_next}</span>
                                <span onClick={changeSlide} className='right-arrow-icon'>
                                    <RightArrowIconForIntro color={utils.isNotNull(introThemeConfig, 'compositeStyle', 'secondaryButton', 'normal', 'text') ? introThemeConfig.compositeStyle.secondaryButton.normal.text : ''} />
                                </span>
                            </SecondaryButton>
                            {allowSkip && <TertiaryButton className='skip-btn' {...introThemeConfig} >
                                <span className='skip-label' onClick={() => { setShowIntroPopUp(false); isFirstLoad.current = null }} >{appMessages.label_intro_skip}</span>
                            </TertiaryButton>}
                        </>
                    }
                </div>
            </div>

        </IntroWrapper>
    </IntroPopupMask>
}

const IntroPopupMask = styled.div`
position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  padding: 15px;
`

const SecondaryButton = styled.div`
color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'normal', 'text') ? compositeStyle.secondaryButton.normal.text : ''};
float: right;
display: flex;
font-size: 18px;
padding-right: 39px;

@media screen and (max-width: 450px), screen and (max-height: 525px) {
    font-size: 18px;
    padding-right: 25px;
    padding: 1px 30px 15px 0px;
    font-size: 16px;
}
`

const TertiaryButton = styled.div`
color: #a6a6a6;
padding: 4px 32px;
font-size: 14px;
font-family: "Open Sans",sans-serif,Helvetica,Arial;

@media screen and (max-width: 450px), screen and (max-height: 525px) {
    padding: 1px 0px 15px 30px;
    font-size: 14px;
}
`

const IntroTitle = styled.h3`
    color: ${({ body }) => utils.isNotNull(body, 'text', 'primary') ? body.text.primary : '#ffd200'};
`

const IntroDescription = styled.p`
    color: ${({ body }) => utils.isNotNull(body, 'text', 'secondary') ? body.text.secondary : '#ffffff'};
`

const IntroWrapper = styled.div`
  background-color:${({ body }) => {
        return utils.isNotNull(body, 'background', 'primary') ? body.background.primary : '#2b2b2b';
    }};

    .pop-up .slick-dots li.slick-active button:before {
        color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'sliderIndicator', 'selected', 'background') ? compositeStyle.sliderIndicator.selected.background : '#ffd200'};
        
    }
    .slick-dots button:before {
        opacity: 0.75;
    }

    .pop-up .slick-dots li button:before {
        color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'sliderIndicator', 'normal', 'background') ? compositeStyle.sliderIndicator.normal.background : '#ffd200'};
    }
`

const GetStartedBtn = styled.button`
background: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'background') ? compositeStyle.primaryButton.normal.background : ''};
border: 1px solid ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'stroke') ? compositeStyle.primaryButton.normal.stroke : ''}
`


const IntroImage = styled.img`
${({ graphics = [] }) => {
        // gets the images in contentFul configuration
        const filteredGraphics = getGraphics({ graphics });
        const { images } = (filteredGraphics[0] || {});
        const { url } = (images[0] || {})
        return `content: url('${url}');`
    }}
position: absolute;
object-fit: contain;
width: 100%;
height: 100%;
`

export default IntroPopup
