import { useState, useEffect, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { GlobalContext } from '../../context/global.context'
import useContentful from '../../contentful/useContentful'
import { appConfig as appConfigAction } from '../../actions/appConfig'
import { setAppLanguage } from '../../utils/localStorageService'
import useErrorUtils from '../../utils/useErrorUtils'

// ContentfulLoader components responsible for fetching contentful data and storing them in redux store.
const ContentfulLoader = () => {
    const { isAppLoaded, isAuthenticated, userInfo, dispatch: globalDispatch } = useContext(GlobalContext)
    const [appData, setAppData] = useState(null)
    const [isApiFailed, setApiFailed] = useState(false)
    const { getContentful } = useContentful()
    const { showError } = useErrorUtils()
    const dispatch = useDispatch()
    const userInfoRef = useRef(userInfo)

    useEffect(() => {
        userInfoRef.current = userInfo
    }, [userInfo])

    /**
     * It: Updates app language to the global state.
     * It: Fetches contentful data and set them as appData state.
     */
    useEffect(() => {
        getContentful().then((res = {}) => {
            const { locale = '' } = res || {}
            globalDispatch({ type: 'SET_APP_LANGUAGE', value: locale || 'en-US' })
            setAppLanguage(locale)
            setAppData(res || {})
        }).catch((error) => {
            error = error || {}
            setAppData({})
            setApiFailed(true)
        })
    }, [getContentful, dispatch, globalDispatch])

    /**
     * It: Stores appData in redux stores.
     * It: Updates isAppLoaded state.
     */
    useEffect(() => {
        let isMounted = true
        if (appData) {
            const userInfo = userInfoRef.current || {}
            let subscriptionType = 'anonymous'
            if (isAuthenticated) subscriptionType = userInfo.subscriptionType || 'registered'
            dispatch(appConfigAction(appData, { ...userInfo, subscriptionType }))
                .catch((error) => {
                    console.log('CONTENTFUL DATA PARSE ERROR', error)
                }).then(() => {
                    if (!isMounted) return
                    globalDispatch({ type: 'IS_APP_LOADED', value: true })
                })
        }
        return () => {
            isMounted = false
        }
    }, [
        dispatch,
        appData,
        isAuthenticated,
        globalDispatch
    ])

    /**
     * It: Displays contentful error.
     */
    useEffect(() => {
        if (isAppLoaded && isApiFailed) {
            showError({ type: 'ServerError' })
        }
    }, [isAppLoaded, isApiFailed, showError])
    return null
}

export default ContentfulLoader