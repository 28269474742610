import { mergeThemes, getThemeData, getMenuButtonStyle, handleTag } from '../utils/contentfulThemeMerger'
import { CATALOG_CONFIG } from '../constants/actionTypes'
import { filter, hydratePageComponent } from '../utils/contentfulHandler'
import utils from '../utils/utils'

/** CatalogConfig store */
export const fetchCatalogConfig = (catalog, themeData, options) => {
  const { appThemeData, componentThemeData } = themeData
  return (dispatch) => {
    catalog = filter(catalog, '', options)
    let catalogTheme = {}
    if (utils.isNotNull(catalog, 0)) {
      const catalogConfig = catalog[0]
      if (utils.isNotNull(catalogConfig, 'theme')) {
        const theme = filter(catalogConfig.theme, '', options)
        if (utils.isNotNull(theme, 0)) {
          catalogTheme = getThemeData(theme[0], options)
          catalogTheme = mergeThemes(appThemeData, catalogTheme)
        } else if (appThemeData) catalogTheme = appThemeData
      } else if (appThemeData) catalogTheme = appThemeData

      let episodeConfig = {}
      let episodeComponentStyle = []
      let episodeComponentTheme = {}
      let episodeOverflowTheme = {}

      if (utils.isNotNull(catalogConfig, 'episodeListingComponent')) {
        const components = filter(catalogConfig.episodeListingComponent, '', options)
        if (utils.isNotNull(components, 0)) {
          const component = components[0]
          const {
            config, componentStyles, componentTheme, overflowTheme
          } = hydratePageComponent(component, options, {
            defaultPageTheme: catalogTheme,
            defaultComponentTheme: componentThemeData
          })
          episodeConfig = config
          episodeComponentStyle = componentStyles
          episodeComponentTheme = componentTheme
          episodeOverflowTheme = overflowTheme
        }
      }
      let relatedComponent = {}
      let rConfig = {}
      if (utils.isNotNull(catalogConfig, 'relatedMediaComponent')) {
        const components = filter(catalogConfig.relatedMediaComponent, '', options)
        relatedComponent = components[0] || {}
        if (utils.isNotNull(relatedComponent, 'contents')) {
          const contents = filter(relatedComponent.contents, '', options)
          if (utils.isNotNull(contents, 0)) {
            rConfig = contents[0]
          }
        }
      }
      let productListingComponent = {}
      if (utils.isNotNull(catalogConfig, 'productListingComponent')) {
        const components = filter(catalogConfig.productListingComponent, '', options)
        productListingComponent = components[0] || {}
      }

      let tabs = [];
      if (utils.isNotNull(catalogConfig, "tab")) {
        tabs = JSON.parse(JSON.stringify(catalogConfig.tab));

        tabs = filter(tabs, "", options);
        tabs
          .map((menuItem) => {
            const { page = [], buttonStyle } = menuItem;

            if (buttonStyle) {
              menuItem.buttonStyle = getMenuButtonStyle(buttonStyle);
            }
            let pageFilter = filter(page, "", options);
            if (pageFilter.length > 0) {
              menuItem.page = pageFilter[0];
              const theme = menuItem?.page?.theme;
              const { components } = menuItem?.page || {};
              const { tagConfiguration } = components[0] || {};

              for (let index = 0; index < tagConfiguration.length; index++) {
                const element = tagConfiguration[index];
                tagConfiguration[index].tagStyle = handleTag(element.tagStyle[0]);
              }

              // Update the components array with modified tag configurations
              // components[0].tagConfiguration = modifiedTagConfiguration;
              let nTheme = {};
              if (utils.isNotNull(theme, 0)) {
                nTheme = getThemeData(theme[0], options);
                nTheme = mergeThemes(appThemeData, nTheme);
                menuItem.page.theme = nTheme;
              }
            } else {
              menuItem.page = {};
            }

            return null;
          })
          .filter((data) => data);
      }

      dispatch({
        type: CATALOG_CONFIG,
        value: {
          rConfig: rConfig,
          theme: catalogTheme,
          catalogTheme,
          catalogComponentThemeData: componentThemeData,
          episodeConfig,
          episodeComponentStyle,
          episodeComponentTheme,
          episodeOverflowTheme,
          relatedComponent,
          productListingComponent
        }
      })
    }
    return Promise.resolve()
  }
}
